import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import Checkbox from '@appchoose/checkbox';
import Icon from '@appchoose/icon';
import type { CheckboxProps } from '@radix-ui/react-checkbox';

import type { BrandMatch } from '../../types/navigation';
import {
  orderDetailsContainerVariants,
  orderDetailsCourierVariants,
  orderDetailsImageVariants,
  orderDetailsOrderIdTextVariants,
} from './order-details';

export type OrderDetailsProps = {
  order: {
    id?: string;
    parcels?: {
      trackingCourier: {
        logoUrl: string;
        name: string;
      };
      trackingNumber: string;
    }[];
  };
} & CheckboxProps;

export const OrderDetailsCheckbox: React.FC<OrderDetailsProps> = forwardRef<
  HTMLButtonElement,
  OrderDetailsProps
>(({ order, ...props }, ref) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { brandId = '' } = useParams<BrandMatch>();

  const parcel = order.parcels?.[0];
  return (
    <div className="divide-y divide-gray-300 rounded border border-gray-300">
      <label className="flex cursor-pointer space-x-6 rounded bg-[#FBFBFB] px-4 py-6">
        <div className={orderDetailsContainerVariants({ size: 'default' })}>
          <div className="flex h-full items-start">
            <img
              src={parcel?.trackingCourier.logoUrl}
              alt=""
              className={orderDetailsImageVariants({
                size: 'default',
                style: 'default',
              })}
            />
          </div>
          <div className="space-y-1">
            <div className={orderDetailsCourierVariants({ style: 'default' })}>
              {parcel?.trackingCourier.name}
            </div>
            <div
              className={orderDetailsOrderIdTextVariants({
                size: 'default',
                style: 'default',
              })}
            >
              {parcel?.trackingNumber}
            </div>
          </div>
        </div>
        {order.id ? (
          <div className="flex-1 space-y-1">
            <div className="text-xs font-semibold uppercase tracking-wider text-gray-500">
              {t('order.order')}
            </div>

            <a
              href={`/${brandId}/orders/${order.id}${search}`}
              target="_blank"
              rel="noreferrer"
              className="inline-flex items-center space-x-1 text-sm font-semibold text-green-900"
            >
              <span>{order.id}</span>
              <Icon icon="externalLinkSimple" className="size-3.5" />
            </a>
          </div>
        ) : null}
        <div className="flex items-center">
          <Checkbox ref={ref} {...props} />
        </div>
      </label>
    </div>
  );
});

OrderDetailsCheckbox.displayName = 'OrderDetailsCheckbox';
