import { useRecoilValue } from 'recoil';

import { useAuth0 } from '@auth0/auth0-react';

import { brandState } from '../stores/brand';
import type { RawSale } from '../stores/sales';
import { useCurrentUserQuery } from '../types/generated-new';
import {
  initIntercom,
  openIntercom,
  openIntercomWithPrePopulatedContent,
} from '../utils/intercom';

export const useSupportChat = () => {
  const { user } = useAuth0();
  const { data: userData } = useCurrentUserQuery(undefined, {
    staleTime: 1000,
  });
  const brand = useRecoilValue(brandState);

  const setSupportPlatform = (sale: RawSale | undefined) => {
    initIntercom({
      user_id: user?.sub ?? 'no_info',
      name: user?.nickname ?? user?.name ?? 'No name',
      email: user?.email ?? 'tech@appchoose.io',
      company: user?.email?.endsWith('@appchoose.io')
        ? undefined // ne pas associer les utilisateurs de Choose à une entreprise
        : {
            company_id: brand?.id ?? '',
            name: brand?.name ?? '',
            contact_person: sale?.logistics_manager ?? '',
            account_manager: sale?.logistics_manager ?? '',
          },
      user_hash: userData?.currentUser.intercomUserHash ?? '',
    });
  };

  const openSupportChat = () => {
    openIntercom();
  };

  const openSupportChatWithPrePopulatedContent = (message: string) => {
    openIntercomWithPrePopulatedContent(message);
  };

  return {
    setSupportPlatform,
    openSupportChat,
    openSupportChatWithPrePopulatedContent,
  };
};
