/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import type { Address } from '@appchoose/address';
import AddressFormFields from '@appchoose/address-form-fields';
import Button from '@appchoose/button';
import { Form } from '@appchoose/form';

import { brandState } from '../../../stores/brand';
import { useUpdateSellerMutation } from '../../../types/generated';
import { getDefaultOnboardingCountry } from '../../../utils/address';
import { track } from '../../../utils/analytics';

export type UpdatingInfoConfirmationReturnAddressProps = {
  goBack: () => void;
};

export type UpdatingInfoConfirmationReturnAddressForm = Address;

export const UpdatingInfoConfirmationReturnAddress: React.FC<
  UpdatingInfoConfirmationReturnAddressProps
> = ({ goBack }: UpdatingInfoConfirmationReturnAddressProps) => {
  const [brand, setBrand] = useRecoilState(brandState);
  const { i18n, t } = useTranslation();

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  useEffect(() => {
    track('EditReturnAddress', {});
  }, []);

  const defaultCountry = getDefaultOnboardingCountry(
    brand?.return_address?.country as string | undefined,
    brand?.shipping_country ?? ''
  );

  const form = useForm<UpdatingInfoConfirmationReturnAddressForm>({
    mode: 'onTouched',
    defaultValues: {
      name: brand?.return_address?.name ?? '',
      street: brand?.return_address?.street ?? '',
      street2: brand?.return_address?.street2 ?? '',
      bp: brand?.return_address?.bp ?? '',
      province: brand?.return_address?.province ?? '',
      city: brand?.return_address?.city ?? '',
      country: brand?.return_address?.country ?? '',
      countryCode: defaultCountry?.code ?? '',
    },
  });

  const onSubmit = (data: UpdatingInfoConfirmationReturnAddressForm) => {
    if (!brand) return;
    setBrand({
      ...brand,
      return_address: data,
    });
    updateSellerMutation({
      updateSeller: { return_address: data },
    });
    goBack();
  };

  return (
    <>
      <h2 className="mb-8 text-2xl font-bold sm:mb-10 sm:text-3.5xl">
        {t('updating_info.confirmation_step.return_address.title')}
      </h2>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <AddressFormFields
            googleMapsApiKey={
              import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''
            }
            locale={i18n.language}
            showCompany={true}
            showName={false}
            showPhone={false}
            autoFocus
            translations={{
              suggestions: t('suggestions'),
              fieldsNameValidationErrorsRequired: t(
                'address.fields.name.validation_errors.required'
              ),
              fieldsNameValidationErrorsMaxLength: t(
                'address.fields.name.validation_errors.maxLength',
                {
                  maxLength: '200',
                }
              ),
              fieldsFirstnameValidationErrorsRequired: t(
                'address.fields.firstname.validation_errors.required'
              ),
              fieldsFirstnameValidationErrorsMaxLength: t(
                'address.fields.firstname.validation_errors.maxLength',
                {
                  maxLength: '200',
                }
              ),
              fieldsLastnameValidationErrorsRequired: t(
                'address.fields.lastname.validation_errors.required'
              ),
              fieldsLastnameValidationErrorsMaxLength: t(
                'address.fields.lastname.validation_errors.maxLength',
                {
                  maxLength: '200',
                }
              ),
              fieldsPhoneValidationErrorsRequired: t(
                'address.fields.phone.validation_errors.required'
              ),
              fieldsPhoneValidationErrorsMaxLength: t(
                'address.fields.phone.validation_errors.maxLength',
                {
                  maxLength: '200',
                }
              ),
              fieldsStreetValidationErrorsRequired: t(
                'address.fields.street.validation_errors.required'
              ),
              fieldsStreetValidationErrorsMaxLength: t(
                'address.fields.street.validation_errors.maxLength',
                {
                  maxLength: '200',
                }
              ),
              fieldsStreet2ValidationErrorsMaxLength: t(
                'address.fields.street2.validation_errors.maxLength',
                {
                  maxLength: '200',
                }
              ),
              fieldsBpValidationErrorsRequired: t(
                'address.fields.bp.validation_errors.required'
              ),
              fieldsBpValidationErrorsMaxLength: t(
                'address.fields.bp.validation_errors.maxLength',
                {
                  maxLength: '200',
                }
              ),
              fieldsCityValidationErrorsRequired: t(
                'address.fields.city.validation_errors.required'
              ),
              fieldsCityValidationErrorsMaxLength: t(
                'address.fields.city.validation_errors.maxLength',
                {
                  maxLength: '200',
                }
              ),
              fieldsProvinceValidationErrorsRequired: t(
                'address.fields.province.validation_errors.required'
              ),
              fieldsProvinceValidationErrorsMaxLength: t(
                'address.fields.province.validation_errors.maxLength',
                {
                  maxLength: '200',
                }
              ),
              fieldsCountryValidationErrorsRequired: t(
                'address.fields.country.validation_errors.required'
              ),
              fieldsNameLabel: t('address.fields.name.label_return'),
              fieldsNamePlaceholder: t('address.fields.name.placeholder'),
              fieldsFirstnameLabel: t('address.fields.firstname.label'),
              fieldsFirstnamePlaceholder: t(
                'address.fields.firstname.placeholder'
              ),
              fieldsLastnameLabel: t('address.fields.lastname.label'),
              fieldsLastnamePlaceholder: t(
                'address.fields.lastname.placeholder'
              ),
              fieldsPhoneLabel: t('address.fields.phone.label'),
              fieldsPhonePlaceholder: t('address.fields.phone.placeholder'),
              fieldsStreetLabel: t('address.fields.street.label'),
              fieldsStreetPlaceholder: t('address.fields.street.placeholder'),
              fieldsStreet2Label: t('address.fields.street2.label'),
              fieldsStreet2Placeholder: t('address.fields.street2.placeholder'),
              fieldsBpLabel: t('address.fields.bp.label'),
              fieldsBpPlaceholder: '',
              fieldsCityLabel: t('address.fields.city.label'),
              fieldsCityPlaceholder: '',
              fieldsProvinceLabel: t('address.fields.province.label'),
              fieldsProvincePlaceholder: '',
              fieldsProvinceEmptyField: t(
                'address.fields.province.empty_field'
              ),
              fieldsCountryLabel: t('address.fields.country.label'),
              fieldsCountryPlaceholder: t('address.fields.country.placeholder'),
              fieldsCountryPlaceholderSearch: t(
                'address.fields.country.placeholder_search'
              ),
              fieldsCountryEmptyField: t('address.fields.country.empty_field'),
              fieldsCountryNoResults: t('address.fields.country.no_results'),
            }}
          />
          <div className="mt-8 flex justify-end space-x-6 sm:mt-10">
            <button
              type="button"
              className="cursor-pointer text-sm font-bold text-green-900"
              onClick={goBack}
            >
              {t('updating_info.confirmation_step.return')}
            </button>{' '}
            <Button type="submit" size="large">
              {t('updating_info.confirmation_step.confirm')}
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

UpdatingInfoConfirmationReturnAddress.displayName =
  'UpdatingInfoConfirmationReturnAddress';
