/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import { Form } from '@appchoose/form';
import { toast } from '@appchoose/toast';
import { useAuth0 } from '@auth0/auth0-react';

import { useCreatePasswordConnectionMutation } from '../../../types/generated-new';
import type { OnboardingUpdateProfileForm } from '../../../views/onboarding-update-profile/onboarding-update-profile';
import { PasswordFormField } from '../../password-form-field/password-form-field';
import { PersonalNameFormFields } from '../../personal-name-form-fields/personal-name-form-fields';

export type OnboardingStepAuthUpdateProfileProps = {
  goToNextSubStepOrStep: () => void;
};

export const OnboardingStepAuthUpdateProfile: React.FC<
  OnboardingStepAuthUpdateProfileProps
> = ({ goToNextSubStepOrStep }: OnboardingStepAuthUpdateProfileProps) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  const form = useForm<OnboardingUpdateProfileForm>({
    mode: 'onTouched',
    defaultValues: {
      firstName:
        user?.['https://appchoose.io/claims/firstname'] ??
        user?.given_name ??
        '',
      lastName:
        user?.['https://appchoose.io/claims/lastname'] ??
        user?.family_name ??
        '',
      password: '',
    },
  });

  if (user && user['https://appchoose.io/claims/needOnboarding'] === false) {
    goToNextSubStepOrStep();
  }

  const { mutateAsync: createOnboardingMutation, isPending } =
    useCreatePasswordConnectionMutation();

  const onSubmit = async (data: OnboardingUpdateProfileForm) => {
    try {
      await createOnboardingMutation({
        userInfo: {
          firstname: data.firstName,
          lastname: data.lastName,
          password: data.password,
        },
      });

      await getAccessTokenSilently({ cacheMode: 'off' });

      goToNextSubStepOrStep();
    } catch {
      toast.error(t('auth.onboarding_update_profile.generic_error'));
    }
  };

  return (
    <section className="mt-8 sm:mt-20">
      <h1 className="mb-10 text-3.5xl font-bold">
        {t('onboarding.update_profile.name')}
      </h1>
      <div className="space-y-10">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-10">
            <p className="m-0 text-base leading-5.5 text-gray-700">
              {t('auth.onboarding_update_profile.better_knowing_you')}
            </p>
            <div className="space-y-6">
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <PersonalNameFormFields />
              </div>
              <div className="space-y-2">
                <PasswordFormField />
              </div>
            </div>
            <Button
              type="submit"
              appearance="primary"
              size="large"
              disabled={isPending}
            >
              {t('validate')}
            </Button>
          </form>
        </Form>
      </div>
    </section>
  );
};
