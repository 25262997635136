import React from 'react';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import type { CheckedState } from '@radix-ui/react-checkbox';

export type OrderCheckboxProps = {
  /**
   * Is the checkbox disabled?
   */
  disabled?: boolean;
  /**
   * Is the checkbox checked by default?
   */
  defaultChecked?: boolean;
  /**
   * Define an handler for checked-changing state
   */
  onCheckedChange?: ((checked: CheckedState) => void) | undefined;
} & React.ComponentPropsWithoutRef<'button'>;

/**
 * Main checkbox component for user interaction.
 */
export const OrderCheckbox = React.forwardRef<
  HTMLButtonElement,
  OrderCheckboxProps
>(
  (
    {
      id,
      disabled = false,
      defaultChecked,
      onCheckedChange,
      children,
      className,
      ...props
    }: OrderCheckboxProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const [checked, setChecked] =
      React.useState<CheckboxPrimitive.CheckedState>(
        typeof defaultChecked !== 'undefined' ? defaultChecked : 'indeterminate'
      );

    return (
      <CheckboxPrimitive.Root
        id={id}
        ref={ref}
        className={cn(
          {
            'border-gray-900 focus:ring-2 focus:ring-gray-900/30':
              !disabled && checked === true,
            'border-gray-100 focus:ring-2 focus:ring-gray-900/30':
              !disabled && checked !== true,
            'cursor-pointer text-gray-900': !disabled,
            'cursor-not-allowed border-gray-300 text-gray-300': disabled,
            'group flex items-center space-x-2 rounded border p-2 text-sm outline-0 transition duration-300':
              true,
          },
          className
        )}
        disabled={disabled}
        defaultChecked={checked === true}
        onCheckedChange={(checked: CheckboxPrimitive.CheckedState) => {
          setChecked(checked);
          onCheckedChange?.(checked);
        }}
        checked={checked}
        {...props}
      >
        <div
          className={cn(
            'flex size-[13.33px] items-center justify-center rounded-sm border',
            {
              'border-gray-900 bg-gray-900 group-focus:ring-2 group-focus:ring-gray-900/30':
                !disabled && checked === true,
              'bg-gray-300': disabled && checked === true,
              'border-[#767A7C] focus:ring-2 group-focus:ring-gray-900/30':
                !disabled && checked !== true,
              'bg-white': disabled && checked !== true,
              'cursor-pointer': !disabled,
              'cursor-not-allowed border-gray-300': disabled,
            }
          )}
        >
          <CheckboxPrimitive.Indicator>
            <Icon
              className={cn({
                'bg-gray-300': disabled,
                'opacity-0': checked !== true,
                'opacity-1 size-[0.58331rem] text-white transition duration-300':
                  true,
              })}
              icon="check"
              size="small"
            />
          </CheckboxPrimitive.Indicator>
        </div>

        <span>{children}</span>
      </CheckboxPrimitive.Root>
    );
  }
);

OrderCheckbox.displayName = 'OrderCheckbox';
