import type { ChangeEvent } from 'react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Input from '@appchoose/input';
import Mailchecker from 'mailchecker';

import type { PhoneFormData } from '../phone-form-fields/phone-form-fields';
import { PhoneFormFields } from '../phone-form-fields/phone-form-fields';

type ContactFormFieldsProps = {
  autoFocus?: boolean;
};

export type BrandContactFormData = {
  email: string;
} & PhoneFormData;

export const ContactFormFields: React.FC<ContactFormFieldsProps> = ({
  autoFocus,
}) => {
  const { t } = useTranslation();
  const form = useFormContext<BrandContactFormData>();

  return (
    <>
      <PhoneFormFields autoFocus={autoFocus} />
      <FormField
        control={form.control}
        name="email"
        rules={{
          required: true,
          maxLength: 50,
          validate: {
            email: (value) => Mailchecker.isValid(value),
          },
        }}
        render={({ field, fieldState: { invalid } }) => (
          <FormItem>
            <FormLabel>
              {t('brand_info.contact_form_fields.email.label')}
            </FormLabel>
            <FormControl>
              <Input
                type="email"
                placeholder="example@gmail.com"
                autoComplete="email"
                inputMode="email"
                {...field}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;
                  event.target.value = value.trim();
                  field.onChange(event);
                }}
              />
            </FormControl>
            {!invalid ? (
              <p className="text-sm text-gray-500">
                {t('brand_info.contact_form_fields.email.help')}
              </p>
            ) : null}
            <FormMessage match="required">
              {t(
                'brand_info.contact_form_fields.email.validation_errors.required'
              )}
            </FormMessage>
            <FormMessage match="maxLength">
              {t(
                'brand_info.contact_form_fields.email.validation_errors.maxLength',
                {
                  maxLength: '50',
                }
              )}
            </FormMessage>
            <FormMessage match="email">
              {t(
                'brand_info.contact_form_fields.email.validation_errors.email'
              )}
            </FormMessage>
          </FormItem>
        )}
      />
    </>
  );
};

ContactFormFields.displayName = 'ContactFormFields';
