import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AuthForm } from '../../components/auth-form/auth-form';
import { XChooseHeader } from '../../components/x-choose-header/x-choose-header';
import type { BrandMatch } from '../../types/navigation';

export const Signup: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { brandId = '' } = useParams<BrandMatch>();

  return (
    <div className="mx-auto w-full px-4 py-10 sm:max-w-4.5xl sm:px-6">
      <XChooseHeader />
      <main className="mx-2 mt-10 sm:mx-8 sm:mt-20 md:mx-24">
        <h1 className="mb-4 text-3.5xl font-bold">
          {t('auth.signup.start_by_creating_your_account')}
        </h1>
        <div className="space-y-10">
          <p className="m-0 text-base leading-5.5 text-gray-700">
            {t('auth.signup.infos_connect_dashboard')}
            <br />
            {t('auth.signup.infos_advice_email')}
          </p>
          <AuthForm
            showLoginWithPassword={false}
            success={(result) => {
              const queryParams = new URLSearchParams(location.search);
              queryParams.set('email', result.email);

              navigate(
                `/${brandId}/confirm-passwordless-code?${queryParams.toString()}`
              );
            }}
          />
        </div>
      </main>
    </div>
  );
};

Signup.displayName = 'Signup';
