import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useCopyToClipboard } from 'react-use';
import { useRecoilValue } from 'recoil';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import Tooltip from '@appchoose/tooltip';
import * as Sentry from '@sentry/react';
import type { ColumnDef, Row } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { brandState } from '../../stores/brand';
import { activeSaleIdState } from '../../stores/sales';
import type {
  BillingStateError,
  ProductSoldErrorV2,
} from '../../types/generated-new';

const MEUSNIDUS_BASE_URL =
  import.meta.env.REACT_APP_STAGE === 'production'
    ? 'https://meusnidus-v2.appchoose.io'
    : 'https://meusnidus-v2.appchoose.co';

export type BillingErrorData = {
  actionQueryParams: string;
  actions: string;
  code: string;
  details: string;
  errors: (ProductSoldErrorV2 | BillingStateError)[];
  type: 'warning' | 'error';
  whoCanResolve: string;
};

const SubComponent = ({ row }: { row: Row<BillingErrorData> }) => {
  const activeSaleId = useRecoilValue(activeSaleIdState);
  const brand = useRecoilValue(brandState);

  const [idClipboardState, copyIdToClipboard] = useCopyToClipboard();
  const { t } = useTranslation();

  useEffect(() => {
    const { value, error } = idClipboardState;
    if (value) {
      toast.success(t('billing.errors.copy_id_feedback'));
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [idClipboardState]);

  return (
    <div
      className={cn(
        'col-span-4 my-4 grid-cols-[minmax(auto,_max-content)_auto] gap-4 sm:grid sm:pl-7',
        {
          'grid-cols-[minmax(auto,_max-content)_minmax(auto,_max-content)_minmax(auto,_max-content)_minmax(auto,_max-content)_minmax(auto,_max-content)]':
            row.original.errors[0]?.__typename === 'ProductSoldErrorV2',
        }
      )}
    >
      {row.original.errors.map((error, i) =>
        error.__typename === 'ProductSoldErrorV2' ? (
          <Fragment key={error.id}>
            <span className="text-xs text-gray-700">#{i + 1}</span>
            <a
              href={`${MEUSNIDUS_BASE_URL}/vente/${
                activeSaleId ?? ''
              }?p=${error.product_id}`}
              target="_blank"
              className="flex items-center space-x-1 text-xs font-semibold text-green-900"
              rel="noreferrer"
            >
              <Icon icon="externalLinkDefault" className="shrink-0" />
              <span>{t('billing.errors.show_product')}</span>
            </a>

            <Tooltip
              content={
                <div className="px-1.5 py-3">
                  {t('billing.errors.order_id_tooltip')}
                </div>
              }
            >
              <button
                type="button"
                onClick={() => copyIdToClipboard(error.order_id)}
                className="flex items-center space-x-1 text-xs font-semibold text-green-900"
              >
                <Icon icon="copy" className="shrink-0" />
                <span>{error.order_id}</span>
              </button>
            </Tooltip>
            <Tooltip
              content={
                <div className="px-1.5 py-3">
                  {t('billing.errors.product_id_tooltip')}
                </div>
              }
            >
              <button
                type="button"
                onClick={() => copyIdToClipboard(error.product_id)}
                className="flex items-center space-x-1 text-xs font-semibold text-green-900"
              >
                <Icon icon="copy" className="shrink-0" />
                <span>{error.product_id}</span>
              </button>
            </Tooltip>
            <Tooltip
              content={
                <div className="px-1.5 py-3">
                  {t('billing.errors.variant_id_tooltip')}
                </div>
              }
            >
              <button
                type="button"
                onClick={() => copyIdToClipboard(error.id)}
                className="flex items-center space-x-1 text-xs font-semibold text-green-900"
              >
                <Icon icon="copy" className="shrink-0" />
                <span>{error.id}</span>
              </button>
            </Tooltip>
          </Fragment>
        ) : (
          <Fragment key={error.id}>
            <span className="text-xs text-gray-700">#{i + 1}</span>
            <NavLink
              to={`${brand?.id ? '/' + brand.id : ''}/orders/${
                error.id
              }${row.original.actionQueryParams}`}
              target="_blank"
              className="flex items-center space-x-1 text-xs font-semibold text-green-900"
              rel="noreferrer"
            >
              <Icon icon="externalLinkDefault" className="shrink-0" />
              <span>
                {t('billing.errors.show_order', {
                  order_id: error.id,
                })}
              </span>
            </NavLink>
          </Fragment>
        )
      )}
    </div>
  );
};

type BillingErrorTableProps = {
  columns: ColumnDef<BillingErrorData, unknown>[];
  data: BillingErrorData[];
};

export const BillingErrorTable: React.FC<BillingErrorTableProps> = ({
  columns,
  data,
}) => {
  const table = useReactTable({
    columns,
    data,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <table className="w-full">
      <thead className="hidden border-b border-gray-100 sm:table-header-group">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="p-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 first:pl-0 last:pr-0 sm:px-6"
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <Fragment key={row.id}>
            <tr
              className={cn({
                'border-b border-gray-100': !row.getIsExpanded(),
              })}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="float-left w-full p-2 text-xs text-gray-900 first:pl-0 last:pr-0 sm:float-none sm:w-auto sm:p-6"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
            {row.getIsExpanded() && (
              <tr className="border-b border-gray-100">
                {/* 2nd row is a custom 1 cell row */}
                <td colSpan={row.getVisibleCells().length}>
                  <SubComponent row={row} />
                </td>
              </tr>
            )}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};
