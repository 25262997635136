import {
  InfiniteData,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
  UseSuspenseInfiniteQueryOptions,
  UseSuspenseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { useFetchData } from '../hooks/use-fetcher';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: string; output: string };
  Json: { input: any; output: any };
  NonEmptyString: { input: string; output: string };
  Upload: { input: any; output: any };
  Void: { input: any; output: any };
};

export type Brand = {
  __typename?: 'Brand';
  accept_contract?: Maybe<Scalars['Boolean']['output']>;
  account_number?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['Json']['output']>;
  admin?: Maybe<Scalars['Boolean']['output']>;
  api?: Maybe<Scalars['String']['output']>;
  api_token?: Maybe<Scalars['String']['output']>;
  bank_address?: Maybe<Scalars['Json']['output']>;
  beneficiary_name?: Maybe<Scalars['String']['output']>;
  contact_email?: Maybe<Scalars['String']['output']>;
  contact_name?: Maybe<Scalars['String']['output']>;
  contact_phone?: Maybe<Scalars['String']['output']>;
  delivery_services?: Maybe<Scalars['Json']['output']>;
  has_mixed_delivery_delay?: Maybe<Scalars['Boolean']['output']>;
  has_pre_order_delivery?: Maybe<Scalars['Boolean']['output']>;
  has_shipping_service?: Maybe<Scalars['Boolean']['output']>;
  has_shopify?: Maybe<Scalars['Boolean']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  intracommunity_vat?: Maybe<Scalars['String']['output']>;
  isReseller?: Maybe<Scalars['Boolean']['output']>;
  is_autoentrepreneur?: Maybe<Scalars['Boolean']['output']>;
  is_french?: Maybe<Scalars['Boolean']['output']>;
  max_delivery_delay?: Maybe<Scalars['Int']['output']>;
  min_delivery_delay?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  need_refresh_info: Scalars['Boolean']['output'];
  plugins?: Maybe<Plugins>;
  return_address?: Maybe<Scalars['Json']['output']>;
  routing_code?: Maybe<Scalars['String']['output']>;
  sales?: Maybe<Array<Maybe<Sale>>>;
  serverTime?: Maybe<Scalars['DateTime']['output']>;
  shipping_country?: Maybe<Scalars['String']['output']>;
  shipping_services?: Maybe<Scalars['Json']['output']>;
  shopify?: Maybe<ShopifyData>;
  shopify_url?: Maybe<Scalars['String']['output']>;
  siret?: Maybe<Scalars['String']['output']>;
  store: StoreRegion;
  swift_code?: Maybe<Scalars['String']['output']>;
  tax_id?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export enum BrandRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

/** **Billing statistics for a sale by day** */
export type HistogramElement = {
  __typename?: 'HistogramElement';
  /**
   * **Date of stats**
   * - > *format* : YYYY-MM-DD
   */
  date: Scalars['String']['output'];
  nb_distinct_orders?: Maybe<Scalars['Int']['output']>;
  total_billing_ht?: Maybe<Scalars['Int']['output']>;
  total_billing_ttc?: Maybe<Scalars['Int']['output']>;
  total_commission_ht?: Maybe<Scalars['Int']['output']>;
  total_commission_ttc?: Maybe<Scalars['Int']['output']>;
  total_operation_ht?: Maybe<Scalars['Int']['output']>;
  total_operation_ttc?: Maybe<Scalars['Int']['output']>;
  total_vat?: Maybe<Scalars['Int']['output']>;
};

/** **Invoice created** */
export type InvoiceCreated = {
  __typename?: 'InvoiceCreated';
  id: Scalars['ID']['output'];
};

/** **Invoice updated** */
export type InvoiceUpdated = {
  __typename?: 'InvoiceUpdated';
  id: Scalars['ID']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  confirmCsv?: Maybe<Scalars['String']['output']>;
  removeCsv?: Maybe<Scalars['String']['output']>;
  resyncShopifyOrders?: Maybe<Scalars['Int']['output']>;
  revokeApiToken?: Maybe<Scalars['Boolean']['output']>;
  setInfoAsUpToDate?: Maybe<Scalars['String']['output']>;
  setUserRole?: Maybe<Scalars['String']['output']>;
  setupShopifyCredentials?: Maybe<ShopifyData>;
  translateClaim: TranslateResponse;
  updateSeller?: Maybe<Scalars['String']['output']>;
  updateShopifyInfo?: Maybe<UpdatedShopifyData>;
  updateStock?: Maybe<Scalars['String']['output']>;
  updateUserLanguage?: Maybe<Scalars['String']['output']>;
  updateWidget?: Maybe<Scalars['String']['output']>;
  uploadCsvFile?: Maybe<Scalars['String']['output']>;
};

export type MutationConfirmCsvArgs = {
  saleId: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type MutationRemoveCsvArgs = {
  saleId: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type MutationResyncShopifyOrdersArgs = {
  saleId: Scalars['String']['input'];
};

export type MutationSetUserRoleArgs = {
  role: BrandRole;
  userId: Scalars['String']['input'];
};

export type MutationSetupShopifyCredentialsArgs = {
  brandId: Scalars['String']['input'];
  data: ShopifyCredentialsInput;
};

export type MutationTranslateClaimArgs = {
  claimId: Scalars['ID']['input'];
  to: Scalars['String']['input'];
};

export type MutationUpdateSellerArgs = {
  updateSeller: UpdateSeller;
};

export type MutationUpdateShopifyInfoArgs = {
  data: UpdateShopifyInfoInput;
};

export type MutationUpdateStockArgs = {
  saleId: Scalars['String']['input'];
  stock?: InputMaybe<Scalars['Int']['input']>;
  stockId: Scalars['String']['input'];
};

export type MutationUpdateUserLanguageArgs = {
  preferredLanguage: Scalars['String']['input'];
};

export type MutationUpdateWidgetArgs = {
  data: UpdateWidget;
  id: Scalars['String']['input'];
};

export type MutationUploadCsvFileArgs = {
  saleId: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type Option = {
  __typename?: 'Option';
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** **Order created** */
export type OrderCreated = {
  __typename?: 'OrderCreated';
  id: Scalars['ID']['output'];
};

/** **Order updated** */
export type OrderUpdated = {
  __typename?: 'OrderUpdated';
  id: Scalars['ID']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
};

export type Plugins = {
  __typename?: 'Plugins';
  shopify?: Maybe<Scalars['Json']['output']>;
};

export type Product = {
  __typename?: 'Product';
  category?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<Maybe<Option>>>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  variants?: Maybe<Array<Maybe<Variant>>>;
};

/** **Product stock updated** */
export type ProductStockUpdated = {
  __typename?: 'ProductStockUpdated';
  productId: Scalars['ID']['output'];
  productVariantId: Scalars['ID']['output'];
  stock: Scalars['Int']['output'];
};

export enum ProductsSort {
  Name = 'NAME',
  Stock = 'STOCK',
}

export type Profile = {
  __typename?: 'Profile';
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  lastname: Scalars['String']['output'];
  picture: Scalars['String']['output'];
  role: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  brand?: Maybe<Brand>;
  getBrandUsers: Array<Maybe<Profile>>;
  getUploadUrl?: Maybe<Scalars['Json']['output']>;
  products?: Maybe<Array<Maybe<Product>>>;
  ratings?: Maybe<Rates>;
  returns: Returns;
  /** **Get statistics for a sale per days ** */
  statsHistogram: Array<HistogramElement>;
  /** **Get statistics for a sale** */
  statsV2: StatsV2;
  topProductsStats: TopProductsStats;
  /** **Verify shopify identification ** */
  verifyShopifyCredentials: VerifyShopifyCredentialsOutput;
};

export type QueryGetUploadUrlArgs = {
  upload: Scalars['Upload']['input'];
};

export type QueryProductsArgs = {
  saleId: Scalars['String']['input'];
  sort?: InputMaybe<ProductsSort>;
};

export type QueryRatingsArgs = {
  filter?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyWithMessage?: InputMaybe<Scalars['Boolean']['input']>;
  saleId: Scalars['String']['input'];
};

export type QueryReturnsArgs = {
  saleId: Scalars['String']['input'];
};

export type QueryStatsHistogramArgs = {
  saleId: Scalars['NonEmptyString']['input'];
};

export type QueryStatsV2Args = {
  saleId: Scalars['NonEmptyString']['input'];
};

export type QueryTopProductsStatsArgs = {
  optionKey?: InputMaybe<Scalars['String']['input']>;
  saleId: Scalars['String']['input'];
};

export type QueryVerifyShopifyCredentialsArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

/** **Count of rates given by rates values** */
export type RateCountAggByRate = {
  __typename?: 'RateCountAggByRate';
  /** **Count of rates given for this rate value** */
  count: Scalars['Int']['output'];
  /** **Rate value** */
  rate: Scalars['Int']['output'];
};

export type Rates = {
  __typename?: 'Rates';
  nodes: Array<Rating>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Rating = {
  __typename?: 'Rating';
  avatar?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  products?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  rate?: Maybe<Scalars['Int']['output']>;
  rate_message?: Maybe<Scalars['String']['output']>;
  rate_updated_at?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ReturnProduct = {
  __typename?: 'ReturnProduct';
  description: Scalars['String']['output'];
  image: Scalars['String']['output'];
  options?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sum: Scalars['Int']['output'];
};

export type ReturnStat = {
  __typename?: 'ReturnStat';
  code: Scalars['String']['output'];
  sum: Scalars['Int']['output'];
};

export type Returns = {
  __typename?: 'Returns';
  percentage?: Maybe<Scalars['Float']['output']>;
  products?: Maybe<Array<Maybe<ReturnProduct>>>;
  returns?: Maybe<Array<Maybe<ReturnStat>>>;
  total_orders?: Maybe<Scalars['Int']['output']>;
  total_sum?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Sale = {
  __typename?: 'Sale';
  brand_id: Scalars['String']['output'];
  category?: Maybe<Scalars['String']['output']>;
  currency: StoreCurrency;
  description?: Maybe<Scalars['String']['output']>;
  end_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  is_selection?: Maybe<Scalars['Boolean']['output']>;
  logistics_manager?: Maybe<Scalars['String']['output']>;
  selection_name?: Maybe<Scalars['String']['output']>;
  start_at?: Maybe<Scalars['DateTime']['output']>;
  store: StoreRegion;
  widgets?: Maybe<Array<Maybe<Widget>>>;
};

export type ShopifyCredentialsInput = {
  carrierCode?: InputMaybe<Scalars['String']['input']>;
  internationalCarrierCode?: InputMaybe<Scalars['String']['input']>;
  /** **Set the price of an order while sending data to shopify (either 0 or the real price)** */
  isProductPriceKept: Scalars['Boolean']['input'];
  isStockLive: Scalars['Boolean']['input'];
  mondialRelayCarrierCode?: InputMaybe<Scalars['String']['input']>;
  sharedSecret: Scalars['String']['input'];
  shop: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type ShopifyData = {
  __typename?: 'ShopifyData';
  carrierCode?: Maybe<Scalars['String']['output']>;
  internationalCarrierCode?: Maybe<Scalars['String']['output']>;
  isProductPriceKept: Scalars['Boolean']['output'];
  isStockLive: Scalars['Boolean']['output'];
  mondialRelayCarrierCode?: Maybe<Scalars['String']['output']>;
  sharedSecret?: Maybe<Scalars['String']['output']>;
  shop: Scalars['String']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

/** **Statistics for a sale** */
export type StatsV2 = {
  __typename?: 'StatsV2';
  /** **Brand is subject to VAT ?** */
  isSubjectToVat: Scalars['Boolean']['output'];
  /**
   * **Count of orders**
   *
   * - > not removed
   * - > not from another order
   * - > older than 10min ago
   */
  orderCount: Scalars['Int']['output'];
  /**
   * **Count of products sold**
   *
   * - > from orders not removed
   * - > from orders not from another order
   * - > from orders older than 10min ago
   */
  productSoldCount: Scalars['Int']['output'];
  /**
   * **Average of rates given** (can be `null` if there is no rate)
   *
   * - > from orders not removed
   * - > from orders not from another order
   * - > from orders older than 10min ago
   */
  rateAvg?: Maybe<Scalars['Float']['output']>;
  /**
   * **Count of rates given**
   *
   * - > from orders not removed
   * - > from orders not from another order
   * - > from orders older than 10min ago
   */
  rateCount: Scalars['Int']['output'];
  /**
   * **Count of rates given by rates values**
   *
   * - > from orders not removed
   * - > from orders not from another order
   * - > from orders older than 10min ago
   */
  rateCountAggByRates: Array<RateCountAggByRate>;
  /**
   * **Amount due to the brand (without VAT)**
   *
   * - > *including* : shipping fees
   * - > *excluding* : commissions, refunds, returns
   */
  totalBillingHt: Scalars['Int']['output'];
  /**
   * **Amount due to the brand (include VAT)**
   *
   * - > *including* : shipping fees
   * - > *excluding* : commissions, refunds, returns
   */
  totalBillingTtc: Scalars['Int']['output'];
  /**
   * **Amount turnover (without VAT)**
   *
   * - > *including* : shipping fees, commissions
   * - > *excluding* : refunds, returns
   */
  totalTurnoverHt: Scalars['Int']['output'];
  /**
   * **Amount turnover (include VAT)**
   *
   * - > *including* : shipping fees, commissions
   * - > *excluding* : refunds, returns
   */
  totalTurnoverTtc: Scalars['Int']['output'];
};

export enum StoreCurrency {
  Eur = 'eur',
  Usd = 'usd',
}

export enum StoreRegion {
  Fr = 'fr',
  Us = 'us',
}

/** **Subscription** */
export type Subscription = {
  __typename?: 'Subscription';
  /** **Invoice created** */
  invoiceCreated: InvoiceCreated;
  /** **Invoice updated** */
  invoiceUpdated: InvoiceUpdated;
  /** **Order created** */
  orderCreated: OrderCreated;
  /** **Order updated** */
  orderUpdated: OrderUpdated;
  /** **Product stock updated** */
  productStockUpdated: ProductStockUpdated;
  /** **Widget updated** */
  widgetUpdated?: Maybe<Widget>;
};

export type TopProductInfo = {
  __typename?: 'TopProductInfo';
  group_id: Scalars['String']['output'];
  image_url: Scalars['String']['output'];
  options: Array<Scalars['String']['output']>;
  product_id: Scalars['String']['output'];
  product_name: Scalars['String']['output'];
  purchased_count: Scalars['Int']['output'];
  total_amount: Scalars['Int']['output'];
  variants: Array<TopProductVariantInfo>;
};

export type TopProductVariantInfo = {
  __typename?: 'TopProductVariantInfo';
  image_url: Scalars['String']['output'];
  option_value?: Maybe<Scalars['String']['output']>;
  options: Array<Scalars['String']['output']>;
  price: Scalars['Int']['output'];
  product_id: Scalars['String']['output'];
  product_name: Scalars['String']['output'];
  purchased_count: Scalars['Int']['output'];
  total_amount: Scalars['Int']['output'];
  variant_id: Scalars['String']['output'];
};

export type TopProductsStats = {
  __typename?: 'TopProductsStats';
  available_option_keys: Array<Scalars['String']['output']>;
  current_option_key?: Maybe<Scalars['String']['output']>;
  products: Array<Maybe<TopProductInfo>>;
};

export type TranslateResponse = {
  __typename?: 'TranslateResponse';
  originalLanguage?: Maybe<Scalars['String']['output']>;
  translation: Scalars['String']['output'];
};

export type UpdateSeller = {
  accept_contract?: InputMaybe<Scalars['Boolean']['input']>;
  account_number?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['Json']['input']>;
  bank_address?: InputMaybe<Scalars['Json']['input']>;
  beneficiary_name?: InputMaybe<Scalars['String']['input']>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  contact_phone?: InputMaybe<Scalars['String']['input']>;
  delivery_services?: InputMaybe<Scalars['Json']['input']>;
  has_mixed_delivery_delay?: InputMaybe<Scalars['Boolean']['input']>;
  has_pre_order_delivery?: InputMaybe<Scalars['Boolean']['input']>;
  has_shopify?: InputMaybe<Scalars['Boolean']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  intracommunity_vat?: InputMaybe<Scalars['String']['input']>;
  is_autoentrepreneur?: InputMaybe<Scalars['Boolean']['input']>;
  is_french?: InputMaybe<Scalars['Boolean']['input']>;
  max_delivery_delay?: InputMaybe<Scalars['Int']['input']>;
  min_delivery_delay?: InputMaybe<Scalars['Int']['input']>;
  return_address?: InputMaybe<Scalars['Json']['input']>;
  routing_code?: InputMaybe<Scalars['String']['input']>;
  shipping_country?: InputMaybe<Scalars['String']['input']>;
  shipping_services?: InputMaybe<Scalars['Json']['input']>;
  shopify_url?: InputMaybe<Scalars['String']['input']>;
  siret?: InputMaybe<Scalars['String']['input']>;
  swift_code?: InputMaybe<Scalars['String']['input']>;
  tax_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  used_couriers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateShopifyInfoInput = {
  carrierCode?: InputMaybe<Scalars['String']['input']>;
  internationalCarrierCode?: InputMaybe<Scalars['String']['input']>;
  /** **Set the price of an order while sending data to shopify (either 0 or the real price)** */
  isProductPriceKept: Scalars['Boolean']['input'];
  isStockLive: Scalars['Boolean']['input'];
  mondialRelayCarrierCode?: InputMaybe<Scalars['String']['input']>;
  sharedSecret?: InputMaybe<Scalars['String']['input']>;
  /** ** Connection information */
  shop?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWidget = {
  results?: InputMaybe<Scalars['Json']['input']>;
  state: WidgetState;
};

export type UpdatedShopifyData = {
  __typename?: 'UpdatedShopifyData';
  carrierCode?: Maybe<Scalars['String']['output']>;
  internationalCarrierCode?: Maybe<Scalars['String']['output']>;
  isProductPriceKept: Scalars['Boolean']['output'];
  isStockLive: Scalars['Boolean']['output'];
  mondialRelayCarrierCode?: Maybe<Scalars['String']['output']>;
};

export type Variant = {
  __typename?: 'Variant';
  buying_price?: Maybe<Scalars['Int']['output']>;
  delivery_delays?: Maybe<Scalars['Int']['output']>;
  discount?: Maybe<Scalars['Int']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  options?: Maybe<Scalars['Json']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  retail_price?: Maybe<Scalars['Int']['output']>;
  shipping_cost?: Maybe<Scalars['Int']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  stock?: Maybe<Scalars['Int']['output']>;
  stock_id: Scalars['String']['output'];
};

export enum VerifyShopifyCredentialsError {
  MissingScope = 'MISSING_SCOPE',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  UnknownError = 'UNKNOWN_ERROR',
}

export type VerifyShopifyCredentialsOutput = {
  __typename?: 'VerifyShopifyCredentialsOutput';
  /** **Error message** */
  error?: Maybe<VerifyShopifyCredentialsError>;
  /** **Tells if the access token is correct for url provided** */
  isValidAccessToken: Scalars['Boolean']['output'];
  /** **Tells if the URL is a valid shopify url** */
  isValidUrl: Scalars['Boolean']['output'];
};

export type Widget = {
  __typename?: 'Widget';
  brand_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  proposition?: Maybe<Scalars['Json']['output']>;
  results?: Maybe<Scalars['Json']['output']>;
  state?: Maybe<WidgetState>;
  type?: Maybe<WidgetType>;
};

export enum WidgetState {
  Accepted = 'ACCEPTED',
  Refused = 'REFUSED',
}

export enum WidgetType {
  Duration = 'DURATION',
  Stock = 'STOCK',
}

export type WidgetFieldsFragment = {
  __typename?: 'Widget';
  id: string;
  type?: WidgetType | null;
  state?: WidgetState | null;
  results?: any | null;
  proposition?: any | null;
};

export type BrandQueryVariables = Exact<{ [key: string]: never }>;

export type BrandQuery = {
  __typename?: 'Query';
  brand?: {
    __typename?: 'Brand';
    id: string;
    name?: string | null;
    store: StoreRegion;
    is_french?: boolean | null;
    is_autoentrepreneur?: boolean | null;
    contact_email?: string | null;
    contact_phone?: string | null;
    siret?: string | null;
    intracommunity_vat?: string | null;
    tax_id?: string | null;
    beneficiary_name?: string | null;
    account_number?: string | null;
    routing_code?: string | null;
    swift_code?: string | null;
    bank_address?: any | null;
    iban?: string | null;
    contact_name?: string | null;
    address?: any | null;
    return_address?: any | null;
    shipping_country?: string | null;
    has_mixed_delivery_delay?: boolean | null;
    has_pre_order_delivery?: boolean | null;
    has_shopify?: boolean | null;
    shopify_url?: string | null;
    shipping_services?: any | null;
    max_delivery_delay?: number | null;
    min_delivery_delay?: number | null;
    delivery_services?: any | null;
    accept_contract?: boolean | null;
    need_refresh_info: boolean;
    timezone?: string | null;
    serverTime?: string | null;
    plugins?: { __typename?: 'Plugins'; shopify?: any | null } | null;
    shopify?: {
      __typename?: 'ShopifyData';
      shop: string;
      token?: string | null;
      sharedSecret?: string | null;
      isStockLive: boolean;
      carrierCode?: string | null;
      internationalCarrierCode?: string | null;
      isProductPriceKept: boolean;
      mondialRelayCarrierCode?: string | null;
    } | null;
    sales?: Array<{
      __typename?: 'Sale';
      id: string;
      start_at?: string | null;
      end_at?: string | null;
      logistics_manager?: string | null;
      selection_name?: string | null;
      store: StoreRegion;
      currency: StoreCurrency;
      widgets?: Array<{
        __typename?: 'Widget';
        id: string;
        type?: WidgetType | null;
        state?: WidgetState | null;
        results?: any | null;
        proposition?: any | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ApiKeyQueryVariables = Exact<{ [key: string]: never }>;

export type ApiKeyQuery = {
  __typename?: 'Query';
  brand?: { __typename?: 'Brand'; api_token?: string | null } | null;
};

export type StockQueryVariables = Exact<{
  saleId: Scalars['String']['input'];
  sort?: InputMaybe<ProductsSort>;
}>;

export type StockQuery = {
  __typename?: 'Query';
  products?: Array<{
    __typename?: 'Product';
    name?: string | null;
    id: string;
    type?: string | null;
    url?: string | null;
    category?: string | null;
    images?: Array<string | null> | null;
    variants?: Array<{
      __typename?: 'Variant';
      images?: Array<string | null> | null;
      sku?: string | null;
      stock?: number | null;
      id: string;
      gtin?: string | null;
      stock_id: string;
      options?: any | null;
    } | null> | null;
  } | null> | null;
};

export type StatsHistogramQueryVariables = Exact<{
  saleId: Scalars['NonEmptyString']['input'];
}>;

export type StatsHistogramQuery = {
  __typename?: 'Query';
  statsHistogram: Array<{
    __typename?: 'HistogramElement';
    nb_distinct_orders?: number | null;
    date: string;
    total_operation_ht?: number | null;
    total_operation_ttc?: number | null;
  }>;
};

export type RatingsQueryVariables = Exact<{
  saleId: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyWithMessage?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['Int']['input']>;
}>;

export type RatingsQuery = {
  __typename?: 'Query';
  ratings?: {
    __typename?: 'Rates';
    updatedAt?: string | null;
    totalCount?: number | null;
    totalPages?: number | null;
    nodes: Array<{
      __typename?: 'Rating';
      rate?: number | null;
      rate_updated_at?: string | null;
      url?: string | null;
      products?: Array<any | null> | null;
      name?: string | null;
      avatar?: string | null;
      rate_message?: string | null;
      order_id?: string | null;
    }>;
    pageInfo?: { __typename?: 'PageInfo'; hasNextPage?: boolean | null } | null;
  } | null;
};

export type TopProductsStatsQueryVariables = Exact<{
  saleId: Scalars['String']['input'];
  optionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type TopProductsStatsQuery = {
  __typename?: 'Query';
  topProductsStats: {
    __typename?: 'TopProductsStats';
    available_option_keys: Array<string>;
    current_option_key?: string | null;
    products: Array<{
      __typename?: 'TopProductInfo';
      group_id: string;
      product_id: string;
      product_name: string;
      image_url: string;
      options: Array<string>;
      purchased_count: number;
      total_amount: number;
      variants: Array<{
        __typename?: 'TopProductVariantInfo';
        product_id: string;
        variant_id: string;
        product_name: string;
        image_url: string;
        option_value?: string | null;
        options: Array<string>;
        purchased_count: number;
        price: number;
        total_amount: number;
      }>;
    } | null>;
  };
};

export type StatsQueryVariables = Exact<{
  saleId: Scalars['NonEmptyString']['input'];
}>;

export type StatsQuery = {
  __typename?: 'Query';
  statsV2: {
    __typename?: 'StatsV2';
    rateCount: number;
    rateAvg?: number | null;
    productSoldCount: number;
    orderCount: number;
    totalBillingHt: number;
    totalBillingTtc: number;
    totalTurnoverHt: number;
    totalTurnoverTtc: number;
    isSubjectToVat: boolean;
    rateCountAggByRates: Array<{
      __typename?: 'RateCountAggByRate';
      rate: number;
      count: number;
    }>;
  };
};

export type ReturnsQueryVariables = Exact<{
  saleId: Scalars['String']['input'];
}>;

export type ReturnsQuery = {
  __typename?: 'Query';
  returns: {
    __typename?: 'Returns';
    updatedAt?: string | null;
    total_orders?: number | null;
    total_sum?: number | null;
    percentage?: number | null;
    returns?: Array<{
      __typename?: 'ReturnStat';
      sum: number;
      code: string;
    } | null> | null;
    products?: Array<{
      __typename?: 'ReturnProduct';
      image: string;
      description: string;
      options?: Array<string | null> | null;
      sum: number;
    } | null> | null;
  };
};

export type UploadUrlQueryVariables = Exact<{
  upload: Scalars['Upload']['input'];
}>;

export type UploadUrlQuery = {
  __typename?: 'Query';
  getUploadUrl?: any | null;
};

export type VerifyShopifyCredentialsQueryVariables = Exact<{
  url: Scalars['String']['input'];
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type VerifyShopifyCredentialsQuery = {
  __typename?: 'Query';
  verifyShopifyCredentials: {
    __typename?: 'VerifyShopifyCredentialsOutput';
    isValidAccessToken: boolean;
    isValidUrl: boolean;
    error?: VerifyShopifyCredentialsError | null;
  };
};

export type RemoveCsvMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
  url: Scalars['String']['input'];
}>;

export type RemoveCsvMutation = {
  __typename?: 'Mutation';
  removeCsv?: string | null;
};

export type ConfirmCsvMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
  url: Scalars['String']['input'];
}>;

export type ConfirmCsvMutation = {
  __typename?: 'Mutation';
  confirmCsv?: string | null;
};

export type UploadCsvFileMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
  url: Scalars['String']['input'];
}>;

export type UploadCsvFileMutation = {
  __typename?: 'Mutation';
  uploadCsvFile?: string | null;
};

export type UpdateStockMutationVariables = Exact<{
  stockId: Scalars['String']['input'];
  saleId: Scalars['String']['input'];
  stock?: InputMaybe<Scalars['Int']['input']>;
}>;

export type UpdateStockMutation = {
  __typename?: 'Mutation';
  updateStock?: string | null;
};

export type UpdateWidgetMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: UpdateWidget;
}>;

export type UpdateWidgetMutation = {
  __typename?: 'Mutation';
  updateWidget?: string | null;
};

export type UpdateSellerMutationVariables = Exact<{
  updateSeller: UpdateSeller;
}>;

export type UpdateSellerMutation = {
  __typename?: 'Mutation';
  updateSeller?: string | null;
};

export type SetInfoAsUpToDateMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SetInfoAsUpToDateMutation = {
  __typename?: 'Mutation';
  setInfoAsUpToDate?: string | null;
};

export type TranslateClaimMutationVariables = Exact<{
  claimId: Scalars['ID']['input'];
  to: Scalars['String']['input'];
}>;

export type TranslateClaimMutation = {
  __typename?: 'Mutation';
  translateClaim: {
    __typename?: 'TranslateResponse';
    originalLanguage?: string | null;
    translation: string;
  };
};

export type ResyncShopifyOrdersMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
}>;

export type ResyncShopifyOrdersMutation = {
  __typename?: 'Mutation';
  resyncShopifyOrders?: number | null;
};

export type SetupShopifyCredentialsMutationVariables = Exact<{
  brandId: Scalars['String']['input'];
  data: ShopifyCredentialsInput;
}>;

export type SetupShopifyCredentialsMutation = {
  __typename?: 'Mutation';
  setupShopifyCredentials?: {
    __typename?: 'ShopifyData';
    token?: string | null;
    shop: string;
    isStockLive: boolean;
    sharedSecret?: string | null;
    carrierCode?: string | null;
    internationalCarrierCode?: string | null;
    isProductPriceKept: boolean;
    mondialRelayCarrierCode?: string | null;
  } | null;
};

export type UpdateShopifyInfoMutationVariables = Exact<{
  data: UpdateShopifyInfoInput;
}>;

export type UpdateShopifyInfoMutation = {
  __typename?: 'Mutation';
  updateShopifyInfo?: {
    __typename?: 'UpdatedShopifyData';
    isStockLive: boolean;
    isProductPriceKept: boolean;
    carrierCode?: string | null;
    internationalCarrierCode?: string | null;
    mondialRelayCarrierCode?: string | null;
  } | null;
};

export type InvoiceCreatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type InvoiceCreatedSubscription = {
  __typename?: 'Subscription';
  invoiceCreated: { __typename?: 'InvoiceCreated'; id: string };
};

export type InvoiceUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type InvoiceUpdatedSubscription = {
  __typename?: 'Subscription';
  invoiceUpdated: { __typename?: 'InvoiceUpdated'; id: string };
};

export type OrderCreatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OrderCreatedSubscription = {
  __typename?: 'Subscription';
  orderCreated: { __typename?: 'OrderCreated'; id: string };
};

export type OrderUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OrderUpdatedSubscription = {
  __typename?: 'Subscription';
  orderUpdated: { __typename?: 'OrderUpdated'; id: string };
};

export type WidgetUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type WidgetUpdatedSubscription = {
  __typename?: 'Subscription';
  widgetUpdated?: {
    __typename?: 'Widget';
    id: string;
    type?: WidgetType | null;
    state?: WidgetState | null;
    results?: any | null;
    proposition?: any | null;
  } | null;
};

export type ProductStockUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type ProductStockUpdatedSubscription = {
  __typename?: 'Subscription';
  productStockUpdated: {
    __typename?: 'ProductStockUpdated';
    productId: string;
    productVariantId: string;
    stock: number;
  };
};

export const WidgetFieldsFragmentDoc = `
    fragment WidgetFields on Widget {
  id
  type
  state
  results
  proposition
}
    `;
export const BrandDocument = `
    query brand {
  brand {
    id
    name
    store
    is_french
    is_autoentrepreneur
    contact_email
    contact_phone
    siret
    intracommunity_vat
    tax_id
    beneficiary_name
    account_number
    routing_code
    swift_code
    bank_address
    plugins {
      shopify
    }
    shopify {
      shop
      token
      sharedSecret
      isStockLive
      carrierCode
      internationalCarrierCode
      isProductPriceKept
      mondialRelayCarrierCode
    }
    iban
    contact_name
    address
    return_address
    shipping_country
    has_mixed_delivery_delay
    has_pre_order_delivery
    has_shopify
    shopify_url
    shipping_services
    max_delivery_delay
    min_delivery_delay
    delivery_services
    accept_contract
    need_refresh_info
    timezone
    serverTime
    sales {
      id
      start_at
      end_at
      logistics_manager
      selection_name
      widgets {
        ...WidgetFields
      }
      store
      currency
    }
  }
}
    ${WidgetFieldsFragmentDoc}`;

export const useBrandQuery = <TData = BrandQuery, TError = unknown>(
  variables?: BrandQueryVariables,
  options?: Omit<UseQueryOptions<BrandQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<BrandQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<BrandQuery, TError, TData>({
    queryKey: variables === undefined ? ['brand'] : ['brand', variables],
    queryFn: useFetchData<BrandQuery, BrandQueryVariables>(BrandDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useSuspenseBrandQuery = <TData = BrandQuery, TError = unknown>(
  variables?: BrandQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<BrandQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<BrandQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<BrandQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['brandSuspense']
        : ['brandSuspense', variables],
    queryFn: useFetchData<BrandQuery, BrandQueryVariables>(BrandDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useInfiniteBrandQuery = <
  TData = InfiniteData<BrandQuery>,
  TError = unknown,
>(
  variables: BrandQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<BrandQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<BrandQuery, TError, TData>['queryKey'];
  }
) => {
  const query = useFetchData<BrandQuery, BrandQueryVariables>(BrandDocument);
  return useInfiniteQuery<BrandQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          (optionsQueryKey ?? variables === undefined)
            ? ['brand.infinite']
            : ['brand.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteBrandQuery = <
  TData = InfiniteData<BrandQuery>,
  TError = unknown,
>(
  variables: BrandQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<BrandQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      BrandQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<BrandQuery, BrandQueryVariables>(BrandDocument);
  return useSuspenseInfiniteQuery<BrandQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          (optionsQueryKey ?? variables === undefined)
            ? ['brand.infiniteSuspense']
            : ['brand.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const ApiKeyDocument = `
    query apiKey {
  brand {
    api_token
  }
}
    `;

export const useApiKeyQuery = <TData = ApiKeyQuery, TError = unknown>(
  variables?: ApiKeyQueryVariables,
  options?: Omit<UseQueryOptions<ApiKeyQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<ApiKeyQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<ApiKeyQuery, TError, TData>({
    queryKey: variables === undefined ? ['apiKey'] : ['apiKey', variables],
    queryFn: useFetchData<ApiKeyQuery, ApiKeyQueryVariables>(
      ApiKeyDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseApiKeyQuery = <TData = ApiKeyQuery, TError = unknown>(
  variables?: ApiKeyQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<ApiKeyQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<ApiKeyQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<ApiKeyQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['apiKeySuspense']
        : ['apiKeySuspense', variables],
    queryFn: useFetchData<ApiKeyQuery, ApiKeyQueryVariables>(
      ApiKeyDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteApiKeyQuery = <
  TData = InfiniteData<ApiKeyQuery>,
  TError = unknown,
>(
  variables: ApiKeyQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<ApiKeyQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<ApiKeyQuery, TError, TData>['queryKey'];
  }
) => {
  const query = useFetchData<ApiKeyQuery, ApiKeyQueryVariables>(ApiKeyDocument);
  return useInfiniteQuery<ApiKeyQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          (optionsQueryKey ?? variables === undefined)
            ? ['apiKey.infinite']
            : ['apiKey.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteApiKeyQuery = <
  TData = InfiniteData<ApiKeyQuery>,
  TError = unknown,
>(
  variables: ApiKeyQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<ApiKeyQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      ApiKeyQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<ApiKeyQuery, ApiKeyQueryVariables>(ApiKeyDocument);
  return useSuspenseInfiniteQuery<ApiKeyQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          (optionsQueryKey ?? variables === undefined)
            ? ['apiKey.infiniteSuspense']
            : ['apiKey.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const StockDocument = `
    query stock($saleId: String!, $sort: ProductsSort) {
  products(saleId: $saleId, sort: $sort) {
    name
    id
    type
    url
    category
    images
    variants {
      images
      sku
      stock
      id
      gtin
      stock_id
      options
    }
  }
}
    `;

export const useStockQuery = <TData = StockQuery, TError = unknown>(
  variables: StockQueryVariables,
  options?: Omit<UseQueryOptions<StockQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<StockQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<StockQuery, TError, TData>({
    queryKey: ['stock', variables],
    queryFn: useFetchData<StockQuery, StockQueryVariables>(StockDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useSuspenseStockQuery = <TData = StockQuery, TError = unknown>(
  variables: StockQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<StockQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<StockQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<StockQuery, TError, TData>({
    queryKey: ['stockSuspense', variables],
    queryFn: useFetchData<StockQuery, StockQueryVariables>(StockDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useInfiniteStockQuery = <
  TData = InfiniteData<StockQuery>,
  TError = unknown,
>(
  variables: StockQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<StockQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<StockQuery, TError, TData>['queryKey'];
  }
) => {
  const query = useFetchData<StockQuery, StockQueryVariables>(StockDocument);
  return useInfiniteQuery<StockQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['stock.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteStockQuery = <
  TData = InfiniteData<StockQuery>,
  TError = unknown,
>(
  variables: StockQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<StockQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      StockQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<StockQuery, StockQueryVariables>(StockDocument);
  return useSuspenseInfiniteQuery<StockQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['stock.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const StatsHistogramDocument = `
    query statsHistogram($saleId: NonEmptyString!) {
  statsHistogram(saleId: $saleId) {
    nb_distinct_orders
    date
    total_operation_ht
    total_operation_ttc
  }
}
    `;

export const useStatsHistogramQuery = <
  TData = StatsHistogramQuery,
  TError = unknown,
>(
  variables: StatsHistogramQueryVariables,
  options?: Omit<
    UseQueryOptions<StatsHistogramQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<StatsHistogramQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<StatsHistogramQuery, TError, TData>({
    queryKey: ['statsHistogram', variables],
    queryFn: useFetchData<StatsHistogramQuery, StatsHistogramQueryVariables>(
      StatsHistogramDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseStatsHistogramQuery = <
  TData = StatsHistogramQuery,
  TError = unknown,
>(
  variables: StatsHistogramQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<StatsHistogramQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      StatsHistogramQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<StatsHistogramQuery, TError, TData>({
    queryKey: ['statsHistogramSuspense', variables],
    queryFn: useFetchData<StatsHistogramQuery, StatsHistogramQueryVariables>(
      StatsHistogramDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteStatsHistogramQuery = <
  TData = InfiniteData<StatsHistogramQuery>,
  TError = unknown,
>(
  variables: StatsHistogramQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<StatsHistogramQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      StatsHistogramQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<StatsHistogramQuery, StatsHistogramQueryVariables>(
    StatsHistogramDocument
  );
  return useInfiniteQuery<StatsHistogramQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['statsHistogram.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteStatsHistogramQuery = <
  TData = InfiniteData<StatsHistogramQuery>,
  TError = unknown,
>(
  variables: StatsHistogramQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<StatsHistogramQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      StatsHistogramQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<StatsHistogramQuery, StatsHistogramQueryVariables>(
    StatsHistogramDocument
  );
  return useSuspenseInfiniteQuery<StatsHistogramQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'statsHistogram.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const RatingsDocument = `
    query ratings($saleId: String!, $offset: Int, $onlyWithMessage: Boolean, $filter: Int) {
  ratings(
    saleId: $saleId
    offset: $offset
    onlyWithMessage: $onlyWithMessage
    filter: $filter
  ) {
    updatedAt
    nodes {
      rate
      rate_updated_at
      url
      products
      name
      avatar
      rate_message
      order_id
    }
    pageInfo {
      hasNextPage
    }
    totalCount
    totalPages
  }
}
    `;

export const useRatingsQuery = <TData = RatingsQuery, TError = unknown>(
  variables: RatingsQueryVariables,
  options?: Omit<UseQueryOptions<RatingsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<RatingsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<RatingsQuery, TError, TData>({
    queryKey: ['ratings', variables],
    queryFn: useFetchData<RatingsQuery, RatingsQueryVariables>(
      RatingsDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseRatingsQuery = <TData = RatingsQuery, TError = unknown>(
  variables: RatingsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<RatingsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<RatingsQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<RatingsQuery, TError, TData>({
    queryKey: ['ratingsSuspense', variables],
    queryFn: useFetchData<RatingsQuery, RatingsQueryVariables>(
      RatingsDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteRatingsQuery = <
  TData = InfiniteData<RatingsQuery>,
  TError = unknown,
>(
  variables: RatingsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<RatingsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<RatingsQuery, TError, TData>['queryKey'];
  }
) => {
  const query = useFetchData<RatingsQuery, RatingsQueryVariables>(
    RatingsDocument
  );
  return useInfiniteQuery<RatingsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['ratings.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteRatingsQuery = <
  TData = InfiniteData<RatingsQuery>,
  TError = unknown,
>(
  variables: RatingsQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<RatingsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      RatingsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<RatingsQuery, RatingsQueryVariables>(
    RatingsDocument
  );
  return useSuspenseInfiniteQuery<RatingsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['ratings.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const TopProductsStatsDocument = `
    query topProductsStats($saleId: String!, $optionKey: String) {
  topProductsStats(saleId: $saleId, optionKey: $optionKey) {
    available_option_keys
    current_option_key
    products {
      group_id
      product_id
      product_name
      image_url
      options
      purchased_count
      total_amount
      variants {
        product_id
        variant_id
        product_name
        image_url
        option_value
        options
        purchased_count
        price
        total_amount
      }
    }
  }
}
    `;

export const useTopProductsStatsQuery = <
  TData = TopProductsStatsQuery,
  TError = unknown,
>(
  variables: TopProductsStatsQueryVariables,
  options?: Omit<
    UseQueryOptions<TopProductsStatsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      TopProductsStatsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<TopProductsStatsQuery, TError, TData>({
    queryKey: ['topProductsStats', variables],
    queryFn: useFetchData<
      TopProductsStatsQuery,
      TopProductsStatsQueryVariables
    >(TopProductsStatsDocument).bind(null, variables),
    ...options,
  });
};

export const useSuspenseTopProductsStatsQuery = <
  TData = TopProductsStatsQuery,
  TError = unknown,
>(
  variables: TopProductsStatsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<TopProductsStatsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      TopProductsStatsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<TopProductsStatsQuery, TError, TData>({
    queryKey: ['topProductsStatsSuspense', variables],
    queryFn: useFetchData<
      TopProductsStatsQuery,
      TopProductsStatsQueryVariables
    >(TopProductsStatsDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteTopProductsStatsQuery = <
  TData = InfiniteData<TopProductsStatsQuery>,
  TError = unknown,
>(
  variables: TopProductsStatsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<TopProductsStatsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      TopProductsStatsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    TopProductsStatsQuery,
    TopProductsStatsQueryVariables
  >(TopProductsStatsDocument);
  return useInfiniteQuery<TopProductsStatsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['topProductsStats.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteTopProductsStatsQuery = <
  TData = InfiniteData<TopProductsStatsQuery>,
  TError = unknown,
>(
  variables: TopProductsStatsQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<TopProductsStatsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      TopProductsStatsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    TopProductsStatsQuery,
    TopProductsStatsQueryVariables
  >(TopProductsStatsDocument);
  return useSuspenseInfiniteQuery<TopProductsStatsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'topProductsStats.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const StatsDocument = `
    query stats($saleId: NonEmptyString!) {
  statsV2(saleId: $saleId) {
    rateCountAggByRates {
      rate
      count
    }
    rateCount
    rateAvg
    productSoldCount
    orderCount
    totalBillingHt
    totalBillingTtc
    totalTurnoverHt
    totalTurnoverTtc
    isSubjectToVat
  }
}
    `;

export const useStatsQuery = <TData = StatsQuery, TError = unknown>(
  variables: StatsQueryVariables,
  options?: Omit<UseQueryOptions<StatsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<StatsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<StatsQuery, TError, TData>({
    queryKey: ['stats', variables],
    queryFn: useFetchData<StatsQuery, StatsQueryVariables>(StatsDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useSuspenseStatsQuery = <TData = StatsQuery, TError = unknown>(
  variables: StatsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<StatsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<StatsQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<StatsQuery, TError, TData>({
    queryKey: ['statsSuspense', variables],
    queryFn: useFetchData<StatsQuery, StatsQueryVariables>(StatsDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useInfiniteStatsQuery = <
  TData = InfiniteData<StatsQuery>,
  TError = unknown,
>(
  variables: StatsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<StatsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<StatsQuery, TError, TData>['queryKey'];
  }
) => {
  const query = useFetchData<StatsQuery, StatsQueryVariables>(StatsDocument);
  return useInfiniteQuery<StatsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['stats.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteStatsQuery = <
  TData = InfiniteData<StatsQuery>,
  TError = unknown,
>(
  variables: StatsQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<StatsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      StatsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<StatsQuery, StatsQueryVariables>(StatsDocument);
  return useSuspenseInfiniteQuery<StatsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['stats.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const ReturnsDocument = `
    query returns($saleId: String!) {
  returns(saleId: $saleId) {
    updatedAt
    total_orders
    total_sum
    percentage
    returns {
      sum
      code
    }
    products {
      image
      description
      options
      sum
    }
  }
}
    `;

export const useReturnsQuery = <TData = ReturnsQuery, TError = unknown>(
  variables: ReturnsQueryVariables,
  options?: Omit<UseQueryOptions<ReturnsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<ReturnsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<ReturnsQuery, TError, TData>({
    queryKey: ['returns', variables],
    queryFn: useFetchData<ReturnsQuery, ReturnsQueryVariables>(
      ReturnsDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseReturnsQuery = <TData = ReturnsQuery, TError = unknown>(
  variables: ReturnsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<ReturnsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<ReturnsQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<ReturnsQuery, TError, TData>({
    queryKey: ['returnsSuspense', variables],
    queryFn: useFetchData<ReturnsQuery, ReturnsQueryVariables>(
      ReturnsDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteReturnsQuery = <
  TData = InfiniteData<ReturnsQuery>,
  TError = unknown,
>(
  variables: ReturnsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<ReturnsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<ReturnsQuery, TError, TData>['queryKey'];
  }
) => {
  const query = useFetchData<ReturnsQuery, ReturnsQueryVariables>(
    ReturnsDocument
  );
  return useInfiniteQuery<ReturnsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['returns.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteReturnsQuery = <
  TData = InfiniteData<ReturnsQuery>,
  TError = unknown,
>(
  variables: ReturnsQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<ReturnsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      ReturnsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<ReturnsQuery, ReturnsQueryVariables>(
    ReturnsDocument
  );
  return useSuspenseInfiniteQuery<ReturnsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['returns.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const UploadUrlDocument = `
    query uploadUrl($upload: Upload!) {
  getUploadUrl(upload: $upload)
}
    `;

export const useUploadUrlQuery = <TData = UploadUrlQuery, TError = unknown>(
  variables: UploadUrlQueryVariables,
  options?: Omit<UseQueryOptions<UploadUrlQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<UploadUrlQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<UploadUrlQuery, TError, TData>({
    queryKey: ['uploadUrl', variables],
    queryFn: useFetchData<UploadUrlQuery, UploadUrlQueryVariables>(
      UploadUrlDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseUploadUrlQuery = <
  TData = UploadUrlQuery,
  TError = unknown,
>(
  variables: UploadUrlQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<UploadUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      UploadUrlQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<UploadUrlQuery, TError, TData>({
    queryKey: ['uploadUrlSuspense', variables],
    queryFn: useFetchData<UploadUrlQuery, UploadUrlQueryVariables>(
      UploadUrlDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteUploadUrlQuery = <
  TData = InfiniteData<UploadUrlQuery>,
  TError = unknown,
>(
  variables: UploadUrlQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<UploadUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      UploadUrlQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<UploadUrlQuery, UploadUrlQueryVariables>(
    UploadUrlDocument
  );
  return useInfiniteQuery<UploadUrlQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['uploadUrl.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteUploadUrlQuery = <
  TData = InfiniteData<UploadUrlQuery>,
  TError = unknown,
>(
  variables: UploadUrlQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<UploadUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      UploadUrlQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<UploadUrlQuery, UploadUrlQueryVariables>(
    UploadUrlDocument
  );
  return useSuspenseInfiniteQuery<UploadUrlQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['uploadUrl.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const VerifyShopifyCredentialsDocument = `
    query verifyShopifyCredentials($url: String!, $accessToken: String) {
  verifyShopifyCredentials(url: $url, accessToken: $accessToken) {
    isValidAccessToken
    isValidUrl
    error
  }
}
    `;

export const useVerifyShopifyCredentialsQuery = <
  TData = VerifyShopifyCredentialsQuery,
  TError = unknown,
>(
  variables: VerifyShopifyCredentialsQueryVariables,
  options?: Omit<
    UseQueryOptions<VerifyShopifyCredentialsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      VerifyShopifyCredentialsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<VerifyShopifyCredentialsQuery, TError, TData>({
    queryKey: ['verifyShopifyCredentials', variables],
    queryFn: useFetchData<
      VerifyShopifyCredentialsQuery,
      VerifyShopifyCredentialsQueryVariables
    >(VerifyShopifyCredentialsDocument).bind(null, variables),
    ...options,
  });
};

export const useSuspenseVerifyShopifyCredentialsQuery = <
  TData = VerifyShopifyCredentialsQuery,
  TError = unknown,
>(
  variables: VerifyShopifyCredentialsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<VerifyShopifyCredentialsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      VerifyShopifyCredentialsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<VerifyShopifyCredentialsQuery, TError, TData>({
    queryKey: ['verifyShopifyCredentialsSuspense', variables],
    queryFn: useFetchData<
      VerifyShopifyCredentialsQuery,
      VerifyShopifyCredentialsQueryVariables
    >(VerifyShopifyCredentialsDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteVerifyShopifyCredentialsQuery = <
  TData = InfiniteData<VerifyShopifyCredentialsQuery>,
  TError = unknown,
>(
  variables: VerifyShopifyCredentialsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<VerifyShopifyCredentialsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      VerifyShopifyCredentialsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    VerifyShopifyCredentialsQuery,
    VerifyShopifyCredentialsQueryVariables
  >(VerifyShopifyCredentialsDocument);
  return useInfiniteQuery<VerifyShopifyCredentialsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'verifyShopifyCredentials.infinite',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteVerifyShopifyCredentialsQuery = <
  TData = InfiniteData<VerifyShopifyCredentialsQuery>,
  TError = unknown,
>(
  variables: VerifyShopifyCredentialsQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<
      VerifyShopifyCredentialsQuery,
      TError,
      TData
    >,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      VerifyShopifyCredentialsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    VerifyShopifyCredentialsQuery,
    VerifyShopifyCredentialsQueryVariables
  >(VerifyShopifyCredentialsDocument);
  return useSuspenseInfiniteQuery<VerifyShopifyCredentialsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'verifyShopifyCredentials.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const RemoveCsvDocument = `
    mutation removeCsv($saleId: String!, $url: String!) {
  removeCsv(saleId: $saleId, url: $url)
}
    `;

export const useRemoveCsvMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RemoveCsvMutation,
    TError,
    RemoveCsvMutationVariables,
    TContext
  >
) => {
  return useMutation<
    RemoveCsvMutation,
    TError,
    RemoveCsvMutationVariables,
    TContext
  >({
    mutationKey: ['removeCsv'],
    mutationFn: useFetchData<RemoveCsvMutation, RemoveCsvMutationVariables>(
      RemoveCsvDocument
    ),
    ...options,
  });
};

export const ConfirmCsvDocument = `
    mutation confirmCsv($saleId: String!, $url: String!) {
  confirmCsv(saleId: $saleId, url: $url)
}
    `;

export const useConfirmCsvMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ConfirmCsvMutation,
    TError,
    ConfirmCsvMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ConfirmCsvMutation,
    TError,
    ConfirmCsvMutationVariables,
    TContext
  >({
    mutationKey: ['confirmCsv'],
    mutationFn: useFetchData<ConfirmCsvMutation, ConfirmCsvMutationVariables>(
      ConfirmCsvDocument
    ),
    ...options,
  });
};

export const UploadCsvFileDocument = `
    mutation uploadCsvFile($saleId: String!, $url: String!) {
  uploadCsvFile(saleId: $saleId, url: $url)
}
    `;

export const useUploadCsvFileMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UploadCsvFileMutation,
    TError,
    UploadCsvFileMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UploadCsvFileMutation,
    TError,
    UploadCsvFileMutationVariables,
    TContext
  >({
    mutationKey: ['uploadCsvFile'],
    mutationFn: useFetchData<
      UploadCsvFileMutation,
      UploadCsvFileMutationVariables
    >(UploadCsvFileDocument),
    ...options,
  });
};

export const UpdateStockDocument = `
    mutation updateStock($stockId: String!, $saleId: String!, $stock: Int) {
  updateStock(stockId: $stockId, saleId: $saleId, stock: $stock)
}
    `;

export const useUpdateStockMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateStockMutation,
    TError,
    UpdateStockMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateStockMutation,
    TError,
    UpdateStockMutationVariables,
    TContext
  >({
    mutationKey: ['updateStock'],
    mutationFn: useFetchData<UpdateStockMutation, UpdateStockMutationVariables>(
      UpdateStockDocument
    ),
    ...options,
  });
};

export const UpdateWidgetDocument = `
    mutation updateWidget($id: String!, $data: UpdateWidget!) {
  updateWidget(id: $id, data: $data)
}
    `;

export const useUpdateWidgetMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateWidgetMutation,
    TError,
    UpdateWidgetMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateWidgetMutation,
    TError,
    UpdateWidgetMutationVariables,
    TContext
  >({
    mutationKey: ['updateWidget'],
    mutationFn: useFetchData<
      UpdateWidgetMutation,
      UpdateWidgetMutationVariables
    >(UpdateWidgetDocument),
    ...options,
  });
};

export const UpdateSellerDocument = `
    mutation updateSeller($updateSeller: UpdateSeller!) {
  updateSeller(updateSeller: $updateSeller)
}
    `;

export const useUpdateSellerMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateSellerMutation,
    TError,
    UpdateSellerMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateSellerMutation,
    TError,
    UpdateSellerMutationVariables,
    TContext
  >({
    mutationKey: ['updateSeller'],
    mutationFn: useFetchData<
      UpdateSellerMutation,
      UpdateSellerMutationVariables
    >(UpdateSellerDocument),
    ...options,
  });
};

export const SetInfoAsUpToDateDocument = `
    mutation setInfoAsUpToDate {
  setInfoAsUpToDate
}
    `;

export const useSetInfoAsUpToDateMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SetInfoAsUpToDateMutation,
    TError,
    SetInfoAsUpToDateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SetInfoAsUpToDateMutation,
    TError,
    SetInfoAsUpToDateMutationVariables,
    TContext
  >({
    mutationKey: ['setInfoAsUpToDate'],
    mutationFn: useFetchData<
      SetInfoAsUpToDateMutation,
      SetInfoAsUpToDateMutationVariables
    >(SetInfoAsUpToDateDocument),
    ...options,
  });
};

export const TranslateClaimDocument = `
    mutation translateClaim($claimId: ID!, $to: String!) {
  translateClaim(claimId: $claimId, to: $to) {
    originalLanguage
    translation
  }
}
    `;

export const useTranslateClaimMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    TranslateClaimMutation,
    TError,
    TranslateClaimMutationVariables,
    TContext
  >
) => {
  return useMutation<
    TranslateClaimMutation,
    TError,
    TranslateClaimMutationVariables,
    TContext
  >({
    mutationKey: ['translateClaim'],
    mutationFn: useFetchData<
      TranslateClaimMutation,
      TranslateClaimMutationVariables
    >(TranslateClaimDocument),
    ...options,
  });
};

export const ResyncShopifyOrdersDocument = `
    mutation resyncShopifyOrders($saleId: String!) {
  resyncShopifyOrders(saleId: $saleId)
}
    `;

export const useResyncShopifyOrdersMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ResyncShopifyOrdersMutation,
    TError,
    ResyncShopifyOrdersMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ResyncShopifyOrdersMutation,
    TError,
    ResyncShopifyOrdersMutationVariables,
    TContext
  >({
    mutationKey: ['resyncShopifyOrders'],
    mutationFn: useFetchData<
      ResyncShopifyOrdersMutation,
      ResyncShopifyOrdersMutationVariables
    >(ResyncShopifyOrdersDocument),
    ...options,
  });
};

export const SetupShopifyCredentialsDocument = `
    mutation setupShopifyCredentials($brandId: String!, $data: ShopifyCredentialsInput!) {
  setupShopifyCredentials(brandId: $brandId, data: $data) {
    token
    shop
    isStockLive
    sharedSecret
    carrierCode
    internationalCarrierCode
    isProductPriceKept
    mondialRelayCarrierCode
  }
}
    `;

export const useSetupShopifyCredentialsMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SetupShopifyCredentialsMutation,
    TError,
    SetupShopifyCredentialsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SetupShopifyCredentialsMutation,
    TError,
    SetupShopifyCredentialsMutationVariables,
    TContext
  >({
    mutationKey: ['setupShopifyCredentials'],
    mutationFn: useFetchData<
      SetupShopifyCredentialsMutation,
      SetupShopifyCredentialsMutationVariables
    >(SetupShopifyCredentialsDocument),
    ...options,
  });
};

export const UpdateShopifyInfoDocument = `
    mutation updateShopifyInfo($data: UpdateShopifyInfoInput!) {
  updateShopifyInfo(data: $data) {
    isStockLive
    isProductPriceKept
    carrierCode
    internationalCarrierCode
    mondialRelayCarrierCode
  }
}
    `;

export const useUpdateShopifyInfoMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateShopifyInfoMutation,
    TError,
    UpdateShopifyInfoMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateShopifyInfoMutation,
    TError,
    UpdateShopifyInfoMutationVariables,
    TContext
  >({
    mutationKey: ['updateShopifyInfo'],
    mutationFn: useFetchData<
      UpdateShopifyInfoMutation,
      UpdateShopifyInfoMutationVariables
    >(UpdateShopifyInfoDocument),
    ...options,
  });
};

export const InvoiceCreatedDocument = `
    subscription invoiceCreated {
  invoiceCreated {
    id
  }
}
    `;
export const InvoiceUpdatedDocument = `
    subscription invoiceUpdated {
  invoiceUpdated {
    id
  }
}
    `;
export const OrderCreatedDocument = `
    subscription orderCreated {
  orderCreated {
    id
  }
}
    `;
export const OrderUpdatedDocument = `
    subscription orderUpdated {
  orderUpdated {
    id
  }
}
    `;
export const WidgetUpdatedDocument = `
    subscription widgetUpdated {
  widgetUpdated {
    ...WidgetFields
  }
}
    ${WidgetFieldsFragmentDoc}`;
export const ProductStockUpdatedDocument = `
    subscription productStockUpdated {
  productStockUpdated {
    productId
    productVariantId
    stock
  }
}
    `;
