/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { User } from '@auth0/auth0-react';
import auth0 from 'auth0-js';

const auth0Client = new auth0.WebAuth({
  domain: import.meta.env.REACT_APP_AUTH0_DOMAIN as string,
  clientID: import.meta.env.REACT_APP_AUTH0_CLIENT_ID as string,
  audience: import.meta.env.REACT_APP_AUTH0_AUDIENCE as string,
  redirectUri: `${window.location.origin}/login-successful`,
  scope: 'openid profile email offline_access',
  responseType: 'token id_token',
});

export type PasswordlessStartResult = {
  Id: string;
  email: string;
  emailVerified: boolean;
};

const passwordlessStart = ({
  email,
  redirectUri,
}: {
  email: string;
  redirectUri?: string;
}): Promise<PasswordlessStartResult> => {
  return new Promise((resolve, reject) => {
    auth0Client.passwordlessStart(
      {
        connection: 'email',
        email: email,
        send: 'code',
        authParams: {
          redirectUri: redirectUri,
        },
      },
      (error, result: PasswordlessStartResult) => {
        // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
        if (error) reject(error);
        else resolve(result);
      }
    );
  });
};

const passwordlessLogin = ({
  email,
  verificationCode,
  redirectUri,
}: {
  email: string;
  verificationCode: string;
  redirectUri?: string;
}) => {
  return new Promise((resolve, reject) => {
    auth0Client.passwordlessLogin(
      {
        connection: 'email',
        email: email,
        verificationCode: verificationCode,
        redirectUri: redirectUri,
      },
      (error, result) => {
        // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
        if (error) reject(error);
        else resolve(result);
      }
    );
  });
};

const login = ({
  email,
  password,
  redirectUri,
}: {
  email: string;
  password: string;
  redirectUri?: string;
}) => {
  return new Promise((resolve, reject) => {
    auth0Client.login(
      {
        realm: 'Username-Password-Authentication',
        email: email,
        password: password,
        redirectUri: redirectUri,
      },
      (error, result) => {
        // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
        if (error) reject(error);
        else resolve(result);
      }
    );
  });
};

const authorizeGoogle = ({ redirectUri }: { redirectUri?: string }) => {
  auth0Client.authorize({
    connection: 'google-oauth2',
    redirectUri: redirectUri,
  });
};

const authorizeMicrosoft = ({ redirectUri }: { redirectUri?: string }) => {
  auth0Client.authorize({
    connection: 'windowslive',
    redirectUri: redirectUri,
  });
};

const parseHash = (
  callback: auth0.Auth0Callback<
    auth0.Auth0DecodedHash | null,
    auth0.Auth0ParseHashError
  >
) => {
  auth0Client.parseHash((error, result) => {
    callback(error, result);
  });
};

const changePassword = ({ email }: { email: string }) => {
  return new Promise((resolve, reject) => {
    auth0Client.changePassword(
      {
        connection: 'Username-Password-Authentication',
        email,
      },
      (error, result) => {
        // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
        if (error) reject(error);
        else resolve(result);
      }
    );
  });
};

const isScopeAuthorized = (user: User | undefined, scope: string) => {
  const isInternalUser = user?.['https://appchoose.io/claims/isInternalUser'];
  const userScopes =
    (user &&
      (user?.['https://appchoose.io/claims/internalScopes'] as string[])) ??
    [];

  if (isInternalUser && !userScopes.includes(scope)) return false;

  return true;
};

export {
  auth0Client,
  passwordlessStart,
  passwordlessLogin,
  login,
  authorizeGoogle,
  authorizeMicrosoft,
  parseHash,
  changePassword,
  isScopeAuthorized,
};
