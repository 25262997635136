import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import Button from '@appchoose/button';
import { Form } from '@appchoose/form';

import { brandState } from '../../../stores/brand';
import { StoreRegion, useUpdateSellerMutation } from '../../../types/generated';
import { useUsedCouriersQuery } from '../../../types/generated-new';
import { track } from '../../../utils/analytics';
import { formatListToParts } from '../../../utils/string';
import { ShippingDelayAdditionalInfoFormFields } from '../../shipping-delay-additional-info-form-fields/shipping-delay-additonal-info-form-fields';
import type { ShippingDelayFormData } from '../../shipping-delay-form-fields/shipping-delay-form-fields';
import {
  ShippingDelayFormFields,
  getDefaultDeliveryDelay,
} from '../../shipping-delay-form-fields/shipping-delay-form-fields';

export type UpdatingInfoConfirmationShippingDelaysProps = {
  goBack: () => void;
};

export const UpdatingInfoConfirmationShippingDelays: React.FC<
  UpdatingInfoConfirmationShippingDelaysProps
> = ({ goBack }: UpdatingInfoConfirmationShippingDelaysProps) => {
  const [brand, setBrand] = useRecoilState(brandState);
  const { i18n, t } = useTranslation();

  const { data } = useUsedCouriersQuery(undefined, {
    select: (data) => data.usedCouriers,
  });

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  useEffect(() => {
    track('EditShippingDelays', {});
  }, []);

  const form = useForm<ShippingDelayFormData>({
    mode: 'onTouched',
    defaultValues: {
      deliveryDelays: getDefaultDeliveryDelay({
        min: brand?.min_delivery_delay,
        max: brand?.max_delivery_delay,
      }),
      deliveryDelaysMin: brand?.min_delivery_delay ?? undefined,
      deliveryDelaysMax: brand?.max_delivery_delay ?? undefined,
      haveDifferentDeliveryTimeProducts:
        brand?.has_mixed_delivery_delay ?? false,
      havePreOrderProducts: brand?.has_pre_order_delivery ?? false,
    },
  });

  const onSubmit = (data: ShippingDelayFormData) => {
    if (!brand) return;
    setBrand({
      ...brand,
      min_delivery_delay: data.deliveryDelaysMin,
      max_delivery_delay: data.deliveryDelaysMax,
      has_mixed_delivery_delay: data.haveDifferentDeliveryTimeProducts,
      has_pre_order_delivery: data.havePreOrderProducts,
    });
    updateSellerMutation({
      updateSeller: {
        min_delivery_delay: data.deliveryDelaysMin,
        max_delivery_delay: data.deliveryDelaysMax,
        has_mixed_delivery_delay: data.haveDifferentDeliveryTimeProducts,
        has_pre_order_delivery: data.havePreOrderProducts,
      },
    });
    goBack();
  };

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-4">
        <h2 className="text-2xl font-bold sm:text-3.5xl">
          {t('updating_info.confirmation_step.shipping_delays.title')}
        </h2>
        <p className="text-sm text-gray-700 sm:text-base">
          <Trans
            t={t}
            i18nKey="onboarding.shipping.sub_steps.3.subtitle"
            tOptions={{
              interpolation: { escapeValue: false },
            }}
            values={{
              country:
                brand?.store === StoreRegion.Fr
                  ? t('onboarding.shipping.sub_steps.3.france')
                  : t('onboarding.shipping.sub_steps.3.united_states'),
              carriers: formatListToParts(
                data?.map((courier) => courier.name) ?? [],
                i18n.language
              )
                .map((part) => {
                  if (part.type === 'element') {
                    part.value = `<b>${part.value}</b>`;
                  }
                  return part;
                })
                .map((part) => part.value)
                .join(''),
            }}
            components={{ b: <b className="text-gray-900" /> }}
          />
        </p>
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-10"
        >
          <section className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <p className="text-xl font-bold leading-[1.875rem] text-gray-900">
                {t('onboarding.shipping.sub_steps.3.preparation_delivery_time')}
              </p>
              <p className="text-sm leading-5.5 text-gray-700">
                {t('onboarding.shipping.sub_steps.3.order_processing_time')}
              </p>
            </div>

            <ShippingDelayFormFields />
          </section>
          <section className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <p className="text-xl font-bold leading-[1.875rem] text-gray-900">
                {t('onboarding.shipping.sub_steps.3.additional_information')}
              </p>
            </div>
            <ShippingDelayAdditionalInfoFormFields />
          </section>
          <div className="flex justify-end gap-6">
            <button
              type="button"
              className="cursor-pointer text-sm font-bold text-green-900"
              onClick={goBack}
            >
              {t('updating_info.confirmation_step.return')}
            </button>
            <Button type="submit" size="large">
              {t('updating_info.confirmation_step.confirm')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

UpdatingInfoConfirmationShippingDelays.displayName =
  'UpdatingInfoConfirmationShippingDelays';
