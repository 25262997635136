import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';

import Button from '@appchoose/button';

import type { BrandMatch } from '../../types/navigation';
import { UpdatingInfoConfirmationContact } from './updating-info-confirmation/updating-info-confirmation-contact';
import { UpdatingInfoConfirmationReturnAddress } from './updating-info-confirmation/updating-info-confirmation-return-address';
import { UpdatingInfoConfirmationShippingDelays } from './updating-info-confirmation/updating-info-confirmation-shipping-shipping-delays';
import { UpdatingInfoStepConfirmationSummary } from './updating-info-confirmation/updating-info-step-confirmation-summary';

export type UpdatingNextStepsProps = {
  goToNextStep: () => void;
  loading?: boolean;
};

export const UpdatingInfoStepConfirmation = ({
  goToNextStep,
  loading,
}: UpdatingNextStepsProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { brandId = '' } = useParams<BrandMatch>();

  const goBack = () => {
    navigate({
      pathname: `/${brandId}/updating-info/1`,
    });
  };

  return (
    <section className="w-full">
      <div className="mt-8 flex flex-col space-y-6 sm:mt-20">
        <Routes>
          <Route
            index
            element={
              <>
                <h2 className="mb-4 text-2xl font-bold sm:text-3.5xl">
                  {t('updating_info.confirmation.title')}
                </h2>
                <UpdatingInfoStepConfirmationSummary />
                <div className="pt-4">
                  <Button
                    type="button"
                    size="large"
                    onClick={goToNextStep}
                    disabled={loading}
                  >
                    {t('updating_info.confirmation.submit_button')}
                  </Button>
                </div>
              </>
            }
          />
          <Route
            path="contact"
            element={<UpdatingInfoConfirmationContact goBack={goBack} />}
          />
          <Route
            path="return_address"
            element={<UpdatingInfoConfirmationReturnAddress goBack={goBack} />}
          />
          <Route
            path="shipping_delays"
            element={<UpdatingInfoConfirmationShippingDelays goBack={goBack} />}
          />
          <Route
            path="*"
            element={<Navigate to={`/${brandId}/updating-info/:step?`} />}
          />
        </Routes>
      </div>
    </section>
  );
};
