import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import type { ColumnDef } from '@tanstack/react-table';

import { brandState } from '../../stores/brand';
import { shortActiveSaleIdState } from '../../stores/sales';
import { BillingStateErrorCode } from '../../types/generated-new.js';
import type { BillingErrorData } from './billing-error-table';
import { BillingErrorTable } from './billing-error-table';
import { useBilling } from './use-billing';
import { useBillingState } from './use-billing-state';

export const BillingErrorList: React.FC = () => {
  const brand = useRecoilValue(brandState);
  const { t } = useTranslation();

  const { data: billingState } = useBillingState();
  const { data: billing } = useBilling();

  const shortActiveSaleId = useRecoilValue(shortActiveSaleIdState);

  const columns: ColumnDef<BillingErrorData>[] = [
    {
      accessorKey: 'errors',
      header: () => <>{t('billing.errors.errors')}</>,
      cell: ({ row }) => {
        return (
          <button
            type="button"
            {...{
              onClick: row.getToggleExpandedHandler(),
              style: { cursor: 'pointer' },
            }}
            className="flex items-center space-x-2"
          >
            <Icon
              icon="dropdown"
              size="large"
              className={cn('shrink-0 text-gray-900', {
                '-rotate-90': !row.getIsExpanded(),
              })}
            />

            <span
              className={cn('text-sm font-semibold lowercase text-red-600', {
                'text-orange-600': row.original.type === 'warning',
              })}
            >
              {row.original.code}
            </span>
            <span
              className={cn(
                'rounded bg-red-350 px-1 py-0.5 text-xs font-semibold text-red-600',
                {
                  'bg-orange-300 text-orange-600':
                    row.original.type === 'warning',
                }
              )}
            >
              {row.original.errors.length}
            </span>
          </button>
        );
      },
    },
    {
      accessorKey: 'details',
      header: () => <>{t('billing.errors.details')}</>,
      cell: ({ getValue }) => (
        <div>
          <div className="flex text-xs font-semibold uppercase tracking-wider text-gray-700 sm:hidden">
            {t('billing.errors.details')}
          </div>
          <div>{getValue<string>()}</div>
        </div>
      ),
    },
    {
      accessorKey: 'actions',
      header: () => <>{t('billing.errors.actions')}</>,
      cell: ({ row, getValue }) => (
        <div>
          <div className="flex text-xs font-semibold uppercase tracking-wider text-gray-700 sm:hidden">
            {t('billing.errors.actions')}
          </div>
          <NavLink
            to={`${brand?.id ? '/' + brand.id : ''}/orders${
              row.original.actionQueryParams
            }`}
            target="_blank"
            className="flex items-center space-x-1 text-xs font-semibold text-green-900"
            rel="noreferrer"
          >
            <Icon icon="externalLinkDefault" className="shrink-0" />
            <span>{getValue<string>()}</span>
          </NavLink>
        </div>
      ),
    },
    {
      accessorKey: 'whoCanResolve',
      header: () => <>{t('billing.errors.who_can_resolve')}</>,
      cell: ({ getValue }) => (
        <div>
          <div className="flex text-xs font-semibold uppercase tracking-wider text-gray-700 sm:hidden">
            {t('billing.errors.who_can_resolve')}
          </div>
          <div>{getValue<string>()}</div>
        </div>
      ),
    },
  ];

  const data: BillingErrorData[] = useMemo(() => {
    const data: BillingErrorData[] = [];

    if (
      (billing?.errors.filter((error) => error.code === 'no_variant_id')
        .length ?? 0) > 0
    )
      data.push({
        actionQueryParams: '',
        actions: t('billing.errors.no_variant_id.actions'),
        code: 'no_variant_id',
        details: t('billing.errors.no_variant_id.details'),
        errors:
          billing?.errors.filter((error) => error.code === 'no_variant_id') ??
          [],
        type: 'error',
        whoCanResolve: t('billing.errors.no_variant_id.who_can_resolve'),
      });
    if (
      (billing?.errors.filter(
        (error) => error.code === 'quantity_not_consistent'
      ).length ?? 0) > 0
    )
      data.push({
        actionQueryParams: '',
        actions: t('billing.errors.quantity_not_consistent.actions'),
        code: 'quantity_not_consistent',
        details: t('billing.errors.quantity_not_consistent.details'),
        errors:
          billing?.errors.filter(
            (error) => error.code === 'quantity_not_consistent'
          ) ?? [],
        type: 'error',
        whoCanResolve: t(
          'billing.errors.quantity_not_consistent.who_can_resolve'
        ),
      });
    if (
      (billing?.errors.filter(
        (error) => error.code === 'buying_price_not_correct'
      ).length ?? 0) > 0
    )
      data.push({
        actionQueryParams: '',
        actions: t('billing.errors.buying_price_not_correct.actions'),
        code: 'buying_price_not_correct',
        details: t('billing.errors.buying_price_not_correct.details'),
        errors:
          billing?.errors.filter(
            (error) => error.code === 'buying_price_not_correct'
          ) ?? [],
        type: 'error',
        whoCanResolve: t(
          'billing.errors.buying_price_not_correct.who_can_resolve'
        ),
      });
    if (
      (billing?.errors.filter(
        (error) => error.code === 'missing_buying_price_info'
      ).length ?? 0) > 0
    )
      data.push({
        actionQueryParams: '',
        actions: t('billing.errors.missing_buying_price_info.actions'),
        code: 'missing_buying_price_info',
        details: t('billing.errors.missing_buying_price_info.details'),
        errors:
          billing?.errors.filter(
            (error) => error.code === 'missing_buying_price_info'
          ) ?? [],
        type: 'error',
        whoCanResolve: t(
          'billing.errors.missing_buying_price_info.who_can_resolve'
        ),
      });
    if (
      (billingState?.errors.filter(
        (error) => error.code === BillingStateErrorCode.ClaimUnfulfilled
      ).length ?? 0) > 0
    )
      data.push({
        actionQueryParams: `?filter=filters&offset=0&claimFilter=PENDING&sort=CREATED_DESC&sid=${shortActiveSaleId}`,
        actions: t('billing.errors.claim_unfulfilled.actions'),
        code: 'claim_unfulfilled',
        details: t('billing.errors.claim_unfulfilled.details'),
        errors:
          billingState?.errors.filter(
            (error) => error.code === BillingStateErrorCode.ClaimUnfulfilled
          ) ?? [],
        type: 'error',
        whoCanResolve: t('billing.errors.claim_unfulfilled.who_can_resolve'),
      });

    if (
      (billingState?.errors.filter(
        (error) => error.code === BillingStateErrorCode.OrderNotDelivered
      ).length ?? 0) > 0
    )
      data.push({
        actionQueryParams: `?filter=filters&offset=0&shippingFilter=IN_PROGRESS&sort=CREATED_DESC&sid=${shortActiveSaleId}`,
        actions: t('billing.errors.order_not_delivered.actions'),
        code: 'order_not_delivered',
        details: t('billing.errors.order_not_delivered.details'),
        errors:
          billingState?.errors.filter(
            (error) => error.code === BillingStateErrorCode.OrderNotDelivered
          ) ?? [],
        type: 'warning',
        whoCanResolve: t('billing.errors.order_not_delivered.who_can_resolve'),
      });

    if (
      (billingState?.errors.filter(
        (error) => error.code === BillingStateErrorCode.ReturnNotValidated
      ).length ?? 0) > 0
    )
      data.push({
        actionQueryParams: `?filter=filters&offset=0&returnFilter=PENDING&sort=CREATED_DESC&sid=${shortActiveSaleId}`,
        actions: t('billing.errors.return_not_validated.actions'),
        code: 'return_not_validated',
        details: t('billing.errors.return_not_validated.details'),
        errors:
          billingState?.errors.filter(
            (error) => error.code === BillingStateErrorCode.ReturnNotValidated
          ) ?? [],
        type: 'warning',
        whoCanResolve: t('billing.errors.return_not_validated.who_can_resolve'),
      });

    return data;
  }, [billing, billingState, shortActiveSaleId, t]);

  if (data.length === 0) return null;

  return <BillingErrorTable columns={columns} data={data} />;
};
