import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Tooltip from '@appchoose/tooltip';
import type { TFunction } from 'i18next';
import i18n from 'i18next';

import { brandState } from '../../stores/brand';
import { activeSaleState } from '../../stores/sales';
import type { OrderQuery } from '../../types/generated-new';
import {
  OrderLineRefundCover,
  OrderLineRefundReason,
  OrderLineType,
  OrderTagRefund,
} from '../../types/generated-new';
import { formatPrice } from '../../utils/currency';
import { transformDateSimple } from '../../utils/date';
import { DashedLine } from '../../views/billing-screen/dashed-line';
import { RefundChoose } from '../icons/refund-choose';
import { ModalOrderRefundBadge } from '../order-status-badge/modal-order-refund-badge';

type ModalOrderRefundDetailsProps = {
  items: OrderQuery['order']['items'];
  linesRefunds: OrderQuery['order']['linesRefunds'];
  tags: OrderQuery['order']['tags'];
};

const getReason = (reason: OrderLineRefundReason, t: TFunction) => {
  switch (reason) {
    case OrderLineRefundReason.DirectCommercialGesture:
      return t('order.refund.reasons.commercial_gesture');
    case OrderLineRefundReason.DirectClaimDeadlineExceeded:
      return t('order.refund.reasons.claim_deadline_exceeded');
    case OrderLineRefundReason.DirectClaimRefused:
      return t('order.refund.reasons.claim_refused');
    case OrderLineRefundReason.DirectItemWrongImage:
      return t('order.refund.reasons.item_wrong_image');
    case OrderLineRefundReason.DirectItemWrongPrice:
      return t('order.refund.reasons.item_wrong_price');
    case OrderLineRefundReason.DirectReturnDeadlineExceeded:
      return t('order.refund.reasons.return_deadline_exceeded');
    case OrderLineRefundReason.DirectReturnRefused:
      return t('order.refund.reasons.return_refused');
  }
};

export const ModalOrderRefundDetails: React.FC<
  ModalOrderRefundDetailsProps
> = ({ items, linesRefunds, tags }: ModalOrderRefundDetailsProps) => {
  const { t } = useTranslation();
  const activeSale = useRecoilValue(activeSaleState);
  const brand = useRecoilValue(brandState);

  const getProductName = (
    items: OrderQuery['order']['items'],
    lineId: string
  ) => {
    const item = items.find((item) => item.id === lineId);
    return item?.name;
  };

  if (tags.refund === OrderTagRefund.None) return null;

  return (
    <div className="rounded border border-gray-300">
      <div className="flex items-center justify-between border-b border-gray-300 px-6 py-4">
        <ModalOrderRefundBadge tag={tags.refund} />
      </div>
      <div className="flex flex-col">
        <ul className="my-4 space-y-2 text-sm text-gray-900">
          {linesRefunds.map((lineRefund) => (
            <li
              key={lineRefund.id}
              className="flex items-end space-x-2 px-6 py-2 text-gray-700"
            >
              <div className="flex min-w-0 max-w-fit flex-auto items-center space-x-1">
                <span className="truncate text-sm leading-5.5 text-gray-900">
                  {lineRefund.lineType === OrderLineType.Shipping
                    ? t('order.refund.shipping_costs')
                    : getProductName(items, lineRefund.lineId)}
                </span>
                <span className="shrink-0 items-end text-xs text-[#767A7C]">
                  (
                  {lineRefund.createdAt
                    ? transformDateSimple(
                        new Date(lineRefund.createdAt),
                        i18n.language === 'fr' ? 'fr' : 'en',
                        undefined,
                        brand?.timezone ?? undefined
                      )
                    : ''}
                  )
                </span>
                {lineRefund.coveredBy === OrderLineRefundCover.Choose ? (
                  <Tooltip
                    offset={[0, 16]}
                    content={
                      <div className="px-1.5 py-3 text-xs font-semibold">
                        {getReason(lineRefund.reason, t) ? (
                          <p className="text-center">
                            {t('order.refund.reason')}{' '}
                            {getReason(lineRefund.reason, t)}
                          </p>
                        ) : null}
                        <p className="text-center">
                          ({t('order.refund.payable_by_choose')})
                        </p>
                      </div>
                    }
                  >
                    <div>
                      <RefundChoose className="text-green-900" />
                    </div>
                  </Tooltip>
                ) : null}
              </div>
              <div className="min-w-0 flex-1 items-end pb-1.5">
                <DashedLine />
              </div>
              <div className="shrink-0 text-sm font-semibold text-gray-900">
                {formatPrice(
                  lineRefund.amountInCents / 100,
                  i18n.language === 'fr' ? 'fr' : 'en',
                  activeSale?.currency.toString().toUpperCase() ?? 'EUR'
                )}
              </div>
            </li>
          ))}
        </ul>
        <div className="flex justify-between rounded-b border-t border-beige-400 bg-beige-300 px-6 py-[21px] font-semibold text-gray-900">
          <span>{t('order.refund.total')}</span>
          <span>
            {formatPrice(
              linesRefunds
                .map((refund) => refund.amountInCents)
                .reduce((prev, curr) => prev + curr, 0) / 100,
              i18n.language === 'fr' ? 'fr' : 'en',
              activeSale?.currency.toString().toUpperCase() ?? 'EUR'
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
