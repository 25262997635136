import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import Button from '@appchoose/button';
import type { FlagType } from '@appchoose/flag';
import Flag from '@appchoose/flag';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Label from '@appchoose/label';
import RadioGroup, { RadioGroupItem } from '@appchoose/radio-group';
import i18n from 'i18next';

import countries from '../../../assets/json/countries.json';
import { brandState } from '../../../stores/brand';
import { StoreRegion, useUpdateSellerMutation } from '../../../types/generated';
import { track } from '../../../utils/analytics';
import type { FlagComboboxRef } from '../../flag-combobox/flag-combobox';
import { FlagCombobox } from '../../flag-combobox/flag-combobox';

export type OnboardingStepShippingCountryProps = {
  goToNextSubStepOrStep: () => void;
};

type CountryForm = {
  countryCode: string;
  countrySelectionType: 'suggested' | 'manual';
};

export const OnboardingStepShippingCountry: React.FC<
  OnboardingStepShippingCountryProps
> = ({ goToNextSubStepOrStep }: OnboardingStepShippingCountryProps) => {
  const [brand, setBrand] = useRecoilState(brandState);
  const { t } = useTranslation();

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const suggestedCountries =
    brand?.store === StoreRegion.Fr
      ? [
          countries.find((c) => c.code === 'FR'),
          countries.find((c) => c.code === 'ES'),
          countries.find((c) => c.code === 'DE'),
        ].filter((o) => o !== undefined)
      : [
          countries.find((c) => c.code === 'US'),
          countries.find((c) => c.code === 'CA'),
          countries.find((c) => c.code === 'MX'),
        ].filter((o) => o !== undefined);

  const suggestedFlagOptions = suggestedCountries.map((country) => ({
    flag: country.code as FlagType,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    name: country[i18n.language],
    value: country.code,
  }));

  const customFlagOptions = [
    {
      name: (
        <div className="sr-only">
          {t('onboarding.shipping.manual_selection')}
        </div>
      ),
      value: 'manual',
    },
  ];

  const defaultCountryCode = brand?.shipping_country
    ? brand?.shipping_country
    : brand?.store === StoreRegion.Fr
      ? 'FR'
      : 'US';
  const form = useForm<CountryForm>({
    mode: 'onTouched',
    defaultValues: {
      countryCode: defaultCountryCode,
      countrySelectionType: suggestedCountries.find(
        (c) => c.code === defaultCountryCode
      )
        ? 'suggested'
        : 'manual',
    },
  });

  const onSubmit = (data: CountryForm) => {
    if (!brand) return;
    setBrand({
      ...brand,
      shipping_country: data.countryCode,
    });
    updateSellerMutation({
      updateSeller: { shipping_country: data.countryCode },
    });
    goToNextSubStepOrStep();
  };

  const flagComboboxRef = useRef<FlagComboboxRef | null>(null);

  return (
    <>
      <h2 className="mb-6 mt-8 text-2xl font-bold sm:mb-10 sm:mt-20 sm:text-3.5xl">
        {t('onboarding.shipping.sub_steps.1.title')}
      </h2>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-10"
        >
          <FormField
            control={form.control}
            name="countryCode"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <FormItem>
                <div className="flex flex-col gap-7">
                  <FormLabel className="sr-only">
                    {t('onboarding.shipping.country.label')}
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      value={
                        form.getValues('countrySelectionType') === 'suggested'
                          ? value
                          : ''
                      }
                      onValueChange={(value) => {
                        form.setValue('countrySelectionType', 'suggested');
                        onChange(value);
                        flagComboboxRef.current?.clearInput();
                      }}
                      className="space-y-7 sm:flex sm:space-x-6 sm:space-y-0"
                    >
                      {suggestedFlagOptions.map((option) => (
                        <div
                          className="group flex items-center space-x-3"
                          key={option.value}
                        >
                          <RadioGroupItem
                            key={option.value}
                            value={option.value}
                            id={`language-option-${option.value}`}
                          />

                          <Label
                            htmlFor={`language-option-${option.value}`}
                            className="flex cursor-pointer items-center space-x-1 text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                          >
                            <Flag flag={option.flag} />
                            <span>{option.name}</span>
                          </Label>
                        </div>
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <div className="flex items-center space-x-2">
                    <RadioGroup
                      value={form.getValues('countrySelectionType')}
                      onValueChange={(value) => {
                        form.setValue(
                          'countrySelectionType',
                          value as 'manual'
                        );
                        onChange('');
                      }}
                      className="flex space-x-11"
                    >
                      {customFlagOptions.map((option) => (
                        <RadioGroupItem
                          key={option.value}
                          value={option.value}
                          id={`country-selection-${option.value}`}
                        />
                      ))}
                    </RadioGroup>
                    <div className="flex w-full max-w-sm items-center font-normal text-gray-500">
                      <FlagCombobox
                        ref={flagComboboxRef}
                        defaultValue={
                          form.getValues('countrySelectionType') === 'manual'
                            ? value
                            : ''
                        }
                        onSearch={(value) => {
                          if (value !== '') {
                            track('SearchShippingCountry', {
                              search_term: value,
                            });
                          }
                        }}
                        onSelect={(value) => {
                          form.setValue('countrySelectionType', 'manual');
                          onChange(value);
                          track('SelectShippingCountry', {
                            selected_country: value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <FormMessage match="required">
                  {t('onboarding.shipping.country.validation_errors.required')}
                </FormMessage>
              </FormItem>
            )}
          />

          <Button type="submit" size="large" className="self-start">
            {t('continue')}
          </Button>
        </form>
      </Form>
    </>
  );
};

OnboardingStepShippingCountry.displayName = 'OnboardingStepShippingCountry';
