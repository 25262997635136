import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import type { TFunction } from 'i18next';

import { OrderTagFulfillment } from '../../types/generated-new';
import { OrderFulfilled } from '../icons/order-fulfilled';
import { OrderUnfulfilled } from '../icons/order-unfulfilled';
import { OrderBadge } from './order-badge';

export const getOrderFulfillmentLabelFromTag = (
  tag: OrderTagFulfillment,
  t: TFunction
) => {
  switch (tag) {
    case OrderTagFulfillment.Fulfilled:
      return t('order.status.fulfillment.fulfilled');
    case OrderTagFulfillment.Unfulfilled:
      return t('order.status.fulfillment.unfulfilled');
  }
};

export const variant = ({
  isCancelled,
  tag,
}: {
  isCancelled?: boolean;
  tag: OrderTagFulfillment;
}) => {
  if (isCancelled) return 'filled';
  return tag === OrderTagFulfillment.Unfulfilled ? 'warning' : 'filled';
};

export const OrderFulfillmentBadge: React.FC<{
  isCancelled?: boolean;
  tag: OrderTagFulfillment;
}> = ({ isCancelled, tag }) => {
  const { t } = useTranslation();

  return (
    <OrderBadge style={variant({ isCancelled, tag })}>
      <div className="flex items-center gap-x-1">
        {tag === OrderTagFulfillment.Unfulfilled ? (
          <OrderUnfulfilled />
        ) : (
          <OrderFulfilled />
        )}
        <span className={cn({ 'line-through': isCancelled })}>
          {getOrderFulfillmentLabelFromTag(tag, t)}
        </span>
      </div>
    </OrderBadge>
  );
};
