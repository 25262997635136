/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSessionStorage } from 'react-use';

import { useAuth0 } from '@auth0/auth0-react';

import { AnimatedCheck } from '../../components/animated-check/animated-check';
import type { BrandMatch } from '../../types/navigation';

export const Welcome: React.FC = () => {
  const navigate = useNavigate();
  const { brandId = '' } = useParams<BrandMatch>();
  const [redirectUri] = useSessionStorage<string>('redirectUri');
  const { t } = useTranslation();

  const { user } = useAuth0();
  const displayedName =
    user?.['https://appchoose.io/claims/firstname'] ?? user?.given_name ?? '';

  useEffect(() => {
    const timer1 = setTimeout(() => {
      if (redirectUri) {
        navigate(redirectUri, { replace: true });
      } else {
        navigate(`/${brandId}/home`, { replace: true });
      }
    }, 3000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <AnimatedCheck className="mb-6 size-32" />
      <div className="text-center text-2xl font-bold text-gray-900">
        {t('auth.signup.welcome', { name: displayedName })}
      </div>
    </div>
  );
};
