import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import { useVirtualizer } from '@tanstack/react-virtual';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../components/dropdown-menu';
import {
  ProductSort,
  productSortState,
  sortedVariantsState,
} from '../../stores/product';
import { StockProductListItem } from './stock-product-list-item';

type StockProductListProps = {
  pageRef: React.RefObject<HTMLDivElement>;
};

export const StockProductList: React.FC<StockProductListProps> = ({
  pageRef,
}) => {
  const { t } = useTranslation();
  const listRef = useRef<HTMLDivElement | null>(null);

  const [productSort, setProductSort] = useRecoilState(productSortState);
  const sortedVariants = useRecoilValue(sortedVariantsState);

  const isWide = useMedia('(min-width: 961px)');
  const virtualizer = useVirtualizer({
    count: sortedVariants?.length ?? 0,
    estimateSize: () => (isWide ? 88 : 197),
    overscan: 5,
    scrollMargin: listRef.current?.offsetTop ?? 0,
    getScrollElement: () => pageRef.current,
  });

  const sortName = () => {
    setProductSort((prev) =>
      prev === ProductSort.name_asc
        ? ProductSort.name_desc
        : ProductSort.name_asc
    );
  };

  const sortSku = () => {
    setProductSort((prev) =>
      prev === ProductSort.sku_asc ? ProductSort.sku_desc : ProductSort.sku_asc
    );
  };

  const sortStock = () => {
    setProductSort((prev) =>
      prev === ProductSort.stock_asc
        ? ProductSort.stock_desc
        : ProductSort.stock_asc
    );
  };

  const sortOptions = [
    { key: ProductSort.name_asc, value: t('stock.sort.name_asc') },
    {
      key: ProductSort.name_desc,
      value: t('stock.sort.name_desc'),
    },
    {
      key: ProductSort.sku_asc,
      value: t('stock.sort.sku_asc'),
    },
    {
      key: ProductSort.sku_desc,
      value: t('stock.sort.sku_desc'),
    },
    {
      key: ProductSort.stock_asc,
      value: t('stock.sort.stock_asc'),
    },
    {
      key: ProductSort.stock_desc,
      value: t('stock.sort.stock_desc'),
    },
  ];

  return (
    <>
      <div className="stock-product-list-header sticky top-0 z-[1] hidden border-b border-gray-300 bg-white py-4 min-[960px]:grid">
        <div
          className="flex items-center space-x-0.5 text-xs font-semibold uppercase tracking-wider text-gray-700"
          onClick={sortName}
        >
          <span>{t('stock.headers.name')}</span>
          <Icon
            icon="arrowDown"
            className={cn(`inline-block shrink-0 text-gray-300`, {
              'rotate-180': productSort === ProductSort.name_asc,
              'text-gray-900':
                productSort === ProductSort.name_asc ||
                productSort === ProductSort.name_desc,
            })}
          />
        </div>
        <div
          className="flex items-center space-x-0.5 text-xs font-semibold uppercase tracking-wider text-gray-700"
          onClick={sortSku}
        >
          <span>{t('stock.headers.sku')}</span>
          <Icon
            icon="arrowDown"
            className={cn(`inline-block shrink-0 text-gray-300`, {
              'rotate-180': productSort === ProductSort.sku_asc,
              'text-gray-900':
                productSort === ProductSort.sku_asc ||
                productSort === ProductSort.sku_desc,
            })}
          />
        </div>
        <div
          className="flex items-center justify-end space-x-0.5 text-xs font-semibold uppercase tracking-wider text-gray-700"
          onClick={sortStock}
        >
          <span>{t('stock.headers.stock')}</span>
          <Icon
            icon="arrowDown"
            className={cn(`inline-block shrink-0 text-gray-300`, {
              'rotate-180': productSort === ProductSort.stock_asc,
              'text-gray-900':
                productSort === ProductSort.stock_asc ||
                productSort === ProductSort.stock_desc,
            })}
          />
        </div>
        <div className="text-right text-xs font-semibold uppercase tracking-wider text-gray-700">
          {t('stock.headers.change_stock')}
        </div>
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger className="group flex shrink-0 items-center min-[960px]:hidden">
          <div className="flex items-center space-x-2">
            <span className="text-sm font-semibold text-green-900">
              {t('stock.sort.label', {
                sort: sortOptions.find((c) => c.key === productSort)?.value,
              })}
            </span>
            <Icon
              icon="arrowDown"
              className="shrink-0 transition-all group-data-[state=open]:rotate-180"
            />
          </div>
        </DropdownMenuTrigger>

        <DropdownMenuContent
          align="start"
          sideOffset={8}
          className="min-w-40 overflow-y-auto"
          style={{
            maxHeight: 'var(--radix-dropdown-menu-content-available-height)',
          }}
        >
          {sortOptions.map((sortOption) => (
            <DropdownMenuItem onSelect={() => setProductSort(sortOption.key)}>
              {sortOption.value}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
      <div ref={listRef}>
        {sortedVariants?.length === 0 ? (
          <p className="my-6 text-sm text-gray-500">{t('stock.no_search')}</p>
        ) : (
          <div
            style={{
              height: `${virtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {virtualizer.getVirtualItems().map((virtualItem) => {
              const listItem = sortedVariants?.[virtualItem.index];
              if (!listItem) return null;
              return (
                <div
                  key={virtualItem.key}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: `${virtualItem.size}px`,
                    transform: `translateY(${
                      virtualItem.start - virtualizer.options.scrollMargin
                    }px)`,
                  }}
                >
                  <StockProductListItem
                    title={listItem.productName}
                    productImage={listItem.productImage}
                    productType={listItem.productType}
                    variant={listItem}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};
