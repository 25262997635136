import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import Icon from '@appchoose/icon';
import { type VariantProps, cva } from 'cva';

import type { BrandMatch } from '../../types/navigation';

export const orderDetailsContainerVariants = cva({
  base: 'flex items-center',
  variants: {
    size: {
      default: 'space-x-2',
      large: 'space-x-3',
    },
  },
});

export const orderDetailsImageVariants = cva({
  base: 'shrink-0 rounded-sm',
  variants: {
    size: {
      default: 'size-8',
      large: 'size-10',
    },
    style: {
      default: '',
      error: 'border border-red-600',
      success: 'border border-green-600',
    },
  },
});

export const orderDetailsCourierVariants = cva({
  base: 'text-xs font-semibold uppercase tracking-wider',
  variants: {
    style: {
      default: 'text-gray-500',
      error: 'text-red-600',
      success: 'text-green-600',
    },
  },
});

export const orderDetailsOrderIdTextVariants = cva({
  base: 'text-sm',
  variants: {
    size: {
      default: 'font-bold',
      large: '',
    },
    style: {
      default: 'text-gray-900',
      error: 'font-bold text-red-600',
      'line-through': 'text-gray-500 line-through',
      success: 'font-bold text-green-600',
    },
  },
});

export const orderDetailsTrackingTextVariants = cva({
  base: 'flex items-center space-x-1 text-sm',
  variants: {
    size: {
      default: 'font-semibold text-gray-700',
      large: 'text-gray-900',
    },
  },
});

export type OrderDetailsProps = {
  order: {
    id?: string;
    parcels?: {
      trackingCourier: {
        logoUrl: string;
        name: string;
      };
      trackingNumber: string;
    }[];
    recipient?: {
      fullName: string;
    };
  };
  showTrackingLink?: boolean;
  alignment?: 'horizontal' | 'vertical';
} & VariantProps<typeof orderDetailsContainerVariants> & {
    courierStyle?: VariantProps<typeof orderDetailsCourierVariants>['style'];
    orderIdStyle?: VariantProps<
      typeof orderDetailsOrderIdTextVariants
    >['style'];
  };

export const OrderDetails: React.FC<OrderDetailsProps> = ({
  order,
  size = 'default',
  alignment = 'horizontal',
  showTrackingLink,
  orderIdStyle = 'default',
  courierStyle = 'default',
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { brandId = '' } = useParams<BrandMatch>();

  const parcel = order.parcels?.[0];
  return (
    <div className="flex space-x-6 rounded bg-[#FBFBFB] px-4 py-6">
      <div className={orderDetailsContainerVariants({ size })}>
        <div className="flex h-full items-start">
          <img
            src={parcel?.trackingCourier.logoUrl}
            alt=""
            className={orderDetailsImageVariants({ size, style: courierStyle })}
          />
        </div>
        <div className="space-y-1">
          <div className={orderDetailsCourierVariants({ style: courierStyle })}>
            {parcel?.trackingCourier.name}
          </div>
          <div
            className={orderDetailsOrderIdTextVariants({
              size,
              style: orderIdStyle,
            })}
          >
            {parcel?.trackingNumber}
          </div>
          {alignment === 'vertical' && order.id ? (
            <a
              href={`/${brandId}/orders/${order.id}${search}`}
              target="_blank"
              rel="noreferrer"
              className="flex items-center space-x-1 text-xs font-semibold text-gray-500"
            >
              <span>{order.id}</span>
              <Icon icon="externalLinkSimple" className="size-3.5" />
            </a>
          ) : null}
        </div>
      </div>
      {alignment === 'horizontal' && order.id ? (
        <div className="space-y-1">
          <div className="text-xs font-semibold uppercase tracking-wider text-gray-500">
            {t('order.order')}
          </div>
          {showTrackingLink ? (
            <a
              href={`/${brandId}/orders/${order.id}${search}`}
              target="_blank"
              rel="noreferrer"
              className="inline-flex items-center space-x-1 text-sm font-semibold text-green-900"
            >
              <span>{order.id}</span>
              <Icon icon="externalLinkSimple" className="size-3.5" />
            </a>
          ) : (
            <p className={orderDetailsTrackingTextVariants({ size })}>
              <span>{order.id}</span>
            </p>
          )}
        </div>
      ) : null}
      {order.recipient ? (
        <div className="space-y-1">
          <div className="text-xs font-semibold uppercase tracking-wider text-gray-500">
            {t('order.customer')}
          </div>

          <p className="flex items-center space-x-1 text-sm text-gray-900">
            <span>{order.recipient.fullName}</span>
          </p>
        </div>
      ) : null}
    </div>
  );
};
