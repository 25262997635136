import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import Alert from '@appchoose/alert';
import Button from '@appchoose/button';
import { Form } from '@appchoose/form';
import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import i18n from 'i18next';

import { brandState } from '../../stores/brand';
import { salesState } from '../../stores/sales';
import {
  StoreRegion,
  useSetupShopifyCredentialsMutation,
  useUpdateShopifyInfoMutation,
} from '../../types/generated';
import { hasSaleEnded, hasSaleStarted } from '../../utils/sales';
import { formatShopifyURL } from '../../utils/string';
import { ShopifyDetails } from './shopify-details';
import { ShopifyForm } from './shopify-form';

export type ShopifyHeaderModalProps = {
  showRetroactiveAlert: boolean;
};

export const SHOPIFY_INTEGRATION_FAQ =
  i18n.language === 'fr'
    ? 'https://choose.notion.site/Proc-dure-Shopify-FR-262b64dabe504d2e923e09241fe5e595'
    : 'https://choose.notion.site/Shopify-integration-ENG-780c448507f143ceabdbe293d8bf138c';

export const ShopifyHeaderModal: React.FC<ShopifyHeaderModalProps> = ({
  showRetroactiveAlert,
}: ShopifyHeaderModalProps) => {
  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      <h3 id="modal-shopify-integration-title" className="text-2xl font-bold">
        {t('settings.integration_tabs.shopify.shopify_integration')}
      </h3>
      <div className="flex items-center whitespace-pre text-sm leading-5.5">
        {t('settings.integration_tabs.shopify.find_shopify_procedure_on')}{' '}
        <a
          href={SHOPIFY_INTEGRATION_FAQ}
          target="_blank"
          className="font-semibold text-green-900"
          rel="noreferrer"
        >
          {t('settings.integration_tabs.shopify.this_document')}
        </a>
        <Icon icon="externalLinkDefault" className="ml-0.5 text-green-900" />
      </div>
      {showRetroactiveAlert ? (
        <Alert
          size="small"
          appearance="warning"
          title={t('settings.integration_tabs.shopify.changes_not_retroactive')}
          icon="information"
        >
          <p className="text-sm text-gray-700">
            {t('settings.integration_tabs.shopify.changes_new_orders')}
          </p>
        </Alert>
      ) : null}
    </div>
  );
};

export type ShopifyIntegrationForm = {
  shopify_url: string;
  token: string;
  shared_secret: string;
  carrier_code: string;
  international_carrier_code: string;
  mondial_relay_carrier_code: string;
  stock_live: boolean;
  is_product_price_kept: string;
  gcu: boolean;
};

type ShopifyModalProps = {
  setIsOpen: (isOpen: boolean) => void;
};

export const ShopifyModal: React.FC<ShopifyModalProps> = ({
  setIsOpen,
}: ShopifyModalProps) => {
  const { t } = useTranslation();
  const [brand, setBrand] = useRecoilState(brandState);
  const sales = useRecoilValue(salesState);
  const isShopifySetup = !!brand?.shopify?.shop;
  const hasRunningSale =
    (Array.isArray(sales) ? sales : []).filter(
      (s) =>
        hasSaleStarted(s.start_at ?? undefined) &&
        !hasSaleEnded(s.end_at ?? undefined)
    ).length !== 0;

  const [isEdit, setIsEdit] = useState(!isShopifySetup);

  const { mutateAsync: updateShopifyInfo, isPending: isUpdateShopifyPending } =
    useUpdateShopifyInfoMutation();

  const {
    mutateAsync: setupShopifyCredentials,
    isPending: isSetupShopifyPending,
  } = useSetupShopifyCredentialsMutation();

  const form = useForm<ShopifyIntegrationForm>({
    mode: 'onTouched',
    defaultValues: {
      shopify_url: brand?.shopify?.shop ?? '',
      token: isShopifySetup
        ? (brand?.shopify?.token ?? '').concat('••••••••••••••••••••••••••')
        : '',
      shared_secret: isShopifySetup
        ? (brand?.shopify?.sharedSecret ?? '').concat(
            '••••••••••••••••••••••••••'
          )
        : '',
      carrier_code: brand?.shopify?.carrierCode ?? '',
      international_carrier_code:
        brand?.shopify?.internationalCarrierCode ?? '',
      mondial_relay_carrier_code: brand?.shopify?.mondialRelayCarrierCode ?? '',
      stock_live: brand?.shopify?.isStockLive ?? false,
      is_product_price_kept:
        brand?.shopify?.isProductPriceKept.toString() ??
        (brand?.store === StoreRegion.Us ? 'true' : 'false'),
    },
  });

  const setupShopify = async (data: ShopifyIntegrationForm) => {
    try {
      const shopifyInfos = await setupShopifyCredentials({
        brandId: brand?.id ?? '',
        data: {
          shop: formatShopifyURL(data.shopify_url.trim()),
          token: data.token,
          sharedSecret: data.shared_secret,
          isStockLive: data.stock_live,
          carrierCode: data.carrier_code,
          internationalCarrierCode: data.international_carrier_code,
          isProductPriceKept: data.is_product_price_kept === 'true',
          mondialRelayCarrierCode: data.mondial_relay_carrier_code,
        },
      });

      if (brand && shopifyInfos.setupShopifyCredentials)
        setBrand({
          ...brand,
          shopify: shopifyInfos.setupShopifyCredentials,
        });

      setIsOpen(false);
    } catch (e) {
      console.log(e);
      toast.error(t('settings.integration_tabs.shopify.setup_error'));
    }
  };

  const updateShopify = async (data: ShopifyIntegrationForm) => {
    try {
      const shopifyInfos = await updateShopifyInfo({
        data: {
          shop: data.shopify_url
            ? formatShopifyURL(data.shopify_url.trim())
            : undefined,
          token: data.token ? data.token : undefined,
          sharedSecret: data.shared_secret ? data.shared_secret : undefined,
          isStockLive: data.stock_live,
          carrierCode: data.carrier_code,
          internationalCarrierCode: data.international_carrier_code,
          isProductPriceKept: data.is_product_price_kept === 'true',
          mondialRelayCarrierCode: data.mondial_relay_carrier_code,
        },
      });

      if (brand && shopifyInfos.updateShopifyInfo)
        setBrand({
          ...brand,
          shopify: {
            ...brand.shopify,
            shop: brand.shopify?.shop ?? '',
            isStockLive: shopifyInfos.updateShopifyInfo.isStockLive,
            carrierCode: shopifyInfos.updateShopifyInfo.carrierCode,
            internationalCarrierCode:
              shopifyInfos.updateShopifyInfo.internationalCarrierCode,
            isProductPriceKept:
              shopifyInfos.updateShopifyInfo.isProductPriceKept,
            mondialRelayCarrierCode:
              shopifyInfos.updateShopifyInfo.mondialRelayCarrierCode,
          },
        });

      setIsOpen(false);
    } catch (e) {
      console.log(e);
      toast.error(t('settings.integration_tabs.shopify.setup_error'));
    }
  };

  const onSubmit = async (data: ShopifyIntegrationForm) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isShopifySetup ? await updateShopify(data) : await setupShopify(data);
  };

  if (!isShopifySetup || isEdit) {
    return (
      <div className="h-full">
        <Form {...form}>
          <form
            onSubmit={(e) => form.handleSubmit(onSubmit)(e)}
            className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
          >
            <div className="flex min-h-0 flex-1 flex-col overflow-y-auto">
              <div className="p-10">
                <div className="space-y-10">
                  <ShopifyHeaderModal
                    showRetroactiveAlert={isShopifySetup && hasRunningSale}
                  />
                  <ShopifyForm isEditMode={isShopifySetup} />
                </div>
              </div>
            </div>
            <footer className="flex shrink-0 justify-end space-x-6 p-4 lg:px-10">
              <Button
                type="button"
                appearance="outline"
                onClick={() => setIsOpen(false)}
                disabled={isSetupShopifyPending || isUpdateShopifyPending}
              >
                {t('cancel')}
              </Button>
              <Button
                type="submit"
                appearance="primary"
                disabled={isSetupShopifyPending || isUpdateShopifyPending}
              >
                {t('confirm')}
              </Button>
            </footer>
          </form>
        </Form>
      </div>
    );
  }

  return (
    <div className="h-full">
      <div className="p-10">
        <div className="space-y-10">
          <ShopifyHeaderModal showRetroactiveAlert={false} />
          <ShopifyDetails />
          <Button
            type="button"
            appearance="outline"
            onClick={() => setIsEdit(true)}
          >
            {t('settings.integration_tabs.shopify.edit')}
          </Button>
        </div>
      </div>
    </div>
  );
};

ShopifyModal.displayName = 'ShopifyModal';
