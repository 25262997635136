import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: import.meta.env.REACT_APP_SENTRY_DSN as string,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.extraErrorDataIntegration(),
  ],

  // Set tracesSampleRate to 0.2 to capture 20%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,

  environment: import.meta.env.REACT_APP_STAGE as string,

  release:
    import.meta.env.REACT_APP_STAGE === 'production'
      ? `brand@${import.meta.env.REACT_APP_VERSION}`
      : `brand@${import.meta.env.REACT_APP_STAGE}.${
          import.meta.env.REACT_APP_COMMIT_SHA
        }`,
});
