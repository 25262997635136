import { useTranslation } from 'react-i18next';

import Icon from '@appchoose/icon';
import Tooltip from '@appchoose/tooltip';
import type { TFunction } from 'i18next';

import type { OrdersQuery } from '../../types/generated-new';
import { OrderTagShipment, TrackingSubStatus } from '../../types/generated-new';
import { formatList } from '../../utils/string';
import { getTrackingErrors } from '../modal-order/modal-order-parcel';
import { OrderBadge } from './order-badge';

type OrderShipmentVariantVariables = {
  isCancelled?: boolean;
  tag: OrderTagShipment;
  trackingSubStatus: TrackingSubStatus | null | undefined;
  hasTrackingStucked: boolean;
};

export const orderShipmentVariant = (
  {
    hasTrackingStucked,
    isCancelled,
    tag,
    trackingSubStatus,
  }: OrderShipmentVariantVariables,
  defaultVariant: 'default' | 'filled' = 'default'
) => {
  if (isCancelled) return defaultVariant;

  switch (tag) {
    case OrderTagShipment.Pending:
      if (
        trackingSubStatus === TrackingSubStatus.PendingCarrierUnrecognized ||
        trackingSubStatus === TrackingSubStatus.PendingWrongCarrier ||
        hasTrackingStucked
      )
        return 'danger';
      else return defaultVariant;
    case OrderTagShipment.Expired:
    case OrderTagShipment.Exception:
      return 'warning';
    default:
      return defaultVariant;
  }
};

export const getOrderShipmentLabelFromTag = (
  tag: OrderTagShipment,
  t: TFunction
) => {
  switch (tag) {
    case OrderTagShipment.AttemptFail:
      return t('order.status.shipment.attempt_fail');
    case OrderTagShipment.AvailableForPickup:
      return t('order.status.shipment.available_for_pickup');
    case OrderTagShipment.Delivered:
      return t('order.status.shipment.delivered');
    case OrderTagShipment.Exception:
      return t('order.status.shipment.exception');
    case OrderTagShipment.Expired:
      return t('order.status.shipment.expired');
    case OrderTagShipment.InTransit:
      return t('order.status.shipment.in_transit');
    case OrderTagShipment.InfoReceived:
      return t('order.status.shipment.info_received');
    case OrderTagShipment.OutForDelivery:
      return t('order.status.shipment.out_for_delivery');
    case OrderTagShipment.Pending:
      return t('order.status.shipment.pending');
  }
};

const OrderShipment: React.FC<OrderShipmentBadgeProps> = ({
  hasTrackingStucked,
  isCancelled,
  tag,
  trackingSubStatus,
}) => {
  const { t } = useTranslation();

  const variantStyle = orderShipmentVariant({
    isCancelled,
    hasTrackingStucked,
    tag,
    trackingSubStatus,
  });

  return (
    <OrderBadge style={variantStyle}>
      <div className="flex flex-nowrap items-center gap-x-1">
        {variantStyle === 'danger' || variantStyle === 'warning' ? (
          <Icon icon="alertCircle" className="size-3.5" />
        ) : null}
        <span className={isCancelled ? 'line-through' : ''}>
          {getOrderShipmentLabelFromTag(tag, t)}
        </span>
      </div>
    </OrderBadge>
  );
};

type OrderShipmentBadgeProps = {
  hasTrackingStucked: OrdersQuery['orders']['nodes'][number]['parcels'][number]['hasTrackingStucked'];
  isCancelled?: boolean;
  tag: OrderTagShipment;
  trackingSubStatus: OrdersQuery['orders']['nodes'][number]['parcels'][number]['trackingSubStatus'];
};

export const OrderShipmentBadge: React.FC<OrderShipmentBadgeProps> = ({
  hasTrackingStucked,
  isCancelled,
  tag,
  trackingSubStatus,
}) => {
  const { i18n, t } = useTranslation();

  if (tag === OrderTagShipment.None) return null;

  if (
    (tag === OrderTagShipment.Pending &&
      (trackingSubStatus === TrackingSubStatus.PendingCarrierUnrecognized ||
        trackingSubStatus === TrackingSubStatus.PendingWrongCarrier ||
        hasTrackingStucked)) ||
    tag === OrderTagShipment.Exception ||
    tag === OrderTagShipment.Expired
  )
    return (
      <Tooltip
        offset={[0, 16]}
        content={
          <div className="p-1">
            {formatList(
              getTrackingErrors(
                {
                  hasTrackingStucked:
                    tag === OrderTagShipment.Pending && hasTrackingStucked,
                  trackingSubStatus,
                },
                t
              )?.map((error) => error.title),
              i18n.language,
              {
                type: 'conjunction',
              }
            )}
          </div>
        }
        placement="top"
      >
        <div>
          <OrderShipment
            hasTrackingStucked={hasTrackingStucked}
            isCancelled={isCancelled}
            tag={tag}
            trackingSubStatus={trackingSubStatus}
          />
        </div>
      </Tooltip>
    );

  return (
    <OrderShipment
      hasTrackingStucked={hasTrackingStucked}
      isCancelled={isCancelled}
      tag={tag}
      trackingSubStatus={trackingSubStatus}
    />
  );
};
