import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';

export const useFetchData = <TData, TVariables>(
  query: string,
  options?: RequestInit['headers']
): ((variables?: TVariables) => Promise<TData>) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  return async (variables?: TVariables) => {
    const headersInit: HeadersInit = {
      'Content-Type': 'application/json',
      'Graphql-Client-Name': 'brand',
      'X-FROM': document.location.pathname,
      ...options,
    };
    if (isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      headersInit['Authorization'] = await getAccessTokenSilently();
    }

    const res = await fetch(import.meta.env.REACT_APP_API_URL_NEW as string, {
      method: 'POST',
      headers: headersInit,
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const json = (await res.json()) as {
      data: TData;
      errors: Error[] | undefined;
    };

    if (json.errors?.[0]) {
      const { message } = json.errors[0];
      Sentry.captureException(json.errors[0]);

      throw new Error(message);
    }

    return json.data;
  };
};
