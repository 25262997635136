import { atom, selector } from 'recoil';

import { isAfter, isBefore } from 'date-fns';

import type {
  StoreCurrency,
  StoreRegion,
  WidgetState,
  WidgetType,
} from '../types/generated';
import { serverTimeState } from './brand';

export type RawSale = {
  __typename?: 'Sale';
  id: string;
  start_at?: string | null;
  end_at?: string | null;
  logistics_manager?: string | null;
  selection_name?: string | null;
  store: StoreRegion;
  currency: StoreCurrency;
  widgets?: {
    __typename?: 'Widget';
    id: string;
    type?: WidgetType | null;
    state?: WidgetState | null;
    results?: unknown;
    proposition?: unknown;
  }[];
};

export const salesState = atom<RawSale[] | undefined>({
  key: 'salesState',
  default: undefined,
});

export const activeSaleIdState = atom<string | undefined>({
  key: 'activeSaleIdState',
  default: undefined,
});

export const shortActiveSaleIdState = selector({
  key: 'shortActiveSaleIdState',
  get: ({ get }) => {
    const activeSaleId = get(activeSaleIdState);

    return activeSaleId?.slice(0, 5) ?? '';
  },
});

export const sortedSalesState = selector({
  key: 'sortedSalesState',
  get: ({ get }) => {
    const list = get(salesState);

    return list
      ?.slice()
      .sort((a, b) =>
        new Date(a.start_at ?? '').getTime() >
        new Date(b.start_at ?? '').getTime()
          ? -1
          : 1
      );
  },
});

export const activeSaleState = selector({
  key: 'activeSaleState',
  get: ({ get }) => {
    const list = get(salesState);
    const activeSaleId = get(activeSaleIdState);

    return list?.find((sale) => sale.id === activeSaleId);
  },
});

export const getInitialActiveSaleId = (
  queryParamSaleId: string | undefined,
  sales: RawSale[] | undefined,
  serverTime: Date
) => {
  if (queryParamSaleId)
    return sales?.find((sale) => sale.id.startsWith(queryParamSaleId))?.id;

  const startedSales =
    sales
      ?.filter((sale) => isAfter(serverTime, new Date(sale.start_at ?? '')))
      .sort((a, b) =>
        new Date(a.start_at ?? '').getTime() >
        new Date(b.start_at ?? '').getTime()
          ? -1
          : 1
      ) ?? [];
  if (startedSales.length > 0 && startedSales[0]) {
    return startedSales[0].id;
  }

  if (sales && sales.length > 0 && sales[0]) return sales[0].id;
};

export const isWaitingForSaleState = selector({
  key: 'isWaitingForSaleState',
  get: ({ get }) => {
    const activeSale = get(activeSaleState);
    const serverTime = get(serverTimeState);

    return !!activeSale && isFuture(activeSale, serverTime);
  },
});

export const activeSaleWidgetsState = selector({
  key: 'activeSaleWidgetsState',
  get: ({ get }) => {
    const activeSale = get(activeSaleState);
    const serverTime = get(serverTimeState);

    if (
      activeSale &&
      isCurrent(activeSale, serverTime) &&
      !isEnded(activeSale, serverTime)
    ) {
      if (Array.isArray(activeSale.widgets) && activeSale.widgets.length) {
        return activeSale.widgets.filter((c) => !c.state);
      }
    }
    return undefined;
  },
});

export const isFuture = (sale: RawSale, currentDate: Date | undefined) => {
  return isBefore(currentDate ?? new Date(), new Date(sale.start_at ?? ''));
};

export const isCurrent = (sale: RawSale, currentDate: Date | undefined) => {
  return (
    isAfter(currentDate ?? new Date(), new Date(sale.start_at ?? '')) &&
    isBefore(currentDate ?? new Date(), new Date(sale.end_at ?? ''))
  );
};

export const isEnded = (sale: RawSale, currentDate: Date | undefined) => {
  return isAfter(currentDate ?? new Date(), new Date(sale.end_at ?? ''));
};
