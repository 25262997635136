/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Button from '@appchoose/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@appchoose/form';
import Label from '@appchoose/label';
import RadioGroup, { RadioGroupItem } from '@appchoose/radio-group';
import { toast } from '@appchoose/toast';
import { useAuth0 } from '@auth0/auth0-react';

import { brandState } from '../../stores/brand';
import { activeSaleIdState } from '../../stores/sales';
import {
  Logistician,
  OrdersExportFileExtension,
  OrdersSortFieldInput,
  OrdersSortOrderInput,
  useOrdersExportQuery,
  useOrdersUnfulfilledExportQuery,
} from '../../types/generated-new';
import { track } from '../../utils/analytics';
import {
  getDateFiltersFromTableFilters,
  getExportSortFromTableSort,
  getFiltersFromTableFilters,
  getSearchFromTableSearch,
  getTaggingFromTabEntry,
} from '../../views/orders-page/orders-page';
import { useSearch } from '../../views/orders-page/use-search';
import { useTabs } from '../../views/orders-page/use-tabs';
import { useFilters } from '../order-table/use-filters';
import { useSorting } from '../order-table/use-sorting';

type ModalExportProps = {
  onClose: () => void;
};

export enum ExportOrderType {
  All = 'ALL',
  ReadyToProcess = 'READY_TO_PROCESS',
  Search = 'SEARCH',
  Filters = 'FILTERS',
}

const getTrackFilterby = (filterBy: ExportOrderType) => {
  switch (filterBy) {
    case ExportOrderType.All:
      return 'all';
    case ExportOrderType.ReadyToProcess:
      return 'readyToProcess';
    case ExportOrderType.Search:
      return 'search';
    case ExportOrderType.Filters:
      return 'filter';
    default:
      return 'all';
  }
};

const getTrackOutputFormat = (outputFormat: Logistician) => {
  switch (outputFormat) {
    case Logistician.Coliship:
      return 'coliship';
    case Logistician.Cubyn:
      return 'cubyn';
    case Logistician.Shippingbo:
      return 'shippingBo';
    case Logistician.Boxtal:
      return 'boxtal';
    case Logistician.Sendcloud:
      return 'sendCloud';
    case Logistician.Bigblue:
      return 'bigBlue';
    case Logistician.Transporterapp:
      return 'transporterApp';
    case Logistician.GenericRow:
      return 'genericRow';
    case Logistician.GenericColumn:
      return 'genericColumn';
    default:
      return 'genericRow';
  }
};

type ExportData = {
  exportType: ExportOrderType;
  fileFormat: OrdersExportFileExtension;
  logistician: Logistician;
};

export const ModalExport: React.FC<ModalExportProps> = ({
  onClose,
}: ModalExportProps) => {
  const { user } = useAuth0();
  const { filters, hasActiveFilters } = useFilters();
  const { debouncedSearchTerm, isSearchActive } = useSearch();
  const { sorting } = useSorting();
  const { selectedTab } = useTabs();
  const { t } = useTranslation();

  const brand = useRecoilValue(brandState);
  const activeSaleId = useRecoilValue(activeSaleIdState);

  const isInternalUser = user?.['https://appchoose.io/claims/isInternalUser'];

  const form = useForm<ExportData>({
    defaultValues: {
      exportType: isSearchActive
        ? ExportOrderType.Search
        : hasActiveFilters
          ? ExportOrderType.Filters
          : ExportOrderType.All,
      fileFormat: OrdersExportFileExtension.Csv,
      logistician: Logistician.GenericRow,
    },
  });

  const exportOrderTypeOptions = [
    { name: t('export.filters.all_orders'), value: ExportOrderType.All },
    {
      name: t('export.filters.waiting_orders'),
      value: ExportOrderType.ReadyToProcess,
    },
    {
      name: t('export.filters.searched_orders'),
      value: ExportOrderType.Search,
      disabled: !isSearchActive,
    },
    {
      name: t('export.filters.filtered_orders'),
      value: ExportOrderType.Filters,
      disabled: !hasActiveFilters,
    },
  ];

  const fileFormatOptions = [
    {
      name: '.csv',
      value: OrdersExportFileExtension.Csv,
    },
    {
      name: '.xls',
      value: OrdersExportFileExtension.Xlsx,
    },
  ];

  const logisticianStandardOptions = [
    {
      name: t('export.logistician.generic_row'),
      value: Logistician.GenericRow,
    },
    {
      name: t('export.logistician.generic_column'),
      value: Logistician.GenericColumn,
    },
  ];

  const logisticianOptions = [
    {
      name: t('export.logistician.coliship'),
      value: Logistician.Coliship,
    },
    {
      name: t('export.logistician.cubyn'),
      value: Logistician.Cubyn,
    },
    {
      name: t('export.logistician.shippingBo'),
      value: Logistician.Shippingbo,
    },
    {
      name: t('export.logistician.boxtal'),
      value: Logistician.Boxtal,
    },
    {
      name: t('export.logistician.sendcloud'),
      value: Logistician.Sendcloud,
    },
    {
      name: t('export.logistician.bigblue'),
      value: Logistician.Bigblue,
    },
    {
      name: t('export.logistician.transporterapp'),
      value: Logistician.Transporterapp,
    },
  ];

  const { isFetching: loadingOrdersExport, refetch: refetchOrdersExport } =
    useOrdersExportQuery(
      {
        saleId: activeSaleId ?? '',
        sellerId: brand?.id ?? '',
        input:
          form.getValues('exportType') === ExportOrderType.Filters ||
          form.getValues('exportType') === ExportOrderType.Search
            ? {
                filter: {
                  creationDate: getDateFiltersFromTableFilters(filters),
                  tagging: {
                    brand: getTaggingFromTabEntry(selectedTab),
                    ...getFiltersFromTableFilters(filters),
                  },
                },
                ...getSearchFromTableSearch(debouncedSearchTerm),
                sort: getExportSortFromTableSort(sorting),
                fileExtension: form.getValues('fileFormat'),
                fileFormat: form.getValues('logistician'),
              }
            : {
                sort: {
                  field: OrdersSortFieldInput.CreationDate,
                  order: OrdersSortOrderInput.Descending,
                },
                fileExtension: form.getValues('fileFormat'),
                fileFormat: form.getValues('logistician'),
              },
      },
      {
        enabled: false,
      }
    );
  const {
    isFetching: loadingOrdersUnfulfilledExport,
    refetch: refetchOrdersUnfulfilledExport,
  } = useOrdersUnfulfilledExportQuery(
    {
      saleId: activeSaleId ?? '',
      sellerId: brand?.id ?? '',
      input: {
        fileExtension: form.getValues('fileFormat'),
        fileFormat: form.getValues('logistician'),
      },
    },
    {
      enabled: false,
    }
  );

  const onSubmit = async (data: ExportData) => {
    if (data.exportType === ExportOrderType.ReadyToProcess) {
      const result = await refetchOrdersUnfulfilledExport();
      if (result.data) {
        if (result.data.ordersUnfulfilledExport.fileUrl) {
          window.open(result.data.ordersUnfulfilledExport.fileUrl, '_self');
          toast.success(result.data.ordersUnfulfilledExport.fileUrl);
        } else {
          toast.success(t('orders.empty_result'));
        }
        onClose();
      }
      if (result.error) {
        toast.error(t('error'));
      }
    } else {
      const result = await refetchOrdersExport();
      if (result.data) {
        if (result.data.ordersExport.fileUrl) {
          window.open(result.data.ordersExport.fileUrl, '_self');
          toast.success(result.data.ordersExport.fileUrl);
        } else {
          toast.success(t('orders.empty_result'));
        }
        onClose();
      }
      if (result.error) {
        toast.error(t('error'));
      }
    }
    track('ExportOrders', {
      filterBy: getTrackFilterby(data.exportType),
      format: data.fileFormat === OrdersExportFileExtension.Csv ? 'csv' : 'xls',
      outputFormat: getTrackOutputFormat(data.logistician),
    });
  };

  form.watch('exportType');
  form.watch('fileFormat');
  form.watch('logistician');

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col overflow-hidden"
      >
        <div className="overflow-y-auto p-6 md:p-10">
          <h3
            id="modal-export-title"
            className="mb-6 text-2xl font-bold text-gray-900"
          >
            {t('orders.modals.export.title')}
          </h3>
          <div className="space-y-6">
            <section>
              <FormField
                control={form.control}
                name="exportType"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="sr-only">
                      {t('export.orders_to_export')}
                    </FormLabel>
                    <FormControl>
                      <RadioGroup
                        className="gap-2"
                        {...field}
                        onValueChange={(value) => {
                          field.onChange(value as ExportOrderType);
                          if (
                            (value as ExportOrderType) !==
                            ExportOrderType.ReadyToProcess
                          ) {
                            form.resetField('logistician');
                          }
                        }}
                      >
                        {exportOrderTypeOptions.map((option) => (
                          <div
                            className="group flex items-center space-x-3"
                            key={option.value}
                          >
                            <RadioGroupItem
                              key={option.value}
                              value={option.value}
                              id={`export-type-${option.value}`}
                              disabled={option.disabled}
                            />
                            <Label
                              htmlFor={`export-type-${option.value}`}
                              className="cursor-pointer text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                            >
                              {option.name}
                            </Label>
                          </div>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </FormItem>
                )}
              />
            </section>
            <section className="space-y-5">
              <h4 className="font-semibold text-gray-700">
                {t('export.file_format')}
              </h4>
              <FormField
                control={form.control}
                name="fileFormat"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="sr-only">
                      {t('export.file_format')}
                    </FormLabel>
                    <FormControl>
                      <RadioGroup
                        className="gap-2"
                        {...field}
                        onValueChange={(value) => {
                          field.onChange(value);
                          if (
                            (value as OrdersExportFileExtension) !==
                            OrdersExportFileExtension.Csv
                          ) {
                            form.resetField('logistician');
                          }
                        }}
                      >
                        {fileFormatOptions.map((option) => (
                          <div
                            className="group flex items-center space-x-3"
                            key={option.value}
                          >
                            <RadioGroupItem
                              key={option.value}
                              value={option.value}
                              id={`file-format-${option.value}`}
                            />

                            <Label
                              htmlFor={`file-format-${option.value}`}
                              className="cursor-pointer text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                            >
                              {option.name}
                            </Label>
                          </div>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </FormItem>
                )}
              />
            </section>
            <section className="space-y-5">
              <h4 className="flex items-center font-semibold text-gray-700">
                {t('export.output_format')}
              </h4>
              <FormField
                control={form.control}
                name="logistician"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="sr-only">
                      {t('export.output_format')}
                    </FormLabel>
                    <FormControl>
                      <RadioGroup
                        className="gap-4"
                        {...field}
                        // Allow to reset radio group
                        value={field.value || ''}
                        onValueChange={field.onChange}
                      >
                        <div className="grid gap-2">
                          <span className="text-xs font-semibold uppercase tracking-wider text-gray-700">
                            {t('export.basic')}
                          </span>
                          {logisticianStandardOptions.map((option) => (
                            <div
                              className="group flex items-center space-x-3"
                              key={option.value}
                            >
                              <RadioGroupItem
                                key={option.value}
                                value={option.value}
                                id={`logistician-${option.value}`}
                              />

                              <Label
                                htmlFor={`logistician-${option.value}`}
                                className="cursor-pointer text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                              >
                                {option.name}
                              </Label>
                            </div>
                          ))}
                        </div>
                        <div className="grid gap-2">
                          <span className="text-xs font-semibold uppercase tracking-wider text-gray-700">
                            {t('export.or_logistician')}
                          </span>
                          {logisticianOptions.map((option) => (
                            <div
                              className="group flex items-center space-x-3"
                              key={option.value}
                            >
                              <RadioGroupItem
                                key={option.value}
                                value={option.value}
                                id={`logistician-${option.value}`}
                                disabled={
                                  (isInternalUser &&
                                    form.getValues('fileFormat') !==
                                      OrdersExportFileExtension.Csv) ||
                                  (!isInternalUser &&
                                    (form.getValues('exportType') !==
                                      ExportOrderType.ReadyToProcess ||
                                      form.getValues('fileFormat') !==
                                        OrdersExportFileExtension.Csv))
                                }
                              />

                              <Label
                                htmlFor={`logistician-${option.value}`}
                                className="cursor-pointer text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                              >
                                {option.name}
                              </Label>
                            </div>
                          ))}
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </FormItem>
                )}
              />
            </section>
          </div>
        </div>
        <div className="flex shrink-0 justify-end space-x-6 border-t border-gray-100 p-6 md:p-10 md:pt-6">
          <Button type="button" appearance="outline" onClick={() => onClose()}>
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            disabled={loadingOrdersExport || loadingOrdersUnfulfilledExport}
          >
            {t('export.export')}
          </Button>
        </div>
      </form>
    </Form>
  );
};

ModalExport.displayName = 'ModalExport';
