import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import type { BrandMatch } from '../../types/navigation';
import { AnimatedCheck } from '../animated-check/animated-check';

export const UpdatingInfoThankYou: React.FC = () => {
  const navigate = useNavigate();
  const { brandId = '' } = useParams<BrandMatch>();
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => navigate(`/${brandId}/home`), 3000);
  }, []);

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <AnimatedCheck className="mb-6 size-32" />
      <div className="text-2xl font-bold text-gray-900">
        {t('updating_info.end.main_content')}
      </div>
    </div>
  );
};
