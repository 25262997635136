import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import Loader from '@appchoose/loader';
import ToggleSwitch from '@appchoose/toggle-switch';

import sad from '../../assets/img/Sad.svg';
import { PaginationList } from '../../components/pagination/pagination-list';
import { activeSaleState } from '../../stores/sales';
import { useTopProductsStatsQuery } from '../../types/generated';
import { TopProductsRow } from './Top-Products-Row';

import './Top-Products.scss';

export const TOP_PRODUCTS_PER_PAGE = 5;

export const TopProducts = () => {
  const activeSale = useRecoilValue(activeSaleState);
  const { t } = useTranslation();

  const [offsetTopProduct, setOffsetTopProduct] = useState(0);
  const [splitSizes, setSplitSizes] = useState(false);

  const {
    data: topProductsStatsData,
    isLoading: loadingTopProductsStats,
    error: errorTopProductsStats,
  } = useTopProductsStatsQuery(
    {
      saleId: activeSale?.id ?? '',
    },
    {
      enabled: !!activeSale,
      refetchOnWindowFocus: false,
    }
  );

  const { data: topProductsStatsSizeData } = useTopProductsStatsQuery(
    {
      saleId: activeSale?.id ?? '',
      optionKey: splitSizes ? undefined : 'size',
    },
    {
      enabled:
        !!activeSale &&
        topProductsStatsData?.topProductsStats.available_option_keys.includes(
          'size'
        ),
      refetchOnWindowFocus: false,
    }
  );

  const onChangeToggleDisplaySizes = () => {
    setSplitSizes((prev) => !prev);
    setOffsetTopProduct(0);
  };

  const topProducts = !splitSizes
    ? topProductsStatsSizeData?.topProductsStats
    : topProductsStatsData?.topProductsStats;
  const availableOptionKeys = !splitSizes
    ? topProductsStatsSizeData?.topProductsStats.available_option_keys
    : topProductsStatsData?.topProductsStats.available_option_keys;
  const hasProducts =
    topProducts &&
    Array.isArray(topProducts.products) &&
    topProducts.products.length;
  const hasSizes = availableOptionKeys?.includes('size') && !splitSizes;

  return (
    <div data-scope="top-products">
      <h3 className="flex flex-col justify-start gap-y-6 sm:flex-row sm:items-center sm:justify-between">
        {availableOptionKeys?.includes('size') && splitSizes ? (
          <p className="m-0 text-lg font-bold text-gray-900">
            {t('analytics.top_products.title_with_sizes')}
          </p>
        ) : (
          <p className="m-0 text-lg font-bold text-gray-900">
            {t('analytics.top_products.title')}
          </p>
        )}
        {availableOptionKeys?.includes('size') ? (
          <div className="flex items-center sm:flex-row-reverse">
            <ToggleSwitch onCheckedChange={onChangeToggleDisplaySizes} />
            <p className="ml-4 text-xs text-gray-700 sm:ml-0 sm:mr-4">
              {t('analytics.top_products.degroup')}
            </p>
          </div>
        ) : null}
      </h3>
      {errorTopProductsStats ? (
        <div className="mt-8 flex flex-col items-center">
          <img src={sad} />
          <p className="mt-5 text-3xl font-bold">
            {t('analytics.top_products.error_title')}
          </p>
          <p className="mt-4 text-gray-700">
            {t('analytics.top_products.error_description')}
          </p>
        </div>
      ) : null}
      {(!errorTopProductsStats && loadingTopProductsStats) ||
      !topProductsStatsData ? (
        <div className="flex h-[34rem] items-center justify-center">
          <Loader className="size-8" />
        </div>
      ) : null}
      {!errorTopProductsStats && !loadingTopProductsStats && !hasProducts ? (
        <div className="no-star">
          <Icon icon="cart" size="large" className="mr-1" />
          <p>{t('analytics.top_products.text')}</p>
        </div>
      ) : null}
      {!errorTopProductsStats && hasProducts ? (
        <>
          <table className="flex select-none flex-col pb-2">
            <thead className="hidden sm:block">
              <tr
                className={cn('grid border-b-0.5 py-2', {
                  nosize: !hasSizes,
                })}
              >
                <th data-column="rank">
                  <p className="m-0 text-xs font-semibold uppercase text-gray-500">
                    {t('analytics.top_products.rank')}
                  </p>
                </th>
                <th
                  data-column="image"
                  className="m-0 grow text-xs font-semibold uppercase text-gray-500"
                >
                  {t('analytics.top_products.product')}
                </th>
                <th
                  data-column="name"
                  className="m-0 grow text-xs font-semibold uppercase text-gray-500"
                ></th>
                <th
                  data-column="sales"
                  className="m-0 hidden text-xs font-semibold uppercase text-gray-500 sm:block"
                >
                  {t('analytics.top_products.sales')}
                </th>
                <th
                  data-column="revenue"
                  className="m-0 hidden text-xs font-semibold uppercase text-gray-500 sm:block"
                >
                  {t('analytics.top_products.revenue')}
                </th>
                {hasSizes ? (
                  <th
                    data-column="size"
                    className="m-0 text-xs font-semibold uppercase text-gray-500"
                  >
                    {t('analytics.top_products.sizes')}
                  </th>
                ) : null}
              </tr>
            </thead>
            {topProducts?.products
              .filter(
                (product, index) =>
                  index / TOP_PRODUCTS_PER_PAGE >= offsetTopProduct
              )
              .slice(0, TOP_PRODUCTS_PER_PAGE)
              .map((product) => (
                <TopProductsRow
                  key={product?.group_id}
                  hasSize={availableOptionKeys?.includes('size') && !splitSizes}
                  product={product}
                  rank={topProducts?.products.indexOf(product)}
                />
              ))}
          </table>
          <PaginationList
            currentPage={offsetTopProduct + 1}
            totalCount={Math.ceil(topProducts.products.length || 0)}
            pageSize={TOP_PRODUCTS_PER_PAGE}
            onChange={(newPage: number) => {
              const newOffset = newPage - 1;
              setOffsetTopProduct(newOffset);
            }}
            className="mt-4"
          />
        </>
      ) : null}
    </div>
  );
};

TopProducts.displayName = 'TopProducts';
