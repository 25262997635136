import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import Icon from '@appchoose/icon';

import type { BrandMatch } from '../../types/navigation';
import {
  orderDetailsContainerVariants,
  orderDetailsCourierVariants,
  orderDetailsImageVariants,
  orderDetailsOrderIdTextVariants,
} from './order-details';

export type OrderDetailsSwapProps = {
  newTrackingNumber: string;
  order: {
    id?: string;
    parcels?: {
      trackingCourier: {
        logoUrl: string;
        name: string;
      };
      trackingNumber: string;
    }[];
    recipient?: {
      fullName: string;
    };
  };
};

export const OrderDetailsSwap: React.FC<OrderDetailsSwapProps> = ({
  newTrackingNumber,
  order,
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { brandId = '' } = useParams<BrandMatch>();

  const parcel = order.parcels?.[0];
  return (
    <div className="flex space-x-6 rounded bg-[#FBFBFB] px-4 py-6">
      <div className={orderDetailsContainerVariants({ size: 'default' })}>
        <div className="flex h-full items-start">
          <img
            src={parcel?.trackingCourier.logoUrl}
            alt=""
            className={orderDetailsImageVariants({
              size: 'default',
              style: 'default',
            })}
          />
        </div>
        <div className="space-y-1">
          <div className={orderDetailsCourierVariants({ style: 'default' })}>
            {parcel?.trackingCourier.name}
          </div>
          <div
            className={orderDetailsOrderIdTextVariants({
              size: 'default',
              style: 'success',
            })}
          >
            {newTrackingNumber}
          </div>
          <div className="text-xs text-gray-500 line-through">
            {parcel?.trackingNumber}
          </div>
        </div>
      </div>

      <div className="space-y-1">
        <div className="text-xs font-semibold uppercase tracking-wider text-gray-500">
          {t('order.order')}
        </div>

        <div className="text-sm font-semibold text-gray-900">
          {order.recipient?.fullName}
        </div>

        <a
          href={`/${brandId}/orders/${order.id}${search}`}
          target="_blank"
          rel="noreferrer"
          className="flex items-center space-x-1 text-xs font-semibold text-gray-500"
        >
          <span>{order.id}</span>
          <Icon icon="externalLinkSimple" className="size-3.5" />
        </a>
      </div>
    </div>
  );
};
