import React, { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import type { Address } from '@appchoose/address';
import AddressFormFields from '@appchoose/address-form-fields';
import Button from '@appchoose/button';
import cn from '@appchoose/cn';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@appchoose/command';
import { Form, FormControl, FormField, FormItem } from '@appchoose/form';
import Icon from '@appchoose/icon';
import { Popover, PopoverContent, PopoverTrigger } from '@appchoose/popover';
import { matchSorter } from 'match-sorter';

import { brandState } from '../../stores/brand';
import { StoreRegion, useUpdateSellerMutation } from '../../types/generated';
import { getDefaultOnboardingCountry } from '../../utils/address';
import { getCountryOptions } from '../../utils/address-options';
import { removeAllSpaces } from '../../utils/string';
import { isInUE } from '../../utils/utils';
import { LegalInfoFormFields } from '../brand-info/legal-info-form-fields';
import type { OnboardingStepBillingInfoForm } from '../onboarding/onboarding-step-billing-info';
import { TaxFormFields } from '../tax-form-fields/tax-form-fields';

export type BillingHandlerProps = {
  goToNextStep: () => void;
  goToNextSubStepOrStep: () => void;
};

export const BillingHandler: React.FC<BillingHandlerProps> = ({
  goToNextSubStepOrStep,
}: BillingHandlerProps) => {
  const [brand, setBrand] = useRecoilState(brandState);
  const { i18n, t } = useTranslation();

  const defaultFiscalCountry = getDefaultOnboardingCountry(
    brand?.billing_address?.country,
    brand?.shipping_country ?? ''
  );
  const countryOptions = getCountryOptions();

  const [open, setOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const listRef = useRef<HTMLDivElement>(null);
  const scrollId = useRef<number>();

  const countryMatches = useMemo(
    () => matchSorter(countryOptions, searchQuery, { keys: ['label'] }),
    [countryOptions, searchQuery]
  );

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const form = useForm<OnboardingStepBillingInfoForm>({
    mode: 'onTouched',
    defaultValues: {
      fiscalCountryCode: defaultFiscalCountry?.code || '',
      legalEntityName: brand?.billing_address?.name ?? '',
      taxId: brand?.tax_id ?? undefined,
      siret: removeAllSpaces(brand?.siret ?? ''),
      intracommunityVat: brand?.intracommunity_vat ?? undefined,
      isNotSubjectVat:
        brand?.is_autoentrepreneur !== null
          ? brand?.is_autoentrepreneur
          : false,
      street: brand?.billing_address?.street ?? '',
      street2: brand?.billing_address?.street2 ?? '',
      bp: brand?.billing_address?.bp ?? '',
      city: brand?.billing_address?.city ?? '',
      province: brand?.billing_address?.province ?? '',
      country: brand?.billing_address?.country ?? defaultFiscalCountry.fr ?? '',
      countryCode:
        brand?.billing_address?.countryCode ?? defaultFiscalCountry?.code ?? '',
    },
  });

  const onSubmit = (data: OnboardingStepBillingInfoForm) => {
    if (!brand) return;
    const billingAddress: Address = {
      name: data.legalEntityName.trim(),
      street: data.street.trim(),
      street2: data.street2?.trim(),
      bp: data.bp.trim(),
      province: data.province?.trim(),
      city: data.city.trim(),
      country: data.country,
      countryCode: data.countryCode,
    };
    setBrand({
      ...brand,
      siret: data.siret ?? '',
      is_autoentrepreneur: data.isNotSubjectVat,
      tax_id: data.taxId ?? '',
      intracommunity_vat: data.intracommunityVat,
      billing_address: billingAddress,
    });
    updateSellerMutation({
      updateSeller: {
        siret: data.siret,
        is_autoentrepreneur: data.isNotSubjectVat,
        tax_id: data.taxId,
        intracommunity_vat: data.intracommunityVat,
        address: billingAddress,
      },
    });
    goToNextSubStepOrStep();
  };

  // Rerender on 'fiscalCountryCode' change
  form.watch('fiscalCountryCode');

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-2 sm:mt-20">
        <h2 className="text-2xl font-bold sm:text-3.5xl">
          {t('onboarding.billing.sub_steps.1.title')}
        </h2>
        <p className="text-sm leading-5.5 text-gray-700">
          {t('onboarding.billing.sub_steps.1.subtitle')}
        </p>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-10">
            <section>
              <div className="pb-8 text-2xl font-bold">
                {t('onboarding.billing.sub_steps.1.subtitle_country')}
              </div>
              <FormField
                control={form.control}
                name="fiscalCountryCode"
                render={({ field }) => {
                  const selectedCountryOption = countryOptions.find(
                    (country) => country.value === field.value
                  );
                  return (
                    <FormItem>
                      <Popover open={open} onOpenChange={setOpen}>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <button
                              type="button"
                              role="combobox"
                              aria-expanded={open}
                              className='form-input flex w-full items-center justify-between rounded border-gray-500 text-sm leading-5.5 text-gray-900 transition duration-300 hover:border-gray-700 focus:border-gray-700 focus:ring-1 focus:ring-gray-700 aria-[invalid="true"]:border-red-600 aria-[invalid="true"]:ring-red-600'
                            >
                              <div className="flex items-center truncate">
                                {field.value
                                  ? selectedCountryOption?.leftSection
                                  : null}
                                <span className="truncate">
                                  {field.value
                                    ? selectedCountryOption?.label
                                    : t('address.fields.country.empty_field')}
                                </span>
                              </div>
                              <Icon
                                icon={open ? 'close' : 'arrowDown'}
                                className="ml-2 size-4 shrink-0 opacity-50"
                              />
                            </button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent
                          className="p-0"
                          style={{
                            width: 'var(--radix-popover-trigger-width)',
                          }}
                        >
                          <Command shouldFilter={false}>
                            <CommandInput
                              placeholder={t(
                                'address.fields.country.placeholder_search'
                              )}
                              onValueChange={(value) => {
                                setSearchQuery(value);

                                //#region scroll list to top when typing
                                // https://github.com/pacocoursey/cmdk/issues/234
                                // https://github.com/pacocoursey/cmdk/issues/233

                                // clear pending scroll
                                if (scrollId.current)
                                  cancelAnimationFrame(scrollId.current);

                                // the setTimeout is used to create a new task
                                // this is to make sure that we don't scroll until the user is done typing
                                // you can tweak the timeout duration ofc
                                scrollId.current = requestAnimationFrame(() => {
                                  // inside your list select the first group and scroll to the top
                                  listRef.current?.scrollTo({ top: 0 });
                                });

                                //#endregion
                              }}
                              value={searchQuery}
                            />
                            <CommandList ref={listRef}>
                              <CommandEmpty>
                                {t('address.fields.country.no_results')}
                              </CommandEmpty>

                              <CommandGroup>
                                {countryMatches.map((country) => (
                                  <CommandItem
                                    key={country.value}
                                    value={country.value}
                                    keywords={[country.label]}
                                    onSelect={(currentValue) => {
                                      const newValue =
                                        currentValue === field.value
                                          ? ''
                                          : currentValue;
                                      field.onChange(newValue);
                                      form.setValue(
                                        'country',
                                        countryOptions.find(
                                          (option) => option.value === newValue
                                        )?.label ?? '',
                                        {
                                          shouldValidate: true,
                                        }
                                      );
                                      form.setValue('countryCode', newValue);
                                      setOpen(false);
                                    }}
                                    className={cn(
                                      'flex items-center justify-between',
                                      {
                                        'font-semibold text-green-900':
                                          field.value === country.value,
                                      }
                                    )}
                                  >
                                    <div className="flex items-center truncate">
                                      {country.leftSection}

                                      <span className="truncate">
                                        {country.label}
                                      </span>
                                    </div>
                                    <Icon
                                      icon="check"
                                      className={cn(
                                        'ml-2 size-4 shrink-0',
                                        field.value === country.value
                                          ? 'opacity-100'
                                          : 'opacity-0'
                                      )}
                                    />
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                    </FormItem>
                  );
                }}
              />
            </section>
            <section>
              <div className="pb-8 text-2xl font-bold">
                {t('onboarding.billing.sub_steps.1.subtitle_legal_info')}
              </div>
              <LegalInfoFormFields
                hasSiret={
                  form.getValues('fiscalCountryCode') === 'FR' &&
                  brand?.store === StoreRegion.Fr
                }
                hasTaxId={false}
              />
            </section>
            {isInUE(form.getValues('fiscalCountryCode')) &&
            brand?.store === StoreRegion.Fr ? (
              <section>
                <div className="pb-8 text-2xl font-bold">
                  {t('onboarding.billing.sub_steps.1.subtitle_tax_info')}
                </div>
                <TaxFormFields
                  countryCode={form.getValues('fiscalCountryCode') ?? ''}
                />
              </section>
            ) : null}
            <section>
              <div className="pb-8 text-2xl font-bold">
                {t('onboarding.billing.sub_steps.1.subtitle_billing_address')}
              </div>
              <AddressFormFields
                googleMapsApiKey={
                  import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY as string
                }
                locale={i18n.language}
                showCompany={false}
                showName={false}
                showPhone={false}
                disabledCountry={true}
                countryRestrictions={form.getValues('fiscalCountryCode')}
                translations={{
                  suggestions: t('suggestions'),
                  fieldsNameValidationErrorsRequired: t(
                    'address.fields.name.validation_errors.required'
                  ),
                  fieldsNameValidationErrorsMaxLength: t(
                    'address.fields.name.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsFirstnameValidationErrorsRequired: t(
                    'address.fields.firstname.validation_errors.required'
                  ),
                  fieldsFirstnameValidationErrorsMaxLength: t(
                    'address.fields.firstname.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsLastnameValidationErrorsRequired: t(
                    'address.fields.lastname.validation_errors.required'
                  ),
                  fieldsLastnameValidationErrorsMaxLength: t(
                    'address.fields.lastname.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsPhoneValidationErrorsRequired: t(
                    'address.fields.phone.validation_errors.required'
                  ),
                  fieldsPhoneValidationErrorsMaxLength: t(
                    'address.fields.phone.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsStreetValidationErrorsRequired: t(
                    'address.fields.street.validation_errors.required'
                  ),
                  fieldsStreetValidationErrorsMaxLength: t(
                    'address.fields.street.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsStreet2ValidationErrorsMaxLength: t(
                    'address.fields.street2.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsBpValidationErrorsRequired: t(
                    'address.fields.bp.validation_errors.required'
                  ),
                  fieldsBpValidationErrorsMaxLength: t(
                    'address.fields.bp.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsCityValidationErrorsRequired: t(
                    'address.fields.city.validation_errors.required'
                  ),
                  fieldsCityValidationErrorsMaxLength: t(
                    'address.fields.city.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsProvinceValidationErrorsRequired: t(
                    'address.fields.province.validation_errors.required'
                  ),
                  fieldsProvinceValidationErrorsMaxLength: t(
                    'address.fields.province.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsCountryValidationErrorsRequired: t(
                    'address.fields.country.validation_errors.required'
                  ),
                  fieldsNameLabel: t('address.fields.name.label_return'),
                  fieldsNamePlaceholder: t('address.fields.name.placeholder'),
                  fieldsFirstnameLabel: t('address.fields.firstname.label'),
                  fieldsFirstnamePlaceholder: t(
                    'address.fields.firstname.placeholder'
                  ),
                  fieldsLastnameLabel: t('address.fields.lastname.label'),
                  fieldsLastnamePlaceholder: t(
                    'address.fields.lastname.placeholder'
                  ),
                  fieldsPhoneLabel: t('address.fields.phone.label'),
                  fieldsPhonePlaceholder: t('address.fields.phone.placeholder'),
                  fieldsStreetLabel: t('address.fields.street.label'),
                  fieldsStreetPlaceholder: t(
                    'address.fields.street.placeholder'
                  ),
                  fieldsStreet2Label: t('address.fields.street2.label'),
                  fieldsStreet2Placeholder: t(
                    'address.fields.street2.placeholder'
                  ),
                  fieldsBpLabel: t('address.fields.bp.label'),
                  fieldsBpPlaceholder: '',
                  fieldsCityLabel: t('address.fields.city.label'),
                  fieldsCityPlaceholder: '',
                  fieldsProvinceLabel: t('address.fields.province.label'),
                  fieldsProvincePlaceholder: '',
                  fieldsProvinceEmptyField: t(
                    'address.fields.province.empty_field'
                  ),
                  fieldsCountryLabel: t('address.fields.country.label'),
                  fieldsCountryPlaceholder: t(
                    'address.fields.country.placeholder'
                  ),
                  fieldsCountryPlaceholderSearch: t(
                    'address.fields.country.placeholder_search'
                  ),
                  fieldsCountryEmptyField: t(
                    'address.fields.country.empty_field'
                  ),
                  fieldsCountryNoResults: t(
                    'address.fields.country.no_results'
                  ),
                }}
              />
            </section>
          </div>
          <div className="mt-8 flex sm:mt-10">
            <Button type="submit" size="large">
              {t('continue')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

BillingHandler.displayName = 'BillingHandler';
