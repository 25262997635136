import React from 'react';
import type { FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import { cva } from 'cva';

import { formatPercentage } from '../utils/number';
import { MiddleTruncate } from './middle-truncate';
import { ProgressBar } from './progress-bar';

const MAX_FILE_SIZE_MB = 10;

type InvoiceFileUploadStateProps = {
  file?: File;
  fileRejections: FileRejection[];
  isDragReject: boolean;
  isDragActive: boolean;
  isUploading: boolean;
  progress: number;
  type: 'invoice' | 'down_payment';
  variant: 'default' | 'small';
};

const InvoiceFileUploadState: React.FC<InvoiceFileUploadStateProps> = ({
  file,
  fileRejections,
  isDragActive,
  progress,
  type,
  variant,
}) => {
  const { i18n, t } = useTranslation();

  if (file) {
    return (
      <>
        <div className="w-[573px] space-y-4 rounded-lg border border-gray-100 bg-white p-4">
          <div className="flex items-center justify-between space-x-4">
            <div className="flex items-center space-x-1 truncate text-sm text-gray-900">
              <Icon icon="document" className="shrink-0" />
              <MiddleTruncate
                className="max-w-[335px] text-gray-900"
                text={file.name}
              />
            </div>
            <div className="text-xs text-gray-900">
              {formatPercentage(progress / 100, i18n.language)}
            </div>
          </div>
          <ProgressBar value={progress} />
        </div>
      </>
    );
  }

  return (
    <>
      {variant === 'default' ? (
        <div className="rounded-full bg-gray-50 p-6 group-data-[active=true]:bg-green-900 group-data-[active=true]:text-white">
          <Icon icon="download" className="h-6 w-6 rotate-180" />
        </div>
      ) : null}
      <div className="space-y-2 text-center">
        <div className="text-gray-900">
          {variant === 'small'
            ? type === 'invoice'
              ? t('invoice.file_upload.drag_and_drop_another_invoice')
              : t('invoice.file_upload.drag_and_drop_another_down_payment')
            : type === 'invoice'
              ? t('invoice.file_upload.drag_and_drop_invoice')
              : t('invoice.file_upload.drag_and_drop_down_payment')}{' '}
          <b className="cursor-pointer text-green-900">
            {t('invoice.file_upload.select_file')}
          </b>
        </div>
        <div className="text-sm text-[#767A7C]">
          {t('invoice.file_upload.requirements', {
            maxFileSize: MAX_FILE_SIZE_MB,
          })}
        </div>
      </div>
      {!isDragActive && fileRejections.length > 0 ? (
        <ul className="space-y-2">
          {fileRejections
            .flatMap((rejections) => rejections.errors)
            .map((error) => (
              <li
                key={error.code}
                className="flex items-center space-x-1 text-sm font-semibold text-red-600"
              >
                <Icon icon="information" />
                {error.code === 'file-invalid-type' ? (
                  <span>
                    {t('invoice.file_upload.errors.file_invalid_type')}
                  </span>
                ) : null}
                {error.code === 'file-too-large' ? (
                  <span>{t('invoice.file_upload.errors.file_too_large')}</span>
                ) : null}
                {error.code === 'file-too-small' ? (
                  <span>{t('invoice.file_upload.errors.file_too_small')}</span>
                ) : null}
                {error.code === 'too-many-files' ? (
                  <span>{t('invoice.file_upload.errors.too_many_files')}</span>
                ) : null}
              </li>
            ))}
        </ul>
      ) : null}
    </>
  );
};

const fileUploadContainerVariants = cva({
  base: '',
  variants: {
    style: {
      default: 'min-h-[308px]',
      small: 'min-h-[100px]',
    },
  },
});

const fileUploadContentVariants = cva({
  base: '',
  variants: {
    style: {
      default: 'py-11',
      small: 'py-6',
    },
  },
});

type InvoiceFileUploadProps = {
  isUploading: boolean;
  onValueChange: (file: File) => void;
  progress: number;
  type: 'invoice' | 'down_payment';
  value?: File;
  variant: 'default' | 'small';
};

const InvoiceFileUpload: React.FC<InvoiceFileUploadProps> = ({
  isUploading,
  onValueChange,
  progress,
  type,
  value,
  variant,
}) => {
  const {
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxFiles: 1,
    maxSize: 1024 * 1024 * MAX_FILE_SIZE_MB,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length && acceptedFiles[0]) {
        onValueChange(acceptedFiles[0]);
      }
    },
  });

  return (
    <div
      className={cn(
        'group flex cursor-pointer flex-col items-center justify-center space-y-4 rounded-lg border border-dashed border-gray-300 bg-[#FBFBFB] transition-colors hover:border-green-900 data-[active=true]:border-green-900 data-[rejected=true]:border-red-600 data-[active=true]:bg-green-300',
        fileUploadContainerVariants({ style: variant })
      )}
      data-active={isDragActive}
      data-rejected={isDragReject || fileRejections.length > 0}
      {...getRootProps()}
    >
      <input {...getInputProps()} hidden />

      <div
        className={cn(
          'flex flex-col items-center space-y-6',
          fileUploadContentVariants({ style: variant })
        )}
      >
        <InvoiceFileUploadState
          file={value}
          fileRejections={fileRejections}
          isDragReject={isDragReject}
          isDragActive={isDragActive}
          isUploading={isUploading}
          progress={progress}
          type={type}
          variant={variant}
        />
      </div>
    </div>
  );
};

export { InvoiceFileUpload };
