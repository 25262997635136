import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Icon from '@appchoose/icon';
import Tooltip from '@appchoose/tooltip';
import type { TFunction } from 'i18next';

import { brandState } from '../../stores/brand';
import type { OrderQuery } from '../../types/generated-new';
import { TrackingSubStatus } from '../../types/generated-new';
import { transformDateSimple } from '../../utils/date';

type ModalOrderDeliveredAtProps = {
  parcel?: OrderQuery['order']['parcels'][0];
};

const getTrackingSubStatus = (
  trackingSubStatus: TrackingSubStatus,
  t: TFunction
) => {
  switch (trackingSubStatus) {
    case TrackingSubStatus.DeliveredMarkedByBot:
      return t('order.status.tracking_sub_status.delivered_marked_by_bot');
    case TrackingSubStatus.DeliveredMarkedByCustomer:
      return t('order.status.tracking_sub_status.delivered_marked_by_customer');
    case TrackingSubStatus.DeliveredMarkedBySupportTeam:
      return t(
        'order.status.tracking_sub_status.delivered_marked_by_support_team'
      );
    default:
      return '';
  }
};

export const ModalOrderDeliveredAt: React.FC<ModalOrderDeliveredAtProps> = ({
  parcel,
}) => {
  const brand = useRecoilValue(brandState);
  const { i18n, t } = useTranslation();

  if (!parcel?.trackingDeliveredAt) return null;

  const deliveredAtLabel = t('order.delivery_on', {
    date: transformDateSimple(
      new Date(parcel.trackingDeliveredAt),
      i18n.language === 'fr' ? 'fr' : 'en',
      undefined,
      brand?.timezone ?? undefined
    ),
    interpolation: { escapeValue: false },
  });

  if (
    parcel.trackingSubStatus === TrackingSubStatus.DeliveredMarkedByBot ||
    parcel.trackingSubStatus === TrackingSubStatus.DeliveredMarkedByCustomer ||
    parcel.trackingSubStatus === TrackingSubStatus.DeliveredMarkedBySupportTeam
  ) {
    return (
      <Tooltip
        content={
          <p className="whitespace-pre-line p-4 text-center text-xs font-semibold">
            {getTrackingSubStatus(parcel.trackingSubStatus, t)}
          </p>
        }
        offset={[0, 12]}
      >
        <div className="flex items-center space-x-1 text-[#767A7C]">
          <p className="cursor-default text-xs">{deliveredAtLabel}</p>
          <Icon icon="information" className="size-3.5" />
        </div>
      </Tooltip>
    );
  }

  return <p className="text-xs text-[#767A7C]">{deliveredAtLabel}</p>;
};
