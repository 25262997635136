import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import { useRecoilValue } from 'recoil';

import type { Address } from '@appchoose/address';

import { brandState } from '../../stores/brand';
import { StoreRegion } from '../../types/generated';
import type { BrandMatchWithSubStep } from '../../types/navigation';
import { track } from '../../utils/analytics';
import { getCorrectStep } from '../../utils/utils';
import type { BankingInfoHandlerProps } from '../banking-info-handler/banking-info-handler';
import { BankingInfoHandler } from '../banking-info-handler/banking-info-handler';
import type { BillingBankingInfoSummaryProps } from '../billing-banking-info-summary/billing-banking-info-summary';
import { BillingBankingInfoSummary } from '../billing-banking-info-summary/billing-banking-info-summary';
import type { BillingHandlerProps } from '../billing-handler/billing-handler';
import { BillingHandler } from '../billing-handler/billing-handler';
import { BillingHandlerUs } from '../billing-handler/billing-handler-us';
import type { IbanFormData } from '../brand-info/iban-form-fields';
import type { LegalInfoFormData } from '../brand-info/legal-info-form-fields';
import type { TaxFormData } from '../tax-form-fields/tax-form-fields';
import type { AccountType } from '../us-billing-form-fields/us-billing-form-fields';

export type OnboardingStepBillingInfoProps = {
  goToNextStep: () => void;
};

export type OnboardingStepBillingInfoForm = {
  fiscalCountryCode?: string;
} & Address &
  LegalInfoFormData &
  TaxFormData;

export type OnboardingStepBillingInfoUs = {
  notHaveUSEntity: boolean;
  accountType: AccountType;
  accountNumber: string;
  beneficiaryName: string;
  routingCode: string;
  swiftCode: string;
} & Address &
  IbanFormData;

export type OnboardingStepBankingInfoForm = { iban: string };

export const OnboardingStepBillingInfo: React.FC<
  OnboardingStepBillingInfoProps
> = ({ goToNextStep }: OnboardingStepBillingInfoProps) => {
  const brand = useRecoilValue(brandState);
  const subSteps =
    brand?.store === StoreRegion.Fr
      ? [BillingHandler, BankingInfoHandler, BillingBankingInfoSummary]
      : [BillingHandler, BillingHandlerUs, BillingBankingInfoSummary];

  const navigate = useNavigate();
  const params = useParams<BrandMatchWithSubStep>();

  const [subStep, setSubStep] = useState(
    getCorrectStep(params.substep, subSteps.length - 1)
  );
  const [onEditMode, setOnEditMode] = useState(false);

  useUpdateEffect(() => {
    setSubStep(getCorrectStep(params.substep, subSteps.length - 1));
  }, [params.substep]);

  const changeUrl = (id: string, step: string, subStep: number) => {
    navigate(`/${id}/onboarding/${step}/${subStep}${location.search}`);
  };

  const goToNextSubStepOrStep = () => {
    if (subStep === subSteps.length - 1) {
      goToNextStep();
    } else if (onEditMode) {
      changeUrl(params.brandId ?? '', params.step ?? '', subStep + 2);
      setSubStep(subStep + 2);
      setOnEditMode(false);
    } else {
      changeUrl(params.brandId ?? '', params.step ?? '', subStep + 1);
      setSubStep(subStep + 1);
    }
  };

  const goToBankingInfoStep = () => {
    changeUrl(params.brandId ?? '', params.step ?? '', 1);
    setSubStep(1);
    track('EditButtonClick', {});
  };

  const goToBillingInfoStep = () => {
    changeUrl(params.brandId ?? '', params.step ?? '', 0);
    setOnEditMode(true);
    setSubStep(0);
    track('EditButtonClick', {});
  };

  const goToIbanStep = () => {
    changeUrl(params.brandId ?? '', params.step ?? '', 1);
    setSubStep(1);
    track('EditButtonClick', {});
  };

  const Step = subSteps[subStep] as React.ElementType<
    | BillingHandlerProps
    | BankingInfoHandlerProps
    | BillingBankingInfoSummaryProps
  >;

  return (
    <section className="w-full">
      <Step
        goToNextStep={goToNextStep}
        goToNextSubStepOrStep={goToNextSubStepOrStep}
        onEditBankingInfo={goToBankingInfoStep}
        onEditBillingInfo={goToBillingInfoStep}
        onEditIban={goToIbanStep}
      />
    </section>
  );
};

OnboardingStepBillingInfo.displayName = 'OnboardingStepBillingInfo';
