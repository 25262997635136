/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import type { Address } from '@appchoose/address';
import Button from '@appchoose/button';
import { Form } from '@appchoose/form';

import { brandState } from '../../stores/brand';
import { StoreRegion, useUpdateSellerMutation } from '../../types/generated';
import { IbanFormFields } from '../brand-info/iban-form-fields';
import type { OnboardingStepBillingInfoUs } from '../onboarding/onboarding-step-billing-info';
import {
  AccountType,
  UsBillingFormFields,
  accountTypeOptions,
} from '../us-billing-form-fields/us-billing-form-fields';
import type { BillingHandlerProps } from './billing-handler';

export const BillingHandlerUs: React.FC<BillingHandlerProps> = ({
  goToNextStep,
  goToNextSubStepOrStep,
}: BillingHandlerProps) => {
  const { t } = useTranslation();
  const [brand, setBrand] = useRecoilState(brandState);
  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const form = useForm<OnboardingStepBillingInfoUs>({
    mode: 'onTouched',
    defaultValues: {
      notHaveUSEntity: brand?.store === StoreRegion.Us && !!brand?.iban,
      iban: brand?.iban ?? '',
      accountNumber: brand?.account_number ?? '',
      beneficiaryName: brand?.beneficiary_name ?? '',
      accountType:
        brand?.routing_code && !brand.swift_code
          ? accountTypeOptions[1].value
          : accountTypeOptions[0].value,
      routingCode: brand?.routing_code ?? '',
      swiftCode: brand?.swift_code ?? '',
      name: brand?.bank_address?.name ?? '',
      street: brand?.bank_address?.street ?? '',
      street2: brand?.bank_address?.street2 ?? '',
      bp: brand?.bank_address?.bp ?? '',
      city: brand?.bank_address?.city ?? '',
      province: brand?.bank_address?.province ?? '',
      country: brand?.bank_address?.country ?? '',
      countryCode: brand?.bank_address?.countryCode ?? '',
    },
  });

  const onSubmit = (data: OnboardingStepBillingInfoUs) => {
    if (!brand) return;

    let swiftCode = '';
    let routingCode = '';
    let bankAddress: Address | undefined = undefined;

    if (data.accountType === AccountType.Swift) {
      swiftCode = data.swiftCode;
    } else {
      routingCode = data.routingCode;
      bankAddress = {
        name: data.name?.trim(),
        street: data.street.trim(),
        street2: data.street2?.trim(),
        bp: data.bp.trim(),
        province: data.province?.trim(),
        city: data.city.trim(),
        country: data.country,
        countryCode: data.countryCode,
      };
    }

    setBrand({
      ...brand,
      iban: data.notHaveUSEntity ? data.iban : '',
      beneficiary_name: data.beneficiaryName,
      account_number: data.accountNumber,
      swift_code: swiftCode,
      routing_code: routingCode,
      bank_address: bankAddress,
    });

    updateSellerMutation({
      updateSeller: {
        beneficiary_name: data.beneficiaryName,
        account_number: data.accountNumber,
        routing_code: routingCode,
        swift_code: swiftCode,
        bank_address: bankAddress,
        iban: data.iban,
      },
    });
    goToNextSubStepOrStep();
  };

  form.watch('notHaveUSEntity');

  return (
    <div className="mt-8 sm:mt-20">
      <h2 className="mb-8 text-2xl font-bold sm:mb-10 sm:text-3.5xl">
        {t('onboarding.billing.sub_steps.1.title_us')}
      </h2>
      <Form {...form}>
        <form className="space-y-14" onSubmit={form.handleSubmit(onSubmit)}>
          {!form.getValues('notHaveUSEntity') ? (
            <UsBillingFormFields autoFocus={false} />
          ) : (
            <IbanFormFields countryCode="" autoFocus />
          )}
          <div className="flex flex-col space-y-6 sm:mt-10">
            <div className="flex justify-between">
              <Button type="submit" size="large">
                {t('continue')}
              </Button>
              <Button
                appearance="outline"
                type="button"
                size="large"
                onClick={goToNextStep}
              >
                {t('skip_this_step')}
              </Button>
            </div>
            <div>
              {!form.getValues('notHaveUSEntity') ? (
                <button
                  type="button"
                  className="text-sm font-semibold text-green-900"
                  onClick={() => form.setValue('notHaveUSEntity', true)}
                >
                  {t('onboarding.billing.sub_steps.1.has_us_entity.no')}
                </button>
              ) : (
                <button
                  type="button"
                  className="text-sm font-semibold text-green-900"
                  onClick={() => form.setValue('notHaveUSEntity', false)}
                >
                  {t('onboarding.billing.sub_steps.1.has_us_entity.yes')}
                </button>
              )}
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

BillingHandlerUs.displayName = 'BillingHandlerUs';
