import type React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import type { BrandMatch } from '../../types/navigation';
import { isScopeAuthorized } from '../../utils/auth';

type ProtectedRouteWithScopesProps = {
  scope: string;
  children: React.ReactElement;
};

export const ProtectedRouteWithScopes: React.FC<
  ProtectedRouteWithScopesProps
> = ({ scope, children }: ProtectedRouteWithScopesProps) => {
  const { user } = useAuth0();

  const navigate = useNavigate();
  const { brandId = '' } = useParams<BrandMatch>();

  if (!isScopeAuthorized(user, scope))
    navigate(`/${brandId}/home`, { replace: true });

  return children;
};

ProtectedRouteWithScopes.displayName = 'ProtectedRouteWithScopes';
