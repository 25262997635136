import * as React from 'react';

import cn from '@appchoose/cn';

import './selector-element.scss';

type SelectorElementProps = {
  selected: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
};

export default class SelectorElement extends React.PureComponent<SelectorElementProps> {
  get getSelected() {
    if (this.props.selected) {
      return <div className="circle active" />;
    }
    return <div className="circle inactive" />;
  }

  render() {
    return (
      <div
        className={cn('selector-element', {
          disabled: this.props.disabled,
        })}
      >
        {this.getSelected}
        {this.props.children}
      </div>
    );
  }
}
