import { useInfoQuery } from '../../types/generated-new';

export const Version: React.FC = () => {
  const { data } = useInfoQuery();
  return (
    <dl className="m-6 grid grid-cols-1 gap-x-4 gap-y-8">
      <div>
        <dt className="text-sm font-medium text-gray-500">Backend version</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {data?.info.version ?? 'n/a'}
        </dd>
      </div>

      <div>
        <dt className="text-sm font-medium text-gray-500">
          Backend environment
        </dt>
        <dd className="mt-1 text-sm text-gray-900">
          {data?.info.environment ?? 'n/a'}
        </dd>
      </div>

      <div>
        <dt className="text-sm font-medium text-gray-500">Frontend version</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {import.meta.env.REACT_APP_VERSION ?? 'n/a'}
        </dd>
      </div>
    </dl>
  );
};
