import { useCallback, useRef } from 'react';
import { useEvent } from 'react-use';

export const useFixedHeader = () => {
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLTableSectionElement>(null);

  const scrollableContent = document.getElementsByClassName(
    'useFixedHeader-scrollable-content'
  )[0];

  const onScroll = useCallback(() => {
    if (tableWrapperRef.current && headerRef.current && scrollableContent) {
      const { scrollTop: scroll } = scrollableContent;
      const header = headerRef.current;
      const table = tableWrapperRef.current;

      const headerTop = table.offsetTop;

      if (scroll > headerTop) {
        const yTranslation = Math.floor(Math.abs(scroll - headerTop));
        header.style.setProperty('transform', `translateY(${yTranslation}px)`);
      } else {
        header.style.removeProperty('transform');
      }
    }
  }, [scrollableContent]);

  useEvent('scroll', onScroll, scrollableContent, { passive: true });

  return [tableWrapperRef, headerRef] as const;
};
