import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Checkbox from '@appchoose/checkbox';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabelCheckbox,
} from '@appchoose/form';

import type { ShippingDelayFormData } from '../shipping-delay-form-fields/shipping-delay-form-fields';

export const ShippingDelayAdditionalInfoFormFields: React.FC = () => {
  const { t } = useTranslation();

  const form = useFormContext<ShippingDelayFormData>();

  return (
    <>
      <FormField
        control={form.control}
        name="haveDifferentDeliveryTimeProducts"
        render={({ field }) => (
          <FormItem className="flex space-x-3 space-y-0">
            <FormControl>
              <Checkbox
                {...field}
                value=""
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <FormLabelCheckbox>
              {t(
                'onboarding.shipping.have_different_delivery_time_products.label'
              )}
            </FormLabelCheckbox>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="havePreOrderProducts"
        render={({ field }) => (
          <FormItem className="flex space-x-3 space-y-0">
            <FormControl>
              <Checkbox
                {...field}
                value=""
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <FormLabelCheckbox>
              {t('onboarding.shipping.have_pre_order_products.label')}
            </FormLabelCheckbox>
          </FormItem>
        )}
      />
    </>
  );
};
