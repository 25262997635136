import { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';

import { productsState } from '../stores/product';
import { activeSaleState } from '../stores/sales';
import type {
  ProductStockUpdatedSubscription,
  Variant,
} from '../types/generated';
import { ProductStockUpdatedDocument } from '../types/generated';
import { getSubscriptionClient } from './subscription-client';

export const useProductStockUpdatedSubscription = () => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const activeSale = useRecoilValue(activeSaleState);
  const [products, setProducts] = useRecoilState(productsState);

  // TODO: refactor
  const updateStock = useCallback(
    (stockChanged: ProductStockUpdatedSubscription['productStockUpdated']) => {
      if (stockChanged.productId && products) {
        const product = products.find((p) => p.id === stockChanged.productId);
        if (product) {
          const variant = product.variants
            ?.filter((variant): variant is Variant => !!variant)
            .find((v) => v.id === stockChanged.productVariantId);

          if (variant) {
            setProducts([
              ...products.filter((p) => p.id !== stockChanged.productId),
              {
                ...product,
                variants: [
                  ...(product.variants
                    ?.filter((variant): variant is Variant => !!variant)
                    .filter((v) => v.id !== stockChanged.productVariantId) ??
                    []),
                  {
                    ...variant,
                    stock: stockChanged.stock,
                  },
                ],
              },
            ]);
          }
        }
      }
    },
    [products, setProducts]
  );

  useEffect(() => {
    const client = getSubscriptionClient(getAccessTokenSilently);
    const subscribe = () => {
      client
        .request({
          query: ProductStockUpdatedDocument,
        })
        .subscribe({
          next(response) {
            if (!activeSale) return;

            const data = response.data as ProductStockUpdatedSubscription;

            if (!data.productStockUpdated) return;

            updateStock(data.productStockUpdated);
          },
        });
    };
    subscribe();
    return () => {
      client.close();
    };
  }, [queryClient, activeSale, products, getAccessTokenSilently, updateStock]);
};
