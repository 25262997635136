import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import Button from '@appchoose/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Input from '@appchoose/input';
import type { Auth0Error } from 'auth0-js';
import Mailchecker from 'mailchecker';

import { Google } from '../../components/icons/google/google';
import { Microsoft } from '../../components/icons/microsoft/microsoft';
import { XChooseHeader } from '../../components/x-choose-header/x-choose-header';
import type { BrandMatch } from '../../types/navigation';
import { authorizeGoogle, authorizeMicrosoft, login } from '../../utils/auth';

type LoginWithPasswordForm = {
  email: string;
  password: string;
};

export const LoginWithPassword: React.FC = () => {
  const { search } = useLocation();
  const { brandId = '' } = useParams<BrandMatch>();
  const { t } = useTranslation();

  const form = useForm<LoginWithPasswordForm>({
    mode: 'onTouched',
    defaultValues: {
      email: decodeURIComponent(new URLSearchParams(search).get('email') ?? ''),
    },
  });

  const [serverError, setServerError] = useState<string | undefined>(undefined);

  useUpdateEffect(() => {
    form.trigger();
  }, [serverError]);

  const onSubmit = async (data: LoginWithPasswordForm) => {
    setServerError(undefined);

    try {
      await login({
        email: data.email,
        password: data.password,
        redirectUri: `${window.location.origin}/login-successful`,
      });
    } catch (error) {
      switch ((error as Auth0Error).error) {
        case 'invalid_user_password':
        case 'access_denied':
          setServerError(
            t('auth.errors.auth_login_password.invalid_credentials')
          );
          break;
        case 'too_many_attempts':
          setServerError(
            t('auth.errors.auth_login_password.too_many_attempts', {
              x: '10',
            })
          );
          break;
        case 'unauthorized':
          setServerError(t('auth.errors.auth_login_password.unauthorized'));
          break;
        default:
          setServerError(t('auth.errors.auth_login_password.generic_error'));
          break;
      }
    }
  };

  form.watch('email');

  const queryParams = new URLSearchParams(search);
  if (form.getValues('email')) {
    queryParams.set('email', form.getValues('email'));
  }

  return (
    <div className="mx-auto w-full px-4 py-10 sm:max-w-4.5xl sm:px-6">
      <XChooseHeader />
      <main className="mx-2 mt-10 sm:mx-8 sm:mt-20 md:mx-24">
        <h1 className="mb-10 text-3.5xl font-bold">
          {t('auth.login.connect')}
        </h1>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-10">
            <div className="space-y-4">
              <Button
                type="button"
                appearance="primary"
                onClick={() =>
                  authorizeGoogle({
                    redirectUri: `${window.location.origin}/login-successful`,
                  })
                }
                className="w-full justify-center text-base font-bold"
              >
                <Google className="my-1.5 mr-2" />
                {t('auth.continue_with', { name: 'Google' })}
              </Button>
              <Button
                type="button"
                appearance="primary"
                onClick={() =>
                  authorizeMicrosoft({
                    redirectUri: `${window.location.origin}/login-successful`,
                  })
                }
                className="w-full justify-center text-base font-bold"
              >
                <Microsoft className="my-1.5 mr-2" />
                {t('auth.continue_with', { name: 'Microsoft' })}
              </Button>
            </div>
            <div className="flex items-center space-x-2">
              <div className="h-px w-full bg-gray-500"></div>
              <div className="flex justify-center text-sm font-semibold uppercase text-gray-500">
                {t('or')}
              </div>
              <div className="h-px w-full bg-gray-500"></div>
            </div>
            <div className="space-y-6">
              <FormField
                control={form.control}
                name="email"
                rules={{
                  required: true,
                  maxLength: 50,
                  validate: {
                    email: (value) => Mailchecker.isValid(value),
                    server: () => serverError === undefined,
                  },
                }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('auth.fields.email.label')}</FormLabel>
                    <FormControl>
                      <Input
                        type="email"
                        placeholder="example@gmail.com"
                        autoComplete="email"
                        inputMode="email"
                        {...field}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          if (serverError !== undefined) {
                            setServerError(undefined);
                          }
                          const { value } = event.target;
                          event.target.value = value.trim();
                          field.onChange(event);
                        }}
                        autoFocus
                      />
                    </FormControl>
                    <FormMessage match="required">
                      {t(
                        'brand_info.contact_form_fields.email.validation_errors.required'
                      )}
                    </FormMessage>
                    <FormMessage match="maxLength">
                      {t(
                        'brand_info.contact_form_fields.email.validation_errors.maxLength',
                        {
                          maxLength: '50',
                        }
                      )}
                    </FormMessage>
                    <FormMessage match="email">
                      {t(
                        'brand_info.contact_form_fields.email.validation_errors.email'
                      )}
                    </FormMessage>
                    <FormMessage match="server">
                      <span className="sr-only"></span>
                    </FormMessage>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="password"
                rules={{
                  required: true,
                  validate: {
                    server: () => serverError === undefined,
                  },
                }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('auth.fields.password.label')}</FormLabel>
                    <FormControl>
                      <Input
                        type="password"
                        autoComplete="current-password"
                        {...field}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          if (serverError !== undefined) {
                            setServerError(undefined);
                          }
                          field.onChange(event);
                        }}
                      />
                    </FormControl>
                    <FormMessage match="required">
                      {t('auth.fields.password.validation_errors.required')}
                    </FormMessage>
                    <FormMessage match="server">{serverError}</FormMessage>
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-6">
              <Button type="submit" appearance="primary" size="large">
                {t('continue')}
              </Button>
              <NavLink
                to={`/${brandId}/forgot-password?${queryParams.toString()}`}
                className="flex text-sm font-semibold text-green-900"
              >
                {t('auth.login.forgot_password')}
              </NavLink>
            </div>
          </form>
        </Form>
      </main>
    </div>
  );
};

LoginWithPassword.displayName = 'LoginWithPassword';
