import { useTranslation } from 'react-i18next';

import Icon from '@appchoose/icon';
import { ModalClose } from '@appchoose/modal';

import type { OrderNavigation } from './modal-order-order-navigation';
import { ModalOrderOrderNavigation } from './modal-order-order-navigation';

type ModalOrderMobileHeaderProps = {
  brandId: string;
} & OrderNavigation;

export const ModalOrderMobileHeader: React.FC<ModalOrderMobileHeaderProps> = ({
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className="sticky top-0 z-10 flex justify-between border-b border-gray-300 bg-white px-4 pb-4 lg:hidden">
      <ModalClose asChild>
        <button
          type="button"
          className="flex w-full items-center focus-visible:ring-2 focus-visible:ring-gray-900/30"
        >
          <Icon icon="back" size="large" className="mr-2" />
          <p>{t('rating.open_order')}</p>
        </button>
      </ModalClose>
      <ModalOrderOrderNavigation {...props} />
    </div>
  );
};
