import React from 'react';

import Icon from '@appchoose/icon';
import i18n from 'i18next';

import type { RateCountAggByRate } from '../../types/generated';
import { formatNumber, formatPercentage } from '../../utils/number';

type StatsRatioProps = {
  sum_rate: number;
  total_rate: number;
  onClick: (rate: number) => void;
  rates: RateCountAggByRate[];
};

export const StatsRatio: React.FC<StatsRatioProps> = ({
  sum_rate,
  total_rate,
  onClick,
  rates,
}) => {
  const getRateProportion = (rate: number) => {
    if (rates) {
      const _rate = rates.find((__rate) => rate === __rate.rate + 1);
      if (_rate) {
        return +_rate.count || 0;
      }
      return 0;
    }
    return 0;
  };

  const getRatio = (limit: number) => {
    const rate = sum_rate - 1;
    if (rate >= limit) {
      return '100%';
    }
    const diff = limit - rate;
    if (diff < 1) {
      return `${(1 - diff) * 100}%`;
    }
    return '0%';
  };

  return (
    <div className="mb-8">
      <div className="flex flex-col items-center space-y-8 md:flex-row md:space-x-8 md:space-y-0">
        <div className="flex w-full flex-col items-center rounded-lg border border-beige-400 bg-beige-300 px-[52px] py-6 lg:w-auto">
          <p className="text-center text-[120px] font-bold leading-[120px] text-gray-900">
            {formatNumber(sum_rate, i18n.language === 'fr' ? 'fr' : 'en', {
              maximumFractionDigits: 1,
            })}
          </p>
          <ul className="flex flex-row space-x-0.75">
            <li className="relative">
              <Icon icon="star" size="large" className="text-beige-400" />
              <div
                className="absolute left-0 top-0"
                style={{ width: getRatio(0), overflow: 'hidden' }}
              >
                <Icon
                  icon="starFilled"
                  size="large"
                  className="text-green-900"
                />
              </div>
            </li>
            <li className="relative">
              <Icon icon="star" size="large" className="text-beige-400" />
              <div
                className="absolute left-0 top-0"
                style={{ width: getRatio(1), overflow: 'hidden' }}
              >
                <Icon
                  icon="starFilled"
                  size="large"
                  className="text-green-900"
                />
              </div>
            </li>
            <li className="relative">
              <Icon icon="star" size="large" className="text-beige-400" />
              <div
                className="absolute left-0 top-0"
                style={{ width: getRatio(2), overflow: 'hidden' }}
              >
                <Icon
                  icon="starFilled"
                  size="large"
                  className="text-green-900"
                />
              </div>
            </li>
            <li className="relative">
              <Icon icon="star" size="large" className="text-beige-400" />
              <div
                className="absolute left-0 top-0"
                style={{ width: getRatio(3), overflow: 'hidden' }}
              >
                <Icon
                  icon="starFilled"
                  size="large"
                  className="text-green-900"
                />
              </div>
            </li>
            <li className="relative">
              <Icon icon="star" size="large" className="text-beige-400" />
              <div
                className="absolute left-0 top-0"
                style={{ width: getRatio(4), overflow: 'hidden' }}
              >
                <Icon
                  icon="starFilled"
                  size="large"
                  className="text-green-900"
                />
              </div>
            </li>
          </ul>
          {total_rate > 0 ? (
            <span className="mx-auto mt-4 text-center text-xs font-semibold uppercase tracking-wider text-[#ab9773]">
              {i18n.t('on')} {total_rate} {i18n.t('analytics.rating')}
              {total_rate > 1 ? 's' : ''}
            </span>
          ) : null}
        </div>

        <ul className="table w-full border-separate border-spacing-y-2 space-y-2 lg:max-w-[430px]">
          {[5, 4, 3, 2, 1].map((entry) => (
            <li
              key={entry}
              onClick={() => onClick(entry)}
              className="group table-row cursor-pointer transition-colors hover:bg-green-300 aria-disabled:pointer-events-none"
              aria-disabled={getRateProportion(entry) === 0}
            >
              <div className="table-cell rounded-l px-2 py-1 font-bold leading-5.5 text-green-400">
                <div className="flex items-center justify-between space-x-2">
                  <span>{entry}</span>
                  <Icon icon="starFilled" className="text-green-100" />
                </div>
              </div>
              <div className="table-cell w-full px-2 py-1 align-middle">
                <div className="flex h-1 flex-1 overflow-hidden rounded-[1px] bg-gray-50 group-hover:bg-white">
                  <div
                    role="progressbar"
                    className="ease flex justify-center overflow-hidden rounded-[1px] bg-green-900 text-center"
                    style={{
                      width: `${(getRateProportion(entry) / total_rate) * 100}%`,
                    }}
                  />
                </div>
              </div>
              <div className="table-cell px-2 py-1 font-bold leading-5.5 text-green-900 group-aria-disabled:text-gray-300">
                {getRateProportion(entry)}
              </div>
              <div className="table-cell rounded-r px-2 py-1 text-right leading-5.5 text-gray-500 group-aria-disabled:text-gray-300">
                {formatPercentage(
                  getRateProportion(entry) / total_rate,
                  i18n.language === 'fr' ? 'fr' : 'en',
                  {
                    maximumFractionDigits: 0,
                  }
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
