/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useSessionStorage } from 'react-use';

import Button from '@appchoose/button';
import cn from '@appchoose/cn';
import type { FlagType } from '@appchoose/flag';
import Flag from '@appchoose/flag';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@appchoose/form';
import Icon from '@appchoose/icon';
import Input from '@appchoose/input';
import Label from '@appchoose/label';
import { Modal, ModalContent, ModalTrigger } from '@appchoose/modal';
import RadioGroup, { RadioGroupItem } from '@appchoose/radio-group';
import { useAuth0 } from '@auth0/auth0-react';
import { addDays, compareAsc } from 'date-fns';

import { getI18nLanguageFromISOLanguage } from '../../lang/i18n';
import type { BrandAccess } from '../../types/generated-new';
import {
  useBrandsAccessQuery,
  useEditBasicUserInfoMutation,
  useUpdateUserLanguageMutation,
} from '../../types/generated-new';
import type { BrandMatch } from '../../types/navigation';
import { PersonalNameFormFields } from '../personal-name-form-fields/personal-name-form-fields';
import { BrandView } from '../settings-account/brand-view';
import { ModalChangePassword } from '../settings-account/modal-change-password';
import { UploadPictureForm } from '../upload-picture-form/upload-picture-form';

type ProfileInfosProps = {
  onPostSave: () => void;
};

export type SettingsAccountFormData = {
  email: string;
  firstName: string;
  lastName: string;
  language: string;
};

export const ProfileInfos: React.FC<ProfileInfosProps> = ({
  onPostSave,
}: ProfileInfosProps) => {
  const { brandId } = useParams<BrandMatch>();
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const location = useLocation();
  const { i18n, t } = useTranslation();

  const [, setRedirectUri] = useSessionStorage<string>('redirectUri');
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(
    location.hash === '#modal-change-password' ? true : false
  );
  const { data: brandAccess } = useBrandsAccessQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  const [showPassword] = useState(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    user?.['https://appchoose.io/claims/listConnection'].includes('auth0')
  );

  const availableLanguages = [
    { name: 'Français', value: 'fr', flag: 'FR' },
    { name: 'English', value: 'en', flag: 'GB' },
  ];
  const availableLanguagesOptions = availableLanguages.map((language) => ({
    flag: language.flag as FlagType,
    name: language.name,
    value: language.value,
  }));

  const {
    mutateAsync: editBasicUserInfoMutation,
    isPending: editBasicUserInfoLoading,
  } = useEditBasicUserInfoMutation();
  const {
    mutateAsync: updateUserLanguageMutation,
    isPending: updateUserLanguageLoading,
  } = useUpdateUserLanguageMutation();

  const form = useForm<SettingsAccountFormData>({
    mode: 'onTouched',
    defaultValues: {
      firstName:
        user?.['https://appchoose.io/claims/firstname'] ??
        user?.given_name ??
        '',
      lastName:
        user?.['https://appchoose.io/claims/lastname'] ??
        user?.family_name ??
        '',
      email: user?.email ?? '',
      language: getI18nLanguageFromISOLanguage(
        (user?.['https://appchoose.io/claims/preferredLanguage'] as
          | string
          | undefined) ?? navigator.language
      ),
    },
  });

  const onSubmit = async (data: SettingsAccountFormData) => {
    if (data.language !== i18n.language) i18n.changeLanguage(data.language);

    await editBasicUserInfoMutation({
      basicUserInfo: {
        firstname: data.firstName,
        lastname: data.lastName,
      },
    });

    await updateUserLanguageMutation({
      preferredLanguage: data.language === 'fr' ? 'fr-FR' : 'en-US',
    });

    await getAccessTokenSilently({ cacheMode: 'off' });

    onPostSave();
  };

  const openChangePasswordModal: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    const lastLogin =
      (user?.['https://appchoose.io/claims/lastLogin'] as number | undefined) ??
      Date.now();

    if (compareAsc(addDays(new Date(lastLogin), 7), new Date()) < 0) {
      setRedirectUri(
        `${location.pathname}/${location.search}#modal-change-password`
      );
      logout({
        logoutParams: {
          returnTo: `${window.location.origin}/logout-successful?change-password=true`,
        },
      });
    } else {
      setIsChangePasswordModalOpen(true);
    }
  };

  return (
    <>
      <div className="mb-6 space-y-14">
        <div className="mb-12 space-y-8">
          <h3 className="text-2xl font-bold">
            {t('settings.account_tabs.details')}
          </h3>
          <div className="space-y-6">
            <UploadPictureForm />
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-8"
              >
                <div className="space-y-6">
                  <div className="grid grid-cols-1 space-y-6 sm:grid-cols-2 sm:gap-x-6 sm:space-y-0">
                    <PersonalNameFormFields />
                  </div>
                  <FormField
                    control={form.control}
                    name="email"
                    disabled
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          {t('brand_info.contact_form_fields.email.label')}
                        </FormLabel>

                        <FormControl>
                          <Input placeholder="example@gmail.com" {...field} />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>
                <div>
                  <FormField
                    control={form.control}
                    name="language"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          {t('settings.account_tabs.language')}
                        </FormLabel>
                        <FormControl>
                          <RadioGroup
                            className="space-y-2 sm:flex sm:space-x-12 sm:space-y-0"
                            {...field}
                            onValueChange={(value) => field.onChange(value)}
                          >
                            {availableLanguagesOptions.map((option) => (
                              <div
                                className="group flex items-center space-x-3"
                                key={option.value}
                              >
                                <RadioGroupItem
                                  key={option.value}
                                  value={option.value}
                                  id={`language-option-${option.value}`}
                                />

                                <Label
                                  htmlFor={`language-option-${option.value}`}
                                  className="flex cursor-pointer items-center space-x-1 text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                                >
                                  <Flag flag={option.flag} />
                                  <span>{option.name}</span>
                                </Label>
                              </div>
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>
                <Button
                  type="submit"
                  disabled={
                    editBasicUserInfoLoading || updateUserLanguageLoading
                  }
                >
                  {t('save')}
                </Button>
              </form>
            </Form>
          </div>
        </div>
        {showPassword ? (
          <div className="space-y-8">
            <h3 className="text-2xl font-bold text-gray-900">
              {t('settings.account_tabs.password')}
            </h3>

            <Modal
              open={isChangePasswordModalOpen}
              onOpenChange={setIsChangePasswordModalOpen}
            >
              <ModalTrigger asChild>
                <Button type="button" onClick={openChangePasswordModal}>
                  {t('settings.account_tabs.change_password.title')}
                </Button>
              </ModalTrigger>
              <ModalContent scrollable>
                <ModalChangePassword
                  handleChangePasswordModal={setIsChangePasswordModalOpen}
                />
              </ModalContent>
            </Modal>
          </div>
        ) : null}
      </div>
      <button
        type="button"
        onClick={() =>
          logout({
            logoutParams: {
              returnTo: `${window.location.origin}/logout-successful`,
            },
          })
        }
        className="text-xs font-semibold text-green-900"
      >
        {t('auth.logout')}
      </button>
      {brandAccess && brandAccess.getBrandsAccess.length > 0 ? (
        <>
          <h3 className="mb-8 mt-14 text-2xl font-bold">
            {t('settings.account_tabs.brand_list')}
          </h3>
          <div className="space-y-2">
            {brandAccess.getBrandsAccess
              .filter((brand) => brand?.brandId === brandId)
              .concat(
                brandAccess.getBrandsAccess
                  .filter((brand) => brand?.brandId !== brandId)
                  .sort((a, b) => {
                    if (a && b) {
                      if (a.brandName < b.brandName) {
                        return -1;
                      }
                      if (a.brandName > b.brandName) {
                        return 1;
                      }
                    }
                    return 0;
                  })
              )
              .filter(
                (brandAccess): brandAccess is BrandAccess => !!brandAccess
              )
              .map((brand, idx) => (
                <div
                  key={idx}
                  className={cn('rounded-lg border border-gray-300', {
                    'bg-gray-50': brand.brandId === brandId,
                  })}
                >
                  {brand.brandId === brandId ? (
                    <div className="flex items-center justify-between space-x-6 px-4 py-6">
                      <div className="truncate font-semibold text-gray-700">
                        {brand.brandName}
                      </div>
                      <div className="flex items-center space-x-1.5">
                        <Icon className="text-gray-700" icon="check" />
                        <div className="text-sm text-gray-700">
                          {t('settings.account_tabs.selected')}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <BrandView name={brand.brandName} id={brand.brandId} />
                  )}
                </div>
              ))}
          </div>
        </>
      ) : null}
    </>
  );
};
