import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Icon from '@appchoose/icon';
import Input, { InputAdornment } from '@appchoose/input';
import Loader from '@appchoose/loader';
import type { Auth0Error } from 'auth0-js';

import { passwordlessLogin } from '../../utils/auth';
import { ExpirationDelay } from './expiration-delay';

type ConfirmPasswordlessCodeForm = {
  code: string;
};

export const ConfirmPasswordlessCodeForm: React.FC = () => {
  const { t } = useTranslation();
  const form = useForm<ConfirmPasswordlessCodeForm>({
    mode: 'onTouched',
  });

  const { search } = useLocation();
  const [email] = useState(
    decodeURIComponent(new URLSearchParams(search).get('email') ?? '')
  );
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState<string | undefined>(undefined);

  useUpdateEffect(() => {
    form.trigger();
  }, [serverError]);

  const onSubmit = async (data: ConfirmPasswordlessCodeForm) => {
    setIsLoading(true);

    try {
      await passwordlessLogin({
        email: email,
        verificationCode: data.code,
        redirectUri: `${window.location.origin}/login-successful`,
      });
    } catch (error) {
      switch ((error as Auth0Error).description) {
        case 'Wrong email or verification code.':
          setServerError(
            t('auth.errors.auth_passwordless_code.invalid_credentials')
          );
          break;
        case "You've reached the maximum number of attempts. Please try to login again.":
          setServerError(
            t('auth.errors.auth_passwordless_code.too_many_attempts')
          );
          break;
        case 'The verification code has expired. Please try to login again.':
          setServerError(
            t('auth.errors.auth_passwordless_code.verification_code_expired')
          );
          break;
        default:
          setServerError(t('auth.errors.auth_passwordless_code.generic_error'));
          break;
      }
    }

    setIsLoading(false);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col space-y-10"
      >
        <p className="m-0 text-base leading-5.5 text-gray-700">
          {t('auth.confirm_passwordless_code.6_digits_code_sent_to')}{' '}
          <span className="font-bold">{email}</span>.<br />
          <ExpirationDelay email={email} />
        </p>
        <FormField
          control={form.control}
          name="code"
          rules={{
            required: true,
            maxLength: 6,
            validate: {
              server: () => serverError === undefined,
            },
          }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('auth.fields.confirmation_code.label')}</FormLabel>
              <FormControl>
                <Input
                  type="number"
                  placeholder="123456"
                  autoComplete="one-time-code"
                  inputMode="numeric"
                  endAdornment={
                    isLoading ? (
                      <InputAdornment position="end">
                        <Loader className="size-4" />
                      </InputAdornment>
                    ) : null
                  }
                  className="hide-arrows"
                  {...field}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (serverError !== undefined) {
                      setServerError(undefined);
                    }
                    const { value } = event.target;
                    event.target.value = value.trim();
                    field.onChange(event);
                    if (value.length === 6) {
                      form.handleSubmit(onSubmit)();
                    }
                  }}
                  autoFocus
                />
              </FormControl>
              <FormMessage match="required">
                {t('auth.fields.confirmation_code.validation_errors.required')}
              </FormMessage>
              <FormMessage match="maxLength">
                {t(
                  'auth.fields.confirmation_code.validation_errors.maxLength',
                  {
                    maxLength: '6',
                  }
                )}
              </FormMessage>
              <FormMessage match="server">{serverError}</FormMessage>
            </FormItem>
          )}
        />
        <div className="flex flex-initial space-x-4 self-start rounded-lg border border-gray-300 bg-gray-50 p-6 pr-8">
          <Icon icon="help" size="large" className="text-gray-700" />
          <div className="space-y-1">
            <p className="m-0 text-sm font-semibold text-gray-700">
              {t('auth.confirm_passwordless_code.cant_find_your_code')}
            </p>
            <p className="m-0 text-sm leading-5.5 text-gray-700">
              {t('auth.confirm_passwordless_code.verify_spam_folder')}
            </p>
          </div>
        </div>
      </form>
    </Form>
  );
};

ConfirmPasswordlessCodeForm.displayName = 'ConfirmPasswordlessCodeForm';
