import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';

import { getFormattedAddress } from '@appchoose/address';
import AddressView from '@appchoose/address-view';
import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import Tooltip from '@appchoose/tooltip';
import * as Sentry from '@sentry/react';

import Chronopost from '../../assets/img/pickup_services/Chronopost.svg';
import Colissimo from '../../assets/img/pickup_services/Colissimo.svg';
import MondialRelay from '../../assets/img/pickup_services/MondialRelay.svg';
import type { OrderQuery, PickupAddress } from '../../types/generated-new';
import { PickupPointNetwork } from '../../types/generated-new';
import { track } from '../../utils/analytics';
import { capitalizeFirstLetter } from '../../utils/string';

type ModalOrderPickupPointProps = {
  order: OrderQuery['order'];
};

export const ModalOrderPickupPoint: React.FC<ModalOrderPickupPointProps> = ({
  order,
}: ModalOrderPickupPointProps) => {
  const { t, i18n } = useTranslation();

  const [pickupPoint] = useState(order.shipping.pickupPoint);

  const [pickupIdClipboardState, copyPickupIdToClipboard] =
    useCopyToClipboard();
  const [addressClipboardState, copyAddressToClipboard] = useCopyToClipboard();

  useEffect(() => {
    const { value, error } = pickupIdClipboardState;
    if (value) {
      toast.success(t('order.copy_pickup_id_feedback'));
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [pickupIdClipboardState, t]);

  useEffect(() => {
    const { value, error } = addressClipboardState;
    if (value) {
      toast.success(t('order.copy_address_feedback'));
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [addressClipboardState, t]);

  if (!pickupPoint) return null;

  const onCopyPickupId = (pickupId: string) => {
    copyPickupIdToClipboard(pickupId);
    track('CopyToClipboard', {
      label: 'pickup_id',
    });
  };

  const onCopyAddress = async (address: PickupAddress) => {
    let stringToCopy = '';
    await getFormattedAddress(
      {
        ...address,
        company: address.name,
        zip: address.zipCode,
        address1: address.street,
        address2: address.streetAdditional ?? '',
        country: address.countryCode,
      },
      i18n.language === 'fr' ? 'FR' : 'EN'
    ).then((address) => {
      address.forEach((row) => (stringToCopy += `${row}\n`));
    });
    copyAddressToClipboard(stringToCopy);
    track('CopyToClipboard', {
      label: 'pickup_address',
    });
  };

  return (
    <>
      {pickupPoint.network ? (
        <section className="space-y-2">
          <h4 className="text-xs font-semibold uppercase tracking-wider text-gray-500">
            {t('pickup_point.service_provider')}
          </h4>
          <p className="flex items-center gap-x-1">
            {pickupPoint.network === PickupPointNetwork.MondialRelay ? (
              <img src={MondialRelay} />
            ) : null}
            {pickupPoint.network === PickupPointNetwork.Colissimo ? (
              <img src={Colissimo} />
            ) : null}
            {pickupPoint.network === PickupPointNetwork.Shop2Shop ? (
              <img src={Chronopost} />
            ) : null}
            <span className="text-sm text-gray-700">
              {pickupPoint.network === PickupPointNetwork.Shop2Shop
                ? 'Shop2Shop by Chronopost'
                : pickupPoint.network
                    .split('_')
                    .map((string) =>
                      capitalizeFirstLetter(string.toLowerCase())
                    )
                    .join(' ')}
            </span>
          </p>
        </section>
      ) : null}
      {pickupPoint.id ? (
        <section className="space-y-2">
          <h4 className="mb-1 text-xs font-semibold uppercase tracking-wider text-gray-500">
            {t('pickup_point.pickup_point_id')}
          </h4>
          <div className="flex items-start justify-between">
            <p className="text-sm text-gray-700">{pickupPoint.id}</p>
            <Tooltip
              content={
                <div className="px-1.5 py-3">
                  {t('order.copy_pickup_id_tooltip')}
                </div>
              }
              placement="left"
            >
              <button
                onClick={() => onCopyPickupId(pickupPoint.id)}
                type="button"
                className="rounded pt-1 transition-colors hover:text-gray-700 focus-visible:ring-2 focus-visible:ring-gray-900/30"
              >
                <Icon icon="copy" />
              </button>
            </Tooltip>
          </div>
        </section>
      ) : null}
      {pickupPoint.address ? (
        <section className="space-y-2">
          <h4 className="mb-1 text-xs font-semibold uppercase tracking-wider text-gray-500">
            {t('pickup_point.address')}
          </h4>
          <div className="flex items-start justify-between">
            <AddressView
              label={t('address.label')}
              size="medium"
              address={{
                ...pickupPoint.address,
                street2: pickupPoint.address.streetAdditional ?? undefined,
                bp: pickupPoint.address.zipCode,
                country: '',
              }}
              locale={i18n.language === 'fr' ? 'FR' : 'EN'}
            />
            <Tooltip
              content={
                <div className="px-1.5 py-3">
                  {t('order.copy_address_tooltip')}
                </div>
              }
              placement="left"
            >
              <button
                onClick={() =>
                  onCopyAddress(pickupPoint.address as PickupAddress)
                }
                type="button"
                className="rounded pt-1 transition-colors hover:text-gray-700 focus-visible:ring-2 focus-visible:ring-gray-900/30"
              >
                <Icon icon="copy" />
              </button>
            </Tooltip>
          </div>
        </section>
      ) : null}
    </>
  );
};
