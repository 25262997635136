import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import Logo from '@appchoose/logo';

import { brandState } from '../../stores/brand';
import { isSidebarMenuOpenState } from './dashboard-screen';

export const MobileSidebar = () => {
  const { pathname, search } = useLocation();
  const { t } = useTranslation();

  const brand = useRecoilValue(brandState);
  const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useRecoilState(
    isSidebarMenuOpenState
  );

  const path = pathname.split('/')[2] ?? null;
  const orderId = pathname.split('/')[3] ?? null;

  return (
    <section
      className={cn(
        `sticky top-0 z-40 flex flex-row items-center justify-between bg-white p-4 min-[960px]:hidden`,
        {
          'bg-gray-100': isSidebarMenuOpen,
          'z-50': path === 'orders' && orderId,
        }
      )}
    >
      <div className="flex items-center space-x-4">
        <NavLink
          to={`/${brand?.id ?? ''}/home${search}`}
          className="rounded-lg focus-visible:ring-2 focus-visible:ring-gray-900/30"
        >
          {import.meta.env.REACT_APP_STAGE === 'production' ? (
            <Logo role="img" aria-label="Logo Choose" />
          ) : (
            <Logo appearance="white" role="img" aria-label="Logo Choose" />
          )}
        </NavLink>
        <p className="font-semibold leading-5.5 text-gray-900">{brand?.name}</p>
      </div>
      <button
        onClick={() => setIsSidebarMenuOpen(!isSidebarMenuOpen)}
        type="button"
        title={t('menu')}
        className={`rounded-lg focus-visible:ring-2 focus-visible:ring-gray-900/30 ${
          isSidebarMenuOpen ? 'hidden' : ''
        }`}
      >
        <Icon icon="menu" size="large" />
        <span className="sr-only">{t('menu')}</span>
      </button>
      <button
        onClick={() => setIsSidebarMenuOpen(!isSidebarMenuOpen)}
        type="button"
        title={t('close')}
        className={`rounded-lg focus-visible:ring-2 focus-visible:ring-gray-900/30 ${
          isSidebarMenuOpen ? '' : 'hidden'
        }`}
      >
        <Icon icon="close" size="large" />
        <span className="sr-only">{t('close')}</span>
      </button>
    </section>
  );
};
