import { atom } from 'recoil';

import type { Address } from '@appchoose/address';

import type { BrandQuery } from '../types/generated';

export const serverTimeState = atom<Date | undefined>({
  key: 'serverTimeState',
  default: undefined,
});

export type Brand =
  | (Exclude<
      NonNullable<BrandQuery['brand']>,
      'address' | 'bank_address' | 'return_address'
    > & {
      // TODO: Type retrieved from the API - type the address object on the back-end
      billing_address?: Address | null;
      bank_address?: Address | null;
      return_address?: Address | null;
      nbUsers?: number;
    })
  | undefined;

export const brandState = atom<Brand>({
  key: 'brandState',
  default: undefined,
});
