import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import cn from '@appchoose/cn';
import type { Row } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';

import { brandState } from '../../stores/brand';
import type { OrderData } from './order-table';

export const OrderTableRow: React.FC<{
  row: Row<OrderData>;
}> = ({ row }) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const brand = useRecoilValue(brandState);

  return (
    <tr
      key={row.id}
      data-table-row
      data-state={row.getIsSelected() && 'selected'}
      className={cn(
        'group cursor-pointer border-b border-b-gray-100 last:border-none',
        {
          'bg-white': row.original.requiredAction,
          'bg-[#FBFBFB]': !row.original.requiredAction,
        }
      )}
      onClick={() =>
        navigate(`/${brand?.id ?? ''}/orders/${row.original.id}${search}`)
      }
    >
      {/* Table row */}
      {row.getVisibleCells().map((cell) => (
        <td
          key={cell.id}
          data-table-cell
          className={cn(
            'items-center whitespace-nowrap text-sm text-gray-700 group-hover:bg-gray-50',
            {
              'sticky z-[1]': cell.column.getIsPinned(),
              'bg-white':
                cell.column.getIsPinned() && row.original.requiredAction,
              'bg-[#FBFBFB]':
                cell.column.getIsPinned() && !row.original.requiredAction,
            }
          )}
          style={{
            left: cell.column.getIsPinned()
              ? cell.column.getStart()
              : undefined,
            width: cell.column.getIsPinned()
              ? cell.column.getSize()
              : undefined,
          }}
        >
          {/* Table cell */}
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
};
