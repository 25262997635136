import type { User } from '@auth0/auth0-react';

export const hasBrandAccess = (user: User | undefined, brandId: string) => {
  if (!user?.['https://appchoose.io/claims/brandsAccess']) {
    return false;
  }

  const brandsAccess = user[
    'https://appchoose.io/claims/brandsAccess'
  ] as Record<string, { role: string }>;

  return Object.keys(brandsAccess).includes(brandId);
};

export const getBrandAccessRole = (user: User | undefined, brandId: string) => {
  if (!user?.['https://appchoose.io/claims/brandsAccess']) {
    return null;
  }

  const brandsAccess = user[
    'https://appchoose.io/claims/brandsAccess'
  ] as Record<string, { role: string }>;

  if (Object.keys(brandsAccess).includes(brandId)) {
    return brandsAccess[brandId]?.role;
  }
  return null;
};
