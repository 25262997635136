import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useTitle } from 'react-use';
import { useRecoilValue } from 'recoil';

import Button from '@appchoose/button';
import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import { Modal, ModalContent, ModalTrigger } from '@appchoose/modal';
import Select, {
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@appchoose/select';
import { Sidebar, SidebarContent, SidebarTrigger } from '@appchoose/sidebar';
import { useAuth0 } from '@auth0/auth0-react';
import type { SortingState } from '@tanstack/react-table';
import { addHours, addMinutes, addSeconds, isAfter } from 'date-fns';

import { ModalExport } from '../../components/modal-export/modal-export';
import { ModalImportCsv } from '../../components/modal-import/modal-import-csv';
import { ModalOrder } from '../../components/modal-order/modal-order';
import { NewOrdersBanner } from '../../components/new-orders-banner/new-orders-banner';
import { OrderPagination } from '../../components/order-table/order-pagination';
import type { OrderData } from '../../components/order-table/order-table';
import { OrderTable } from '../../components/order-table/order-table';
import type { OrderFiltering } from '../../components/order-table/use-filters';
import { useFilters } from '../../components/order-table/use-filters';
import { useResetList } from '../../components/order-table/use-reset-list';
import { useSorting } from '../../components/order-table/use-sorting';
import { Tabs, TabsContent } from '../../components/order-tabs/order-tabs';
import { SmartTabs } from '../../components/order-tabs/smart-tabs';
import { useOrderCreatedSubscription } from '../../hooks/use-order-added-subscription';
import { useOrderUpdatedSubscription } from '../../hooks/use-order-updated-subscription';
import { brandState, serverTimeState } from '../../stores/brand';
import { activeSaleState } from '../../stores/sales';
import { StoreRegion } from '../../types/generated';
import type { OrdersQuery } from '../../types/generated-new';
import {
  OrderTagBrand,
  OrdersSearchFieldInput,
  OrdersSortFieldInput,
  OrdersSortOrderInput,
  useOrdersCountersQuery,
  useOrdersQuery,
} from '../../types/generated-new';
import type { BrandMatch } from '../../types/navigation';
import { track } from '../../utils/analytics';
import { isScopeAuthorized } from '../../utils/auth';
import { OrderFilters } from './order-filters';
import { OrderFiltersModal } from './order-filters-modal';
import { OrderSearch } from './order-search';
import { OrdersShopifyDesyncAlert } from './orders-shopify-desync-alert';
import { SaleNotStarted } from './sale-not-started';
import { TabCounter } from './tab-counter';
import { usePagination } from './use-pagination';
import { useSearch } from './use-search';
import { TabEntry, useTabs } from './use-tabs';

export const PAGE_SIZE = 50;

const transformData = (
  orders: OrdersQuery['orders'],
  store: StoreRegion
): {
  endCursor: string | undefined;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  nodes: OrderData[];
  startCursor: string | undefined;
  totalCount: number;
} => {
  return {
    endCursor: orders.paging.endCursor ?? undefined,
    hasNextPage: orders.paging.hasNextPage,
    hasPreviousPage: orders.paging.hasPreviousPage,
    nodes: orders.nodes.map((order) => ({
      claim: order.tags.claim,
      claimCreatedAt: order.claims[0]?.createdAt
        ? new Date(order.claims[0].createdAt)
        : null,
      claimReason: order.tags.claimReason,
      createdAt: new Date(order.createdAt),
      fulfillment: order.tags.fulfillment,
      hasSynchronizationError: order.hasSynchronizationError,
      id: order.id,
      items: order.items
        .map((item) => item.imageUrl)
        .reduce<{ imageUrl: string; quantity: number }[]>((acc, curr) => {
          const foundItemIdx = acc.findIndex((it) => it.imageUrl === curr);
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          if (foundItemIdx !== -1) acc[foundItemIdx]!.quantity++;
          else acc.push({ imageUrl: curr, quantity: 1 });
          return acc;
        }, []),
      total: {
        amount:
          store === StoreRegion.Fr
            ? order.totalPriceSold.valueWithVat
            : order.totalPriceSold.valueWithoutVat,
        currency: order.totalPriceSold.currency,
      },
      recipientName: order.recipient.fullName,
      refund: order.tags.refund,
      reship: order.tags.reship,
      return: order.tags.return,
      requiredAction: order.tags.brand.includes(OrderTagBrand.ActionRequired),
      shipment: order.tags.shipment,
      parcelTrackingSubStatus: order.parcels[0]?.trackingSubStatus ?? null,
      parcelHasTrackingStucked: order.parcels[0]?.hasTrackingStucked ?? false,
    })),
    startCursor: orders.paging.startCursor ?? undefined,
    totalCount: orders.totalCount,
  };
};

export const getTaggingFromTabEntry = (tab: TabEntry) => {
  switch (tab) {
    case TabEntry.RequiredAction:
      return [OrderTagBrand.ActionRequired];
    case TabEntry.Unfulfilled:
      return [OrderTagBrand.ActionRequiredUnfulfilled];
    case TabEntry.Claim:
      return [OrderTagBrand.ActionRequiredClaim];
    case TabEntry.DeliveryIncident:
      return [OrderTagBrand.ActionRequiredShipmentIncident];
    case TabEntry.Return:
      return [OrderTagBrand.ActionRequiredReturn];
    case TabEntry.Filters:
      return [];
    default:
      return [OrderTagBrand.Available];
  }
};

export const getSearchFromTableSearch = (searchTerm: string | undefined) => {
  return searchTerm
    ? {
        search: {
          field: OrdersSearchFieldInput.All,
          term: searchTerm.trim(),
        },
      }
    : undefined;
};

export const getFiltersFromTableFilters = (filters: OrderFiltering) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { creationDate, ...filtersWithoutCreationDate } = filters;
  return {
    ...filtersWithoutCreationDate,
  };
};

// TODO: remove in favor of getSortFromTableSort
export const getExportSortFromTableSort = (sortingState: SortingState) => {
  const createdAtColumn = sortingState.find(
    (columnSort) => columnSort.id === 'createdAt'
  );
  if (createdAtColumn)
    return {
      field: OrdersSortFieldInput.CreationDate,
      order: createdAtColumn.desc
        ? OrdersSortOrderInput.Descending
        : OrdersSortOrderInput.Ascending,
    };

  const recipientNameColumn = sortingState.find(
    (columnSort) => columnSort.id === 'recipientName'
  );
  if (recipientNameColumn)
    return {
      field: OrdersSortFieldInput.RecipientFullName,
      order: recipientNameColumn.desc
        ? OrdersSortOrderInput.Descending
        : OrdersSortOrderInput.Ascending,
    };

  const totalColumn = sortingState.find(
    (columnSort) => columnSort.id === 'total'
  );
  if (totalColumn)
    return {
      field: OrdersSortFieldInput.TotalPriceSold,
      order: totalColumn.desc
        ? OrdersSortOrderInput.Descending
        : OrdersSortOrderInput.Ascending,
    };

  const fulfillmentColumn = sortingState.find(
    (columnSort) => columnSort.id === 'fulfillment'
  );
  if (fulfillmentColumn)
    return {
      field: OrdersSortFieldInput.TagFulfillment,
      order: fulfillmentColumn.desc
        ? OrdersSortOrderInput.Descending
        : OrdersSortOrderInput.Ascending,
    };

  const claimColumn = sortingState.find(
    (columnSort) => columnSort.id === 'claim'
  );
  if (claimColumn)
    return {
      field: OrdersSortFieldInput.TagClaim,
      order: claimColumn.desc
        ? OrdersSortOrderInput.Descending
        : OrdersSortOrderInput.Ascending,
    };

  const refundColumn = sortingState.find(
    (columnSort) => columnSort.id === 'refund'
  );
  if (refundColumn)
    return {
      field: OrdersSortFieldInput.TagRefund,
      order: refundColumn.desc
        ? OrdersSortOrderInput.Descending
        : OrdersSortOrderInput.Ascending,
    };

  const reshipColumn = sortingState.find(
    (columnSort) => columnSort.id === 'reship'
  );
  if (reshipColumn)
    return {
      field: OrdersSortFieldInput.TagReship,
      order: reshipColumn.desc
        ? OrdersSortOrderInput.Descending
        : OrdersSortOrderInput.Ascending,
    };

  return {
    field: OrdersSortFieldInput.CreationDate,
    order: OrdersSortOrderInput.Ascending,
  };
};

export const getSortFromTableSort = (sortingState: SortingState) => {
  const createdAtColumn = sortingState.find(
    (columnSort) => columnSort.id === 'createdAt'
  );
  if (createdAtColumn)
    return {
      field: OrdersSortFieldInput.CreationDate,
      order: createdAtColumn.desc
        ? OrdersSortOrderInput.Descending
        : OrdersSortOrderInput.Ascending,
    };

  const recipientNameColumn = sortingState.find(
    (columnSort) => columnSort.id === 'recipientName'
  );
  if (recipientNameColumn)
    return {
      field: OrdersSortFieldInput.RecipientFullName,
      order: recipientNameColumn.desc
        ? OrdersSortOrderInput.Descending
        : OrdersSortOrderInput.Ascending,
    };

  const totalColumn = sortingState.find(
    (columnSort) => columnSort.id === 'total'
  );
  if (totalColumn)
    return {
      field: OrdersSortFieldInput.TotalPriceSold,
      order: totalColumn.desc
        ? OrdersSortOrderInput.Descending
        : OrdersSortOrderInput.Ascending,
    };

  const fulfillmentColumn = sortingState.find(
    (columnSort) => columnSort.id === 'fulfillment'
  );
  if (fulfillmentColumn)
    return {
      field: OrdersSortFieldInput.TagFulfillment,
      order: fulfillmentColumn.desc
        ? OrdersSortOrderInput.Descending
        : OrdersSortOrderInput.Ascending,
    };

  const claimColumn = sortingState.find(
    (columnSort) => columnSort.id === 'claim'
  );
  if (claimColumn)
    return {
      field: OrdersSortFieldInput.TagClaim,
      order: claimColumn.desc
        ? OrdersSortOrderInput.Descending
        : OrdersSortOrderInput.Ascending,
    };

  const claimCreationDateColumn = sortingState.find(
    (columnSort) => columnSort.id === 'claimCreatedAt'
  );
  if (claimCreationDateColumn)
    return {
      field: OrdersSortFieldInput.ClaimCreationDate,
      order: claimCreationDateColumn.desc
        ? OrdersSortOrderInput.Descending
        : OrdersSortOrderInput.Ascending,
    };

  const claimReasonColumn = sortingState.find(
    (columnSort) => columnSort.id === 'claimReason'
  );
  if (claimReasonColumn)
    return {
      field: OrdersSortFieldInput.TagClaimReason,
      order: claimReasonColumn.desc
        ? OrdersSortOrderInput.Descending
        : OrdersSortOrderInput.Ascending,
    };

  const refundColumn = sortingState.find(
    (columnSort) => columnSort.id === 'refund'
  );
  if (refundColumn)
    return {
      field: OrdersSortFieldInput.TagRefund,
      order: refundColumn.desc
        ? OrdersSortOrderInput.Descending
        : OrdersSortOrderInput.Ascending,
    };

  const reshipColumn = sortingState.find(
    (columnSort) => columnSort.id === 'reship'
  );
  if (reshipColumn)
    return {
      field: OrdersSortFieldInput.TagReship,
      order: reshipColumn.desc
        ? OrdersSortOrderInput.Descending
        : OrdersSortOrderInput.Ascending,
    };

  return {
    field: OrdersSortFieldInput.CreationDate,
    order: OrdersSortOrderInput.Ascending,
  };
};

export const getDateFiltersFromTableFilters = (filter: OrderFiltering) => {
  const toDate = filter.creationDate?.to
    ? addSeconds(
        addMinutes(addHours(new Date(filter.creationDate.to), 23), 59),
        59
      )
    : undefined;
  return {
    from: filter.creationDate?.from ? filter.creationDate.from : undefined,
    to: toDate?.toISOString(),
  };
};

enum SwitchOrder {
  None,
  Next,
  Previous,
}

export const Orders: React.FC = () => {
  const { user } = useAuth0();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { filters } = useFilters();
  const { pagination, setPagination } = usePagination();
  const { resetList } = useResetList();
  const {
    debouncedSearchTerm,
    isSearchActive,
    isSearchEmpty,
    setIsSearchActive,
    trackOpenSearch,
  } = useSearch();
  const [, setSearchParams] = useSearchParams();
  const { sorting } = useSorting();
  const { tabs, trackTab, selectedTab } = useTabs();
  const { t } = useTranslation();

  const { brandId, orderId } = useParams<BrandMatch>();

  const activeSale = useRecoilValue(activeSaleState);
  const brand = useRecoilValue(brandState);
  const serverTime = useRecoilValue(serverTimeState);

  const [switchOrder, setSwitchOrder] = useState<SwitchOrder>(SwitchOrder.None);
  const [openFiltersModal, setOpenFiltersModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);

  const hasSaleStarted =
    activeSale?.start_at &&
    isAfter(serverTime ?? new Date(), new Date(activeSale.start_at));

  useOrderCreatedSubscription();
  useOrderUpdatedSubscription();

  const { data: ordersCountersData } = useOrdersCountersQuery(
    {
      sellerId: brandId ?? '',
      saleId: activeSale?.id ?? '',
    },
    {
      enabled: !!brandId && !!activeSale?.id,
      refetchOnWindowFocus: false,
    }
  );

  const { data, isLoading, error } = useOrdersQuery(
    {
      saleId: activeSale?.id ?? '',
      input: {
        filter: {
          creationDate: getDateFiltersFromTableFilters(filters),
          tagging: {
            brand: getTaggingFromTabEntry(selectedTab),
            ...getFiltersFromTableFilters(filters),
          },
        },
        ...getSearchFromTableSearch(debouncedSearchTerm),
        sort: getSortFromTableSort(sorting),
        paging: {
          pageSize: PAGE_SIZE,
          beforeCursor: pagination?.beforeCursor,
          afterCursor: pagination?.afterCursor,
        },
      },
    },
    {
      select: (value) =>
        transformData(value?.orders, activeSale?.store ?? StoreRegion.Fr),
    }
  );

  useEffect(() => {
    if (data && switchOrder !== SwitchOrder.None) {
      if (switchOrder === SwitchOrder.Previous) {
        // Après un changement vers la page précédente, on sélectionne
        // la dernière commande de la liste
        const previousOrderId = data.nodes.at(-1)?.id;
        if (previousOrderId) {
          navigate({
            pathname: `/${brandId}/orders/${previousOrderId}`,
            search: search,
          });
        }
      } else {
        // Après un changement vers la page suivante, on sélectionne
        // la première commande de la liste
        const nextOrderId = data.nodes[0]?.id;
        if (nextOrderId) {
          navigate({
            pathname: `/${brandId}/orders/${nextOrderId}`,
            search: search,
          });
        }
      }
      setSwitchOrder(SwitchOrder.None);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useTitle(
    `${brand?.name ?? ''} ~ Orders (${ordersCountersData?.ordersCounters.tagging.brand.actionRequired ?? 0})`
  );

  const previousOrderId =
    data?.nodes[data?.nodes.findIndex((o) => o.id === orderId) - 1]?.id;
  const nextOrderId =
    data?.nodes[data?.nodes.findIndex((o) => o.id === orderId) + 1]?.id;
  const isOrderDetailsOpen = typeof orderId === 'string' && orderId !== '';

  return (
    <div className="useFixedHeader-scrollable-content relative flex-1 overflow-y-auto">
      <NewOrdersBanner />
      <section className="m-4 max-w-7xl space-y-8 md:m-10">
        <div className="space-y-2">
          <header className="flex justify-between">
            <h2 className="text-4xl font-bold leading-[3.75rem] text-gray-900">
              {t('sidebar.orders')}
            </h2>
            {hasSaleStarted ? (
              <div className="hidden items-center space-x-4 md:flex">
                <Modal open={openImportModal} onOpenChange={setOpenImportModal}>
                  <ModalTrigger asChild>
                    <Button
                      onClick={() => track('OpenModalImportCSV', {})}
                      type="button"
                      appearance="outline"
                    >
                      {t('orders.actions.import')}
                    </Button>
                  </ModalTrigger>
                  <ModalContent scrollable>
                    <ModalImportCsv onClose={() => setOpenImportModal(false)} />
                  </ModalContent>
                </Modal>
                {isScopeAuthorized(user, 'scope.brand.order.export') ? (
                  <Modal
                    open={openExportModal}
                    onOpenChange={setOpenExportModal}
                  >
                    <ModalTrigger asChild>
                      <Button
                        onClick={() => track('OpenModalExport', {})}
                        type="button"
                        appearance="primary"
                      >
                        {t('orders.actions.export')}
                      </Button>
                    </ModalTrigger>
                    <ModalContent scrollable>
                      <ModalExport onClose={() => setOpenExportModal(false)} />
                    </ModalContent>
                  </Modal>
                ) : null}
              </div>
            ) : null}
          </header>
        </div>
        {hasSaleStarted ? (
          <>
            <OrdersShopifyDesyncAlert />
            <Tabs
              value={selectedTab}
              onValueChange={(value) => {
                resetList(value as TabEntry);
                trackTab(value as TabEntry);
              }}
            >
              <div className="flex flex-col space-y-4 sm:hidden">
                <Select
                  value={selectedTab}
                  onValueChange={(value) => resetList(value as TabEntry)}
                >
                  <SelectTrigger>
                    <SelectValue
                      placeholder={t('orders.tabs.select.empty_field')}
                    />
                  </SelectTrigger>
                  <SelectContent
                    ref={(ref) => {
                      if (!ref) return;
                      ref.addEventListener('touchstart', (e: TouchEvent) =>
                        e.preventDefault()
                      );
                    }}
                  >
                    {tabs.map((tab) => (
                      <SelectItem
                        value={tab.value}
                        key={tab.value}
                        className="select-none"
                      >
                        <div className="flex space-x-1">
                          <span>{tab.label}</span>
                          <TabCounter
                            counter={tab.counter}
                            className={cn({
                              'bg-green-300 text-green-900':
                                tab.value === selectedTab,
                            })}
                          />
                        </div>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <button
                  type="button"
                  className={cn(
                    'relative flex items-center gap-x-1 rounded border border-gray-500 p-2 text-sm text-gray-500 outline-none hover:bg-green-300 focus-visible:ring-2 focus-visible:ring-[#ECECEC]',
                    {
                      hidden: isSearchActive,
                    }
                  )}
                  onClick={() => {
                    resetList(TabEntry.Available);
                    setIsSearchActive(true);
                    trackOpenSearch();
                  }}
                >
                  <div className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <Icon icon="search" />
                  </div>
                  <span className="pl-6 leading-5.5">
                    {t('orders.search.placeholder')}
                  </span>
                </button>
                <OrderSearch isSearchActive={isSearchActive} />
                <Modal
                  open={openFiltersModal}
                  onOpenChange={(open) => setOpenFiltersModal(open)}
                >
                  <ModalTrigger asChild>
                    <button
                      type="button"
                      className={cn(
                        'relative flex items-center gap-x-1 rounded border border-gray-500 p-2 text-sm text-gray-900 outline-none focus-visible:ring-2 focus-visible:ring-[#ECECEC]'
                      )}
                    >
                      <div className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <Icon icon="filterAlt" />
                      </div>
                      <span className="px-6 leading-5.5">
                        {t('orders.actions.filter')}
                      </span>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <Icon icon="arrowDown" className="-rotate-90" />
                      </div>
                    </button>
                  </ModalTrigger>
                  <ModalContent
                    scrollable
                    onOpenAutoFocus={(event) => event.preventDefault()}
                  >
                    <OrderFiltersModal setOpen={setOpenFiltersModal} />
                  </ModalContent>
                </Modal>
              </div>
              <div className="hidden justify-between gap-x-10 shadow-[inset_0_-1px_0_#ECECEC] sm:flex">
                <SmartTabs
                  className={cn({ hidden: isSearchActive })}
                  selected={tabs.findIndex((tab) => tab.value === selectedTab)}
                  tabs={tabs}
                />

                <div
                  className={cn('flex items-center gap-x-2', {
                    hidden: isSearchActive,
                  })}
                >
                  <Modal
                    open={openFiltersModal}
                    onOpenChange={(open) => setOpenFiltersModal(open)}
                  >
                    <ModalTrigger asChild>
                      <button
                        type="button"
                        className="flex items-center gap-x-1 rounded px-2 py-1 text-sm font-semibold text-green-900 outline-none hover:bg-green-300 focus-visible:ring-2 focus-visible:ring-[#ECECEC]"
                      >
                        <Icon icon="filterAlt" />
                        {t('orders.actions.filter')}
                      </button>
                    </ModalTrigger>
                    <ModalContent
                      scrollable
                      onOpenAutoFocus={(event) => event.preventDefault()}
                    >
                      <OrderFiltersModal setOpen={setOpenFiltersModal} />
                    </ModalContent>
                  </Modal>
                  <button
                    type="button"
                    className="flex items-center gap-x-1 rounded px-2 py-1 text-sm font-semibold text-green-900 outline-none hover:bg-green-300 focus-visible:ring-2 focus-visible:ring-[#ECECEC]"
                    onClick={() => {
                      resetList(TabEntry.Available);
                      setIsSearchActive(true);
                      trackOpenSearch();
                    }}
                  >
                    <Icon icon="search" />
                    {t('orders.actions.search')}
                  </button>
                </div>
                <OrderSearch isSearchActive={isSearchActive} />
              </div>
              {isSearchActive &&
              !isSearchEmpty &&
              data?.totalCount !== undefined ? (
                <div className="mt-4 text-sm text-gray-700">
                  {t('orders.search.x_results', {
                    count: data.totalCount,
                  })}
                </div>
              ) : null}
              <OrderFilters totalCount={data?.totalCount} />
              {tabs.map((tab) => (
                <TabsContent key={tab.value} value={tab.value}>
                  <OrderTable
                    data={data?.nodes ?? []}
                    error={error}
                    isLoading={isLoading}
                  />
                  <OrderPagination
                    afterCursor={data?.endCursor}
                    beforeCursor={data?.startCursor}
                    hasNextPage={data?.hasNextPage ?? false}
                    hasPreviousPage={data?.hasPreviousPage ?? false}
                  />
                </TabsContent>
              ))}
            </Tabs>
          </>
        ) : (
          <SaleNotStarted />
        )}
      </section>
      <Sidebar
        open={isOrderDetailsOpen}
        onOpenChange={() => {
          navigate({
            pathname: `/${brandId}/orders`,
            search: search,
          });
        }}
        modal={false}
      >
        {/* Sidebar overlay when modal={false} */}
        {isOrderDetailsOpen ? (
          <SidebarTrigger className="fixed inset-0 z-40 bg-black/30 data-[state=closed]:duration-300 data-[state=open]:duration-300 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
        ) : null}
        <SidebarContent
          className="lg:w-[38rem]"
          // Prevent the sidebar from closing when clicking outside
          onPointerDownOutside={(e) => e.preventDefault()}
          onFocusOutside={(e) => e.preventDefault()}
        >
          <ModalOrder
            goToPreviousPageAndOrder={() => {
              setSwitchOrder(SwitchOrder.Previous);
              setPagination({
                beforeCursor: data?.startCursor,
                afterCursor: undefined,
              });
              setSearchParams((prev) => {
                prev.delete('after');
                prev.set('before', data?.startCursor ?? '');

                return prev;
              });
            }}
            goToNextPageAndOrder={() => {
              setSwitchOrder(SwitchOrder.Next);
              setPagination({
                beforeCursor: undefined,
                afterCursor: data?.endCursor,
              });
              setSearchParams((prev) => {
                prev.delete('before');
                prev.set('after', data?.endCursor ?? '');

                return prev;
              });
            }}
            hasNextPage={data?.hasNextPage ?? false}
            hasPreviousPage={data?.hasPreviousPage ?? false}
            id={orderId ?? ''}
            nextOrderId={nextOrderId}
            previousOrderId={previousOrderId}
          />
        </SidebarContent>
      </Sidebar>
    </div>
  );
};
