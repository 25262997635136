import { atom, useRecoilState } from 'recoil';

import type { SortingState } from '@tanstack/react-table';

import { track } from '../../utils/analytics';
import { getComplexSearchParam, getSearchParam } from '../../utils/url';
import { TabEntry, sanitizeTab } from '../../views/orders-page/use-tabs';

const sortingKey = [
  'createdAt',
  'recipientName',
  'total',
  'fulfillment',
  'claim',
  'claimCreatedAt',
  'refund',
  'reship',
];

export const getTrackingNameFromSortCriteria = (sortCriteria: string) => {
  switch (sortCriteria) {
    case 'createdAt':
      return 'Creation Date';
    case 'recipientName':
      return 'Customer';
    case 'total':
      return 'Total';
    case 'fulfillment':
      return 'Fulfillment';
    case 'claim':
      return 'Claim';
    case 'claimCreatedAt':
      return 'Claim Creation Date';
    case 'refund':
      return 'Refund';
    case 'reship':
      return 'Reship';
    default:
      return '<unknown>';
  }
};

const sanitizeSorting = (sorting: unknown, tabEntry: TabEntry) => {
  const sanitizedSorting = [] as SortingState;
  if (Array.isArray(sorting)) {
    (sorting as SortingState).forEach((sort) => {
      if (
        sort &&
        sortingKey.includes(sort.id) &&
        typeof sort.desc === 'boolean'
      ) {
        sanitizedSorting.push({
          id: sort.id,
          desc: sort.desc,
        });
      }
    });
  }

  if (sanitizedSorting.length > 0) {
    return sanitizedSorting;
  }

  if (tabEntry === TabEntry.Claim) {
    return [{ id: 'claimCreatedAt', desc: false }];
  }

  return [
    {
      id: 'createdAt',
      desc: false,
    },
  ];
};

export const orderSortingState = atom<SortingState>({
  key: 'orderSortingState',
  default: Promise.resolve(
    sanitizeSorting(
      getComplexSearchParam('sort'),
      sanitizeTab(getSearchParam('view') ?? '')
    )
  ),
});

export const useSorting = () => {
  const [sorting, setSorting] = useRecoilState(orderSortingState);

  const resetSorting = (tabEntry: TabEntry) => {
    if (tabEntry === TabEntry.Claim) {
      setSorting([{ id: 'claimCreatedAt', desc: false }]);
    } else {
      setSorting([{ id: 'createdAt', desc: false }]);
    }
  };

  const trackSorting = (newSorting: SortingState) => {
    track('SortTableOrders', {
      sortCriteria: getTrackingNameFromSortCriteria(newSorting[0]?.id ?? ''),
      sortDirection: newSorting[0]?.desc ? 'Descending' : 'Ascending',
    });
  };

  return { resetSorting, sorting, setSorting, trackSorting } as const;
};
