import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Button from '@appchoose/button';
import Icon from '@appchoose/icon';

import { brandState } from '../../stores/brand';
import { activeSaleIdState } from '../../stores/sales';
import {
  OrderTagBrand,
  OrderTagFulfillment,
  OrderTagShopify,
  OrdersSortFieldInput,
  OrdersSortOrderInput,
  useOrdersUnfulfilledDesyncShopifyQuery,
} from '../../types/generated-new';
import {
  getProcShopifyUrl,
  getShopifyAdminUrl,
  getShopifyErrors,
  getShopifyLabelFromError,
} from '../../utils/shopify';
import { useResyncShopifyOrders } from './use-resync-shopify-orders';

export const OrdersShopifyDesyncAlert: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { mutateAsync: onResyncShopifyOrders, isPending } =
    useResyncShopifyOrders();

  const activeSaleId = useRecoilValue(activeSaleIdState);
  const brand = useRecoilValue(brandState);

  const { data, isLoading } = useOrdersUnfulfilledDesyncShopifyQuery({
    saleId: activeSaleId ?? '',
    input: {
      filter: {
        tagging: {
          brand: [OrderTagBrand.Available],
          fulfillment: [OrderTagFulfillment.Unfulfilled],
          shopify: [OrderTagShopify.Unsynchronized],
        },
      },
      paging: {
        pageSize: 100,
      },
      sort: {
        field: OrdersSortFieldInput.TagShopify,
        order: OrdersSortOrderInput.Ascending,
      },
    },
  });

  if (data?.orders.totalCount === 0 || isLoading) return null;

  const shopifyDesyncErrors = new Set(
    data?.orders.nodes.map((order) =>
      getShopifyErrors(order.integration.errorMessage ?? '')
    ) ?? []
  );

  return (
    <div className="flex flex-col justify-between gap-x-8 gap-y-6 rounded border border-red-350 bg-[#F9E7E7] p-6 lg:flex-row lg:items-center">
      <div className="flex flex-row space-x-4">
        <Icon icon="shopifyError" className="size-6 shrink-0 text-red-600" />
        <div className="space-y-2.5">
          <div className="space-y-1">
            <div className="text-sm font-bold text-gray-700">
              {t('orders.shopify_desync.x_desynced_orders', {
                count: data?.orders.totalCount,
              })}
            </div>
            <ul className="list-inside list-disc text-sm text-gray-700">
              {Array.from(shopifyDesyncErrors).map((error) => (
                <li key={error}>{getShopifyLabelFromError(error, t)}</li>
              ))}
            </ul>
          </div>
          <a
            href={getProcShopifyUrl(i18n.language)}
            target="_blank"
            rel="noreferrer"
            className="inline-flex items-center space-x-0.5 text-xs font-semibold text-green-900"
          >
            <span>{t('orders.shopify_desync.view_procedure')}</span>
            <Icon icon="externalLinkSimple" />
          </a>
        </div>
      </div>
      <div className="flex shrink-0 flex-col gap-4 lg:flex-row">
        <a
          href={getShopifyAdminUrl(brand?.shopify?.shop ?? '')}
          target="_blank"
          rel="noreferrer"
          className="flex items-center justify-center space-x-2 rounded-lg border-2 border-[#E79E9E] bg-white px-4 py-2 text-sm font-bold text-gray-900"
        >
          <Icon icon="externalLinkDefault" className="size-5 shrink-0" />
          <span>{t('orders.shopify_desync.rectify_on_shopify')}</span>
        </a>
        <Button
          appearance="primary"
          type="button"
          className="justify-center space-x-2"
          onClick={onResyncShopifyOrders}
          disabled={isPending}
        >
          <Icon icon="refresh" className="size-5 shrink-0" />
          <span>{t('orders.shopify_desync.sync_orders')}</span>
        </Button>
      </div>
    </div>
  );
};
