import { initReactI18next } from 'react-i18next';

import type { Resource } from 'i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en.json';
import fr from './fr.json';

const resources: Resource = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

// initialize i18next with catalog and language to use
if (i18n) {
  i18n.use(initReactI18next).use(LanguageDetector).init({
    resources,
    fallbackLng: 'en',
  });
}

export const getI18nLanguageFromISOLanguage = (isoLanguage: string) => {
  const language = isoLanguage.substring(0, 2);
  return language === 'fr' ? 'fr' : 'en';
};

export const getI18nLanguageFullFromISOLanguage = (isoLanguage: string) => {
  const language = isoLanguage.substring(0, 2);
  return language === 'fr' ? 'french' : 'english';
};

export default i18n;
