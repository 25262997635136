import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';

import Icon from '@appchoose/icon';
import { Modal, ModalContent, ModalTrigger } from '@appchoose/modal';
import { toast } from '@appchoose/toast';
import Tooltip from '@appchoose/tooltip';
import * as Sentry from '@sentry/react';

import type { OrderQuery } from '../../types/generated-new';
import { TrackingStatus } from '../../types/generated-new';
import { track } from '../../utils/analytics';
import { ModalOrderEditTracking } from './modal-order-edit-tracking';

type TrackingViewLinkProps = {
  parcel?:
    | OrderQuery['order']['parcels'][0]
    | OrderQuery['order']['returnParcels'][0];
};

export const TrackingViewLink: React.FC<TrackingViewLinkProps> = ({
  parcel,
}: TrackingViewLinkProps) => {
  const { t } = useTranslation();

  const [displayModalEdit, setDisplayModalEdit] = useState(false);

  const [trackingNumberClipboardState, copyTrackingNumberToClipboard] =
    useCopyToClipboard();

  useEffect(() => {
    const { value, error } = trackingNumberClipboardState;
    if (value) {
      toast.success(t('order.copy_tracking_number_feedback'));
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [trackingNumberClipboardState, t]);

  const onCopyTrackingNumber = (trackingNumber: string) => {
    copyTrackingNumberToClipboard(trackingNumber);
    track('CopyToClipboard', {
      label: 'trackingNumber',
    });
  };

  return (
    <a
      href={parcel?.trackingUrl}
      target="_blank"
      rel="noreferrer"
      className="flex flex-row items-center space-x-3 rounded border border-gray-300 bg-[#FBFBFB] p-4 hover:border-gray-700"
    >
      <img
        src={parcel?.trackingCourier.logoUrl}
        alt=""
        className="size-12 object-contain"
      />
      <div className="flex flex-1 items-center truncate">
        <div className="flex flex-1 flex-col space-y-0.5 truncate font-semibold">
          <span className="text-xs text-gray-500">
            {parcel?.trackingCourier.name}
          </span>
          <div className="flex items-center space-x-2">
            <span className="truncate text-sm text-gray-900">
              {parcel?.trackingNumber}
            </span>
            <div className="flex items-center gap-1">
              <Tooltip
                content={
                  <div className="px-1.5 py-3">
                    {t('order.copy_tracking_number_tooltip')}
                  </div>
                }
              >
                <button
                  type="button"
                  className="rounded-sm p-[0.19rem] text-gray-500 hover:bg-gray-50"
                  onClick={(e) => {
                    e.preventDefault();
                    onCopyTrackingNumber(parcel?.trackingNumber ?? '');
                  }}
                >
                  <Icon icon="copy" className="size-3.5" />
                </button>
              </Tooltip>

              {parcel?.trackingStatus === TrackingStatus.Delivered ? (
                <Tooltip
                  content={
                    <div className="px-1.5 py-3">
                      {t(
                        'order.impossible_to_edit_tracking_of_delivered_order'
                      )}
                    </div>
                  }
                >
                  <button
                    type="button"
                    className="cursor-default rounded-sm px-1 py-0.5 text-xs text-gray-300"
                    tabIndex={-1}
                  >
                    {t('order.edit')}
                  </button>
                </Tooltip>
              ) : (
                <Modal
                  open={displayModalEdit}
                  onOpenChange={setDisplayModalEdit}
                >
                  <ModalTrigger asChild>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setDisplayModalEdit(true);
                        track('OpenModalEditTracking', {});
                      }}
                      className="rounded-sm px-1 py-0.5 text-xs text-green-900 hover:bg-green-300 disabled:text-gray-300 disabled:hover:bg-transparent"
                    >
                      {t('order.edit')}
                    </button>
                  </ModalTrigger>
                  <ModalContent scrollable>
                    <ModalOrderEditTracking
                      parcel={parcel}
                      onConfirm={() => setDisplayModalEdit(false)}
                    />
                  </ModalContent>
                </Modal>
              )}
            </div>
          </div>
        </div>
        <div className="shrink-0">
          <Icon
            icon="externalLinkSimple"
            size="large"
            className="text-green-900"
          />
        </div>
      </div>
    </a>
  );
};
