import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import Icon from '@appchoose/icon';

import { XChooseHeader } from '../../components/x-choose-header/x-choose-header';
import type { BrandMatch } from '../../types/navigation';

export const ForgotPasswordConfirmation: React.FC = () => {
  const { brandId = '' } = useParams<BrandMatch>();
  const { t } = useTranslation();

  const { search } = useLocation();
  const [email] = useState(
    decodeURIComponent(new URLSearchParams(search).get('email') ?? '')
  );

  return (
    <div className="mx-auto w-full px-4 py-10 sm:max-w-4.5xl sm:px-6">
      <XChooseHeader />
      <main className="mx-2 mt-20 flex flex-col space-y-10 sm:mx-8 md:mx-20">
        <h1 className="mb-4 space-y-4 text-3.5xl font-bold">
          <span className="flex">
            {t('auth.change_password.email_sent_to')}
          </span>
          <span className="flex text-green-900">{email}</span>
        </h1>
        <div className="flex flex-initial space-x-4 self-start rounded-lg border border-gray-300 bg-gray-50 p-6 pr-8">
          <Icon icon="help" size="large" className="text-gray-700" />
          <div className="space-y-1">
            <p className="m-0 text-sm font-semibold text-gray-700">
              {t('auth.change_password.didnt_receive_email')}
            </p>
            <ol className="m-0 list-inside list-decimal text-sm leading-5.5 text-gray-700">
              <li>
                <span className="pl-0.5">
                  {t('auth.change_password.check_info')}
                </span>
              </li>
              <li>
                <span className="pl-0.5">
                  {t('auth.change_password.check_spam')}
                </span>
              </li>
              <li>
                <span className="pl-0.5">
                  {t('auth.change_password.wait_x_minutes', {
                    time: '10',
                  })}
                </span>
              </li>
            </ol>
          </div>
        </div>
        <NavLink
          to={`/${brandId}/login${location.search}`}
          className="text-sm font-semibold text-green-900"
        >
          {t('auth.change_password.back_to_login')}
        </NavLink>
      </main>
    </div>
  );
};

ForgotPasswordConfirmation.displayName = 'ForgotPasswordConfirmation';
