import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { activeSaleState } from '../../stores/sales';
import type { BillingByVatV2 } from '../../types/generated-new.js';
import { formatPrice } from '../../utils/currency';
import { formatPercentage } from '../../utils/number';
import { AdminWithTooltip } from './admin-with-tooltip';
import { DashedLine } from './dashed-line';

export type BillingProductSummaryProps = {
  product_stats: BillingByVatV2;
  isInternalUser: boolean;
  isSubjectVat: boolean;
  locale: 'fr' | 'en';
};

export const BillingProductSummary = ({
  product_stats,
  isInternalUser,
  isSubjectVat,
  locale,
}: BillingProductSummaryProps) => {
  const activeSale = useRecoilValue(activeSaleState);
  const { t } = useTranslation();

  return (
    <>
      {isInternalUser ? (
        <div>
          <div className="flex justify-between space-x-2 px-6 py-2 text-base leading-5.5 text-gray-700 transition-colors duration-300 hover:bg-gray-50">
            <div className="flex shrink-0 items-center space-x-2">
              <span>{t('invoice.amount_operation_ht')}</span>{' '}
              <AdminWithTooltip />
            </div>
            <div className="flex w-full items-end pb-1.5">
              <DashedLine />
            </div>
            <div className="shrink-0">
              {formatPrice(
                product_stats.total_operation_ht / 100,
                locale,
                activeSale?.currency.toString().toUpperCase() ?? 'EUR'
              )}
            </div>
          </div>
        </div>
      ) : null}
      {isInternalUser ? (
        <div>
          <div className="flex justify-between space-x-2 px-6 py-2 text-base leading-5.5 text-gray-700 transition-colors duration-300 hover:bg-gray-50">
            <div className="flex shrink-0 items-center space-x-2">
              <span>{t('invoice.amount_choose_margin')}</span>
              <AdminWithTooltip />
            </div>
            <div className="flex w-full items-end pb-1.5">
              <DashedLine />
            </div>
            <div className="shrink-0">
              {formatPrice(
                -(product_stats.total_commission_ht / 100),
                locale,
                activeSale?.currency.toString().toUpperCase() ?? 'EUR'
              )}
            </div>
          </div>
        </div>
      ) : null}

      <div>
        <div className="flex justify-between space-x-2 px-6 py-2 text-base leading-5.5 text-gray-700 transition-colors duration-300 hover:bg-gray-50">
          <div className="shrink-0">{t('invoice.amount_billing_ht')}</div>
          <div className="flex w-full items-end pb-1.5">
            <DashedLine />
          </div>
          <div className="shrink-0">
            {formatPrice(
              product_stats.total_billing_ht / 100,
              locale,
              activeSale?.currency.toString().toUpperCase() ?? 'EUR'
            )}
          </div>
        </div>
      </div>
      {isSubjectVat ? (
        <div>
          <div className="flex justify-between space-x-2 px-6 py-2 text-base leading-5.5 text-gray-700 transition-colors duration-300 hover:bg-gray-50">
            <div className="shrink-0">
              {t('invoice.tva')}{' '}
              {formatPercentage(
                parseFloat(product_stats.vat_rate) / 100,
                locale
              )}
            </div>
            <div className="flex w-full items-end pb-1.5">
              <DashedLine />
            </div>
            <div className="shrink-0">
              {formatPrice(
                product_stats.total_vat / 100,
                locale,
                activeSale?.currency.toString().toUpperCase() ?? 'EUR'
              )}
            </div>
          </div>
        </div>
      ) : null}
      {isSubjectVat ? (
        <div>
          <div className="flex justify-between space-x-2 px-6 py-2 text-base leading-5.5 text-gray-700 transition-colors duration-300 hover:bg-gray-50">
            <div className="shrink-0">{t('invoice.amount_billing_ttc')}</div>
            <div className="flex w-full items-end pb-1.5">
              <DashedLine />
            </div>
            <div className="shrink-0">
              {formatPrice(
                product_stats.total_billing_ttc / 100,
                locale,
                activeSale?.currency.toString().toUpperCase() ?? 'EUR'
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
