import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Button from '@appchoose/button';
import { toast } from '@appchoose/toast';
import { useQueryClient } from '@tanstack/react-query';

import { brandState } from '../../stores/brand';
import { activeSaleState } from '../../stores/sales';
import { StoreRegion } from '../../types/generated';
import type { OrderQuery } from '../../types/generated-new';
import { useAcceptOrderClaimWithRefundMutation } from '../../types/generated-new';
import { transformPrice } from '../../utils/currency';

type OrderConfirmRefundProps = {
  claim: OrderQuery['order']['claims'][0];
  order?: OrderQuery['order'];
  orderId?: OrderQuery['order']['id'];
  setIsOpen: (isOpen: boolean) => void;
};

export const ModalConfirmRefund: React.FC<OrderConfirmRefundProps> = ({
  claim,
  order,
  orderId,
  setIsOpen,
}: OrderConfirmRefundProps) => {
  const queryClient = useQueryClient();
  const { i18n, t } = useTranslation();

  const activeSale = useRecoilValue(activeSaleState);
  const brand = useRecoilValue(brandState);

  const {
    mutateAsync: acceptClaimWithRefundMutation,
    isPending,
    error,
  } = useAcceptOrderClaimWithRefundMutation();

  useEffect(() => {
    if (error) {
      toast.error(t('order.modals.modal_refund.refund_error'));
    }
  }, [error]);

  const getRefundPrice = () => {
    const claimedProducts = claim.items;
    if (claimedProducts.length > 0) {
      const total_amount = claimedProducts
        .map((product) => product.priceSold.valueWithVat)
        .reduce((prev, curr) => (prev += curr), 0);
      return transformPrice(
        total_amount,
        i18n.language === 'fr' ? 'fr' : 'en',
        activeSale?.currency.toString().toUpperCase() ?? 'EUR'
      );
    }
    return transformPrice(
      brand?.store === StoreRegion.Fr
        ? order?.totalPriceSold.valueWithVat
        : (order?.totalPriceSold.valueWithoutVat ?? 0),
      i18n.language === 'fr' ? 'fr' : 'en',
      activeSale?.currency.toString().toUpperCase() ?? 'EUR'
    );
  };

  const getRefundPriceFdp = () => {
    const fdp = transformPrice(
      order?.shipping.feesSold ?? 0,
      i18n.language === 'fr' ? 'fr' : 'en',
      activeSale?.currency.toString().toUpperCase() ?? 'EUR'
    );

    const claimedProducts = claim.items;
    if (claimedProducts.length > 0) {
      return claimedProducts.length === order?.items.length
        ? t('order.amount_shipping_fees', { amount: fdp })
        : t('order.amount_shipping_fees', {
            amount: transformPrice(
              0,
              i18n.language === 'fr' ? 'fr' : 'en',
              activeSale?.currency.toString().toUpperCase() ?? 'EUR'
            ),
          });
    }
    return t('order.amount_shipping_fees', { amount: fdp });
  };

  const onConfirm = async () => {
    await acceptClaimWithRefundMutation({
      input: {
        orderId: orderId ?? '',
        claimId: claim.id,
      },
    });

    queryClient.invalidateQueries({
      queryKey: ['order', { orderId: orderId }],
    });
    queryClient.invalidateQueries({
      queryKey: ['orders'],
    });
    setIsOpen(false);
    toast.success(t('order.modals.modal_refund.claim_processed'));
  };

  return (
    <div className="flex flex-col overflow-hidden">
      <div className="overflow-y-auto p-6 md:p-10">
        <h3
          id="modal-refund-title"
          className="mb-6 text-2xl font-bold text-gray-900"
        >
          {claim
            ? t('order.actions_refund_product')
            : t('order.actions_refund')}
        </h3>
        <p>
          {t('order.modals.modal_refund.confirm_refund')}{' '}
          <b>
            {getRefundPrice()} ({getRefundPriceFdp()})
          </b>
        </p>
      </div>
      <div className="flex shrink-0 justify-end space-x-6 border-t border-gray-100 p-6 md:p-10 md:pt-6">
        <Button
          type="button"
          appearance="outline"
          onClick={() => setIsOpen(false)}
        >
          {t('cancel')}
        </Button>{' '}
        <Button type="button" onClick={() => onConfirm()} disabled={isPending}>
          {t('confirm')}
        </Button>
      </div>
    </div>
  );
};
