import { useTranslation } from 'react-i18next';

import type { OrderQuery } from '../../types/generated-new';
import { formatRange } from '../../utils/date';

export type EstimatedDeliveryDatesProps = {
  history?: OrderQuery['order']['shipping']['deliveryDateRangeHistory'];
};

export const EstimatedDeliveryDates: React.FC<EstimatedDeliveryDatesProps> = ({
  history,
}: EstimatedDeliveryDatesProps) => {
  const { i18n } = useTranslation();

  if (!history) return null;

  const firstEstimatedDeliveryDate =
    history.length > 0 ? history[history.length - 1] : null;

  const lastEstimatedDeliveryDate = history.length > 1 ? history[0] : null;

  return (
    <>
      {lastEstimatedDeliveryDate && (
        <span className="font-bold">
          {formatRange(
            new Date(lastEstimatedDeliveryDate.start),
            new Date(lastEstimatedDeliveryDate.end),
            i18n.language === 'fr' ? 'fr' : 'en'
          )}
        </span>
      )}{' '}
      {firstEstimatedDeliveryDate && (
        <span
          className={lastEstimatedDeliveryDate ? 'line-through' : 'font-bold'}
        >
          {formatRange(
            new Date(firstEstimatedDeliveryDate.start),
            new Date(firstEstimatedDeliveryDate.end),
            i18n.language === 'fr' ? 'fr' : 'en'
          )}
        </span>
      )}
    </>
  );
};
