/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useTitle, useUpdateEffect } from 'react-use';
import { useRecoilValue } from 'recoil';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import Statistics from '@appchoose/statistics';
import { differenceInMinutes } from 'date-fns';

import { SalesAreaChart } from '../../components/sales-area-chart/sales-area-chart';
import { SkeletonGraph } from '../../components/sales-area-chart/skeleton-graph';
import { brandState } from '../../stores/brand';
import { activeSaleState } from '../../stores/sales';
import {
  StoreCurrency,
  StoreRegion,
  useRatingsQuery,
  useReturnsQuery,
  useStatsHistogramQuery,
  useStatsQuery,
} from '../../types/generated';
import { Rate } from './Rate';
import { Returns } from './Returns';
import { TopProducts } from './Top-Products';

import './analytics-screen.scss';

export const AnalyticsScreen: React.FC = () => {
  const brand = useRecoilValue(brandState);
  const [searchParams, setSearchParams] = useSearchParams();
  const { i18n, t } = useTranslation();

  useTitle(`${brand?.name ?? ''} ~ Analytics`);

  const [offsetRate, setOffsetRate] = useState(
    searchParams.has('rate_offset')
      ? parseInt(searchParams.get('rate_offset')!)
      : 0
  );
  const [filterRate, setFilterRate] = useState(
    searchParams.has('rate') ? parseInt(searchParams.get('rate')!) : -1
  );
  const [filterRateMessage, setFilterRateMessage] = useState(
    searchParams.has('rate_message') ? searchParams.get('rate_message')! : 'all'
  );

  const [updatedAt, setUpdatedAt] = useState(new Date());

  const activeSale = useRecoilValue(activeSaleState);

  const { data: statsHistogramData } = useStatsHistogramQuery(
    {
      saleId: activeSale?.id ?? '',
    },
    {
      enabled: !!activeSale?.id,
      select: (data) =>
        data.statsHistogram.map((statHistogram) => ({
          date: new Date(statHistogram.date),
          sum: statHistogram.nb_distinct_orders ?? 0,
          amount:
            ((brand?.store === StoreRegion.Fr
              ? statHistogram.total_operation_ttc
              : statHistogram.total_operation_ht) ?? 0) / 100,
        })),
      refetchOnWindowFocus: false,
    }
  );
  const { data: ratingsData, isLoading: loadingRatings } = useRatingsQuery(
    {
      saleId: activeSale?.id ?? '',
      offset: offsetRate,
      onlyWithMessage: filterRateMessage !== 'all',
      filter: filterRate > -1 ? filterRate - 1 : undefined,
    },
    {
      enabled: !!activeSale?.id,
      refetchOnWindowFocus: false,
    }
  );
  const { data: statsData } = useStatsQuery(
    {
      saleId: activeSale?.id ?? '',
    },
    {
      enabled: !!activeSale?.id,
      refetchOnWindowFocus: false,
    }
  );
  const { data: returnsData } = useReturnsQuery(
    {
      saleId: activeSale?.id ?? '',
    },
    {
      enabled: !!activeSale?.id,
      refetchOnWindowFocus: false,
    }
  );

  useUpdateEffect(() => {
    if (statsData) {
      setUpdatedAt(new Date());
    }
  }, [statsData]);

  const onChangePageRating = (newPage: number) => {
    const newOffset = newPage - 1;
    setSearchParams((prev) => {
      prev.set('rate_offset', newOffset.toString());
      return prev;
    });
    setOffsetRate(newOffset);
    const element = document.querySelector('.rate');
    element?.scrollIntoView({
      behavior: 'auto',
      block: 'start',
      inline: 'nearest',
    });
  };

  const onSelectRating = (key: string | number) => {
    setSearchParams((prev) => {
      prev.set('rate_offset', '0');
      prev.set('rate', key.toString());
      return prev;
    });
    setOffsetRate(0);
    setFilterRate(+key);
  };

  const onSelectFilter = (key: string) => {
    setSearchParams((prev) => {
      prev.set('rate_offset', '0');
      prev.set('rate_message', key.toString());
      return prev;
    });
    setFilterRateMessage(key);
    setOffsetRate(0);
  };

  const onClickRatio = (rate: number) => {
    onSelectRating(rate);
  };

  const getUpdated = () => {
    const time = differenceInMinutes(new Date(), updatedAt);
    if (time === 0) {
      return t('analytics.header_last_update');
    }
    if (time === 1) {
      return t('analytics.header_last_update_time', {
        x: time,
      });
    }
    return t('analytics.header_last_update_times', {
      x: time,
    });
  };

  return (
    <main className="analytics relative flex-1 overflow-y-auto">
      <div className="m-4 max-w-[55rem] md:m-10">
        <header className="flex items-center justify-between">
          <h2 className="text-4xl font-bold leading-[3.75rem] text-gray-900">
            {t('sidebar.analytics')}
          </h2>
          <div className="flex space-x-6 text-gray-500">
            <p className="hidden items-center space-x-1 text-xs min-[961px]:flex">
              <Icon icon="calendar" className="text-gray-500" />
              <span>{t('analytics.header_time')}</span>
            </p>
            <p className="flex items-center space-x-1 text-xs">
              <Icon icon="clock" className="text-gray-500" />
              <span>{getUpdated()}</span>
            </p>
          </div>
        </header>
        <section className="py-6">
          <header className="mb-4 flex flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
            <div className="flex w-full flex-col space-y-6 divide-y rounded border border-beige-400 bg-beige-300 p-6">
              <Statistics
                icon={
                  activeSale?.currency === StoreCurrency.Usd
                    ? 'dollarCircle'
                    : 'euroCircle'
                }
                title={t('analytics.number_total_sale')}
                value={
                  brand?.store === StoreRegion.Fr
                    ? (statsData?.statsV2.totalTurnoverTtc ?? 0) / 100
                    : (statsData?.statsV2.totalTurnoverHt ?? 0) / 100
                }
                locale={i18n.language === 'fr' ? 'fr' : 'en'}
                hasBorder={false}
                tooltipContent={
                  <div className="p-1">
                    {brand?.store === StoreRegion.Fr
                      ? t('analytics.tooltip_total_sale')
                      : t('analytics.tooltip_total_sale_ht')}
                  </div>
                }
                appearance="secondary"
                sign={activeSale?.currency === StoreCurrency.Usd ? '$' : '€'}
                showSign
                reverseSignAndValue={i18n.language === 'en' ? true : false}
                className=""
              />
              <Statistics
                icon="document"
                title={t('analytics.number_total_invoice')}
                value={
                  statsData?.statsV2.isSubjectToVat
                    ? (statsData.statsV2.totalBillingTtc || 0) / 100
                    : (statsData?.statsV2.totalBillingHt ?? 0) / 100
                }
                locale={i18n.language === 'fr' ? 'fr' : 'en'}
                tooltipContent={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t('analytics.tooltip_total_invoice'),
                    }}
                    className="p-1"
                  ></div>
                }
                additionalInfo={
                  statsData?.statsV2.totalBillingHt
                    ? t('analytics.estimation')
                    : undefined
                }
                appearance="secondary"
                hasBorder={false}
                sign={activeSale?.currency === StoreCurrency.Usd ? '$' : '€'}
                showSign
                reverseSignAndValue={i18n.language === 'en' ? true : false}
                className="rounded-none pt-6"
              />
            </div>
            <div className="flex w-full flex-col space-y-6 divide-y rounded border border-gray-300 p-6">
              <Statistics
                icon="package"
                title={t('analytics.number_total_orders')}
                value={statsData?.statsV2.orderCount ?? 0}
                locale={i18n.language === 'fr' ? 'fr' : 'en'}
                hasBorder={false}
                tooltipContent={
                  <div className="p-1">
                    {t('analytics.tooltip_total_orders')}
                  </div>
                }
                className=""
              />
              <Statistics
                icon="tag"
                title={t('analytics.number_total_products')}
                value={statsData?.statsV2.productSoldCount ?? 0}
                locale={i18n.language === 'fr' ? 'fr' : 'en'}
                tooltipContent={
                  <div className="p-1">
                    {t('analytics.tooltip_total_products')}
                  </div>
                }
                hasBorder={false}
                className="rounded-none pt-6"
              />
            </div>
          </header>
          <div className="rounded border border-gray-300 p-6 pb-0">
            <div>
              <div className="flex items-center">
                <Icon icon="statistics" />
                {
                  <span className="ml-2.25 cursor-pointer border-b border-dashed border-gray-300 text-xs font-semibold uppercase tracking-wider text-gray-700 hover:border-solid">
                    {t('analytics.graph_day')}
                  </span>
                }
              </div>

              <div
                className={cn('py-4', {
                  'h-[438px]': (statsHistogramData ?? []).length,
                })}
              >
                {(statsHistogramData ?? []).length ? (
                  <SalesAreaChart
                    data={statsHistogramData ?? []}
                    startAt={activeSale?.start_at ?? ''}
                  />
                ) : (
                  <SkeletonGraph />
                )}
              </div>
            </div>
          </div>

          <TopProducts key={activeSale?.id} />
          <h3 className="my-3.5 text-lg font-bold text-gray-900">
            {t('analytics.returns')}
          </h3>
          <Returns
            total_orders={returnsData?.returns.total_orders ?? 0}
            total_sum={returnsData?.returns.total_sum ?? 0}
            percentage={returnsData?.returns.percentage ?? 0}
            returns={returnsData?.returns.returns ?? []}
            products={returnsData?.returns.products ?? []}
          />

          <h3 className="my-3.5 text-lg font-bold text-gray-900">
            {t('analytics.rate')}
          </h3>
          {!(statsData?.statsV2.rateCountAggByRates ?? []).length ? (
            <div className="no-star">
              <Icon icon="star" size="large" className="mr-1" />
              <p>{t('analytics.rate_text')}</p>
            </div>
          ) : (
            <Rate
              rateCount={statsData?.statsV2.rateCount ?? 0}
              allRates={statsData?.statsV2.rateCountAggByRates ?? []}
              rateAvg={statsData?.statsV2.rateAvg ?? 0}
              onClickRatio={onClickRatio}
              onChangePage={onChangePageRating}
              onSelectFilter={onSelectFilter}
              onSelectRating={onSelectRating}
              filterRate={filterRate}
              filterRateMessage={filterRateMessage}
              isLoadingReviews={loadingRatings}
              rates={ratingsData?.ratings}
              offset={offsetRate}
            />
          )}
        </section>
      </div>
    </main>
  );
};

AnalyticsScreen.displayName = 'AnalyticsScreen';
