/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import AddressView from '@appchoose/address-view';
import Icon from '@appchoose/icon';
import i18n from 'i18next';

import { brandState } from '../../../stores/brand';
import { StoreRegion } from '../../../types/generated';
import type { BrandMatch } from '../../../types/navigation';
import { parsePhoneNumber } from '../../../utils/phone';

export const UpdatingInfoStepConfirmationSummary: React.FC = () => {
  const brand = useRecoilValue(brandState);
  const { brandId = '' } = useParams<BrandMatch>();
  const { t } = useTranslation();

  const parsedCurrentPhoneNumber = parsePhoneNumber(brand?.contact_phone ?? '');

  return (
    <>
      <div className="rounded-lg border border-gray-500 p-6">
        <h3 className="mb-4 text-base font-bold text-gray-900">
          {t('updating_info.confirmation.contact_title')}
        </h3>
        <div className="mb-2 flex items-center">
          <Icon icon="phone" className="mr-2 text-gray-700" />
          <div className="text-sm font-normal">
            {parsedCurrentPhoneNumber ? (
              <span className="text-gray-700">
                {parsedCurrentPhoneNumber.formatInternational()}
              </span>
            ) : (
              <span className="text-gray-500">
                {t('updating_info.confirmation.na')}
              </span>
            )}
          </div>
        </div>
        <div className="flex items-center">
          <Icon icon="mail" className="mr-2 text-gray-700" />
          <div className="text-sm font-normal text-gray-700">
            {brand?.contact_email ? (
              <span className="text-gray-700">{brand?.contact_email}</span>
            ) : (
              <span className="text-gray-500">
                {t('updating_info.confirmation.na')}
              </span>
            )}
          </div>
        </div>
        <NavLink
          to={`/${brandId}/updating-info/1/contact`}
          className="mt-6 flex cursor-pointer text-sm font-bold text-green-900"
        >
          {t('edit')}
        </NavLink>
      </div>
      <div className="rounded-lg border border-gray-500 p-6">
        <h3 className="mb-1 text-base font-bold text-gray-900">
          {t('updating_info.confirmation.return_address_title')}
        </h3>
        <div className="mb-4 text-xs font-normal text-gray-500">
          {t('updating_info.confirmation.context_warning')}
        </div>
        {brand?.return_address ? (
          <AddressView
            label={t('address.label')}
            address={brand.return_address}
            locale={i18n.language === 'fr' ? 'FR' : 'EN'}
          />
        ) : null}
        <NavLink
          to={`/${brandId}/updating-info/1/return_address`}
          className="mt-6 flex cursor-pointer text-sm font-bold text-green-900"
        >
          {t('edit')}
        </NavLink>
      </div>
      <div className="rounded-lg border border-gray-500 p-6">
        <h3 className="mb-1 text-base font-bold text-gray-900">
          {brand?.store === StoreRegion.Fr
            ? t('updating_info.confirmation.estimated_delivery_time_title')
            : t('updating_info.confirmation.estimated_delivery_time_title_us')}
        </h3>
        <div className="mb-4 text-xs font-normal text-gray-500">
          {t('updating_info.confirmation.context_warning')}
        </div>
        <ul className="flex flex-col gap-2">
          <li className="flex items-center gap-2 text-sm font-normal text-gray-700">
            <Icon icon="clock" />
            {t('updating_info.confirmation.estimated_delivery_time_content', {
              min: brand?.min_delivery_delay,
              max: brand?.max_delivery_delay,
            })}
          </li>
          {brand?.has_mixed_delivery_delay ? (
            <li className="flex items-center gap-2 text-sm font-normal text-gray-700">
              <Icon icon="check" />
              {t(
                'updating_info.confirmation.have_different_delivery_time_products'
              )}
            </li>
          ) : null}
          {brand?.has_pre_order_delivery ? (
            <li className="flex items-center gap-2 text-sm font-normal text-gray-700">
              <Icon icon="check" />
              {t('updating_info.confirmation.have_pre_order_products')}
            </li>
          ) : null}
        </ul>
        <NavLink
          to={`/${brandId}/updating-info/1/shipping_delays`}
          className="mt-6 flex cursor-pointer text-sm font-bold text-green-900"
        >
          {t('edit')}
        </NavLink>
      </div>
    </>
  );
};
