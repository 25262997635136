import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@appchoose/icon';

import noRate from '../../assets/img/icons/StarStrikethrought.svg';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../components/dropdown-menu';
import { PaginationList } from '../../components/pagination/pagination-list';
import StatisticsRating from '../../components/statistics-rating/statistics-rating';
import { StatsRatio } from '../../components/stats-radio/stats-ratio';
import type { RateCountAggByRate, RatingsQuery } from '../../types/generated';

type RateProps = {
  rateCount: number;
  allRates: RateCountAggByRate[];
  rateAvg: number;
  onClickRatio: (rate: number) => void;
  filterRateMessage: string;
  onSelectFilter: (key: string) => void;
  onSelectRating: (key: string | number) => void;
  filterRate: number;
  isLoadingReviews: boolean;
  rates: RatingsQuery['ratings'];
  offset: number;
  onChangePage: (newPage: number) => void;
};

export const Rate: React.FC<RateProps> = ({
  rateCount,
  allRates,
  rateAvg,
  onClickRatio,
  filterRateMessage,
  onSelectFilter,
  onSelectRating,
  filterRate,
  isLoadingReviews,
  rates,
  offset,
  onChangePage,
}: RateProps) => {
  const { t } = useTranslation();

  const typeOptions = [
    {
      key: 'all',
      value: t('rating.all_review'),
    },
    {
      key: 'with_comment',
      value: t('rating.review_comments_only'),
    },
  ];

  const ratingOptions = [
    { key: '-1', value: t('rating.all_star') },
    {
      key: '5',
      value: t('rating.5_star_only'),
      disabled: allRates.findIndex((c) => c.rate === 4) === -1,
    },
    {
      key: '4',
      value: t('rating.4_star_only'),
      disabled: allRates.findIndex((c) => c.rate === 3) === -1,
    },
    {
      key: '3',
      disabled: allRates.findIndex((c) => c.rate === 2) === -1,
      value: t('rating.3_star_only'),
    },
    {
      key: '2',
      disabled: allRates.findIndex((c) => c.rate === 1) === -1,
      value: t('rating.2_star_only'),
    },
    {
      key: '1',
      disabled: allRates.findIndex((c) => c.rate === 0) === -1,
      value: t('rating.1_star_only'),
    },
  ];

  return (
    <div className="rate">
      <StatsRatio
        total_rate={rateCount}
        sum_rate={rateAvg}
        rates={allRates}
        onClick={onClickRatio}
      />
      <h5 className="mb-2 text-xs font-semibold uppercase tracking-wider text-gray-700">
        {t('rating.filter_by')}
      </h5>
      <div className="mb-6 flex flex-row space-x-4 border-b border-gray-300 pb-6 min-[961px]:space-x-9">
        <DropdownMenu>
          <DropdownMenuTrigger className="group flex shrink-0 items-center">
            <div className="flex items-center space-x-2">
              <span className="text-sm font-semibold text-green-900">
                {typeOptions.find((c) => c.key === filterRateMessage)?.value}
              </span>
              <Icon
                icon="arrowDown"
                className="shrink-0 transition-all group-data-[state=open]:rotate-180"
              />
            </div>
          </DropdownMenuTrigger>

          <DropdownMenuContent
            align="start"
            sideOffset={8}
            className="min-w-40 overflow-y-auto"
            style={{
              maxHeight: 'var(--radix-dropdown-menu-content-available-height)',
            }}
          >
            {typeOptions.map((typeOption) => (
              <DropdownMenuItem onSelect={() => onSelectFilter(typeOption.key)}>
                {typeOption.value}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>

        <DropdownMenu>
          <DropdownMenuTrigger className="group flex shrink-0 items-center">
            <div className="flex items-center space-x-2">
              <span className="text-sm font-semibold text-green-900">
                {
                  ratingOptions.find((c) => c.key === filterRate.toString())
                    ?.value
                }
              </span>
              <Icon
                icon="arrowDown"
                className="shrink-0 transition-all group-data-[state=open]:rotate-180"
              />
            </div>
          </DropdownMenuTrigger>

          <DropdownMenuContent
            align="start"
            sideOffset={8}
            className="min-w-40 overflow-y-auto"
            style={{
              maxHeight: 'var(--radix-dropdown-menu-content-available-height)',
            }}
          >
            {ratingOptions.map((ratingOption) => (
              <DropdownMenuItem
                onSelect={() => onSelectRating(ratingOption.key)}
                disabled={ratingOption.disabled}
              >
                {ratingOption.value}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {isLoadingReviews ? (
        <>
          <StatisticsRating variant="skeleton" />
          <StatisticsRating variant="skeleton" />
          <StatisticsRating variant="skeleton" />
          <StatisticsRating variant="skeleton" />
        </>
      ) : rates?.totalCount && rates.nodes ? (
        rates.nodes.map((rate, i) => {
          return <StatisticsRating key={rate.order_id + '_' + i} rate={rate} />;
        })
      ) : (
        <div className="flex items-center">
          <img src={noRate} />
          <p className="m-0 ml-2 text-sm text-gray-500">
            {t('analytics.no_result')}
          </p>
        </div>
      )}

      <PaginationList
        currentPage={offset + 1}
        totalCount={rates?.totalCount ?? 0}
        pageSize={16}
        onChange={onChangePage}
        className="mt-4"
      />
    </div>
  );
};
